const ACCEPTABLE = {
    "AAHED": 1,
"AALII": 1,
"AARGH": 1,
"AARTI": 1,
"ABACA": 1,
"ABACI": 1,
"ABACS": 1,
"ABAFT": 1,
"ABAKA": 1,
"ABAMP": 1,
"ABAND": 1,
"ABASH": 1,
"ABASK": 1,
"ABAYA": 1,
"ABBAS": 1,
"ABBED": 1,
"ABBES": 1,
"ABCEE": 1,
"ABEAM": 1,
"ABEAR": 1,
"ABELE": 1,
"ABERS": 1,
"ABETS": 1,
"ABIES": 1,
"ABLER": 1,
"ABLES": 1,
"ABLET": 1,
"ABLOW": 1,
"ABMHO": 1,
"ABOHM": 1,
"ABOIL": 1,
"ABOMA": 1,
"ABOON": 1,
"ABORD": 1,
"ABORE": 1,
"ABRAM": 1,
"ABRAY": 1,
"ABRIM": 1,
"ABRIN": 1,
"ABRIS": 1,
"ABSEY": 1,
"ABSIT": 1,
"ABUNA": 1,
"ABUNE": 1,
"ABUTS": 1,
"ABUZZ": 1,
"ABYES": 1,
"ABYSM": 1,
"ACAIS": 1,
"ACARI": 1,
"ACCAS": 1,
"ACCOY": 1,
"ACERB": 1,
"ACERS": 1,
"ACETA": 1,
"ACHAR": 1,
"ACHED": 1,
"ACHES": 1,
"ACHOO": 1,
"ACIDS": 1,
"ACIDY": 1,
"ACING": 1,
"ACINI": 1,
"ACKEE": 1,
"ACKER": 1,
"ACMES": 1,
"ACMIC": 1,
"ACNED": 1,
"ACNES": 1,
"ACOCK": 1,
"ACOLD": 1,
"ACRED": 1,
"ACRES": 1,
"ACROS": 1,
"ACTED": 1,
"ACTIN": 1,
"ACTON": 1,
"ACYLS": 1,
"ADAWS": 1,
"ADAYS": 1,
"ADBOT": 1,
"ADDAX": 1,
"ADDED": 1,
"ADDER": 1,
"ADDIO": 1,
"ADDLE": 1,
"ADEEM": 1,
"ADHAN": 1,
"ADIEU": 1,
"ADIOS": 1,
"ADITS": 1,
"ADMAN": 1,
"ADMEN": 1,
"ADMIX": 1,
"ADOBO": 1,
"ADOWN": 1,
"ADOZE": 1,
"ADRAD": 1,
"ADRED": 1,
"ADSUM": 1,
"ADUKI": 1,
"ADUNC": 1,
"ADUST": 1,
"ADVEW": 1,
"ADYTA": 1,
"ADZED": 1,
"ADZES": 1,
"AECIA": 1,
"AEDES": 1,
"AEGIS": 1,
"AEONS": 1,
"AERIE": 1,
"AEROS": 1,
"AESIR": 1,
"AFALD": 1,
"AFARA": 1,
"AFARS": 1,
"AFEAR": 1,
"AFLAJ": 1,
"AFORE": 1,
"AFRIT": 1,
"AFROS": 1,
"AGAMA": 1,
"AGAMI": 1,
"AGARS": 1,
"AGAST": 1,
"AGAVE": 1,
"AGAZE": 1,
"AGENE": 1,
"AGERS": 1,
"AGGER": 1,
"AGGIE": 1,
"AGGRI": 1,
"AGGRO": 1,
"AGGRY": 1,
"AGHAS": 1,
"AGILA": 1,
"AGIOS": 1,
"AGISM": 1,
"AGIST": 1,
"AGITA": 1,
"AGLEE": 1,
"AGLET": 1,
"AGLEY": 1,
"AGLOO": 1,
"AGLUS": 1,
"AGMAS": 1,
"AGOGE": 1,
"AGONE": 1,
"AGONS": 1,
"AGOOD": 1,
"AGRIA": 1,
"AGRIN": 1,
"AGROS": 1,
"AGUED": 1,
"AGUES": 1,
"AGUNA": 1,
"AGUTI": 1,
"AHEAP": 1,
"AHENT": 1,
"AHIGH": 1,
"AHIND": 1,
"AHING": 1,
"AHINT": 1,
"AHOLD": 1,
"AHULL": 1,
"AHURU": 1,
"AIDAS": 1,
"AIDED": 1,
"AIDES": 1,
"AIDOI": 1,
"AIDOS": 1,
"AIERY": 1,
"AIGAS": 1,
"AIGHT": 1,
"AILED": 1,
"AIMED": 1,
"AIMER": 1,
"AINEE": 1,
"AINGA": 1,
"AIOLI": 1,
"AIRED": 1,
"AIRER": 1,
"AIRNS": 1,
"AIRTH": 1,
"AIRTS": 1,
"AITCH": 1,
"AITUS": 1,
"AIVER": 1,
"AIYEE": 1,
"AIZLE": 1,
"AJIES": 1,
"AJIVA": 1,
"AJUGA": 1,
"AJWAN": 1,
"AKEES": 1,
"AKELA": 1,
"AKENE": 1,
"AKING": 1,
"AKITA": 1,
"AKKAS": 1,
"ALAAP": 1,
"ALACK": 1,
"ALAMO": 1,
"ALAND": 1,
"ALANE": 1,
"ALANG": 1,
"ALANS": 1,
"ALANT": 1,
"ALAPA": 1,
"ALAPS": 1,
"ALARY": 1,
"ALATE": 1,
"ALAYS": 1,
"ALBAS": 1,
"ALBEE": 1,
"ALCID": 1,
"ALCOS": 1,
"ALDEA": 1,
"ALDER": 1,
"ALDOL": 1,
"ALECK": 1,
"ALECS": 1,
"ALEFS": 1,
"ALEFT": 1,
"ALEPH": 1,
"ALEWS": 1,
"ALEYE": 1,
"ALFAS": 1,
"ALGAL": 1,
"ALGAS": 1,
"ALGID": 1,
"ALGIN": 1,
"ALGOR": 1,
"ALGUM": 1,
"ALIAS": 1,
"ALIFS": 1,
"ALINE": 1,
"ALIST": 1,
"ALIYA": 1,
"ALKIE": 1,
"ALKOS": 1,
"ALKYD": 1,
"ALKYL": 1,
"ALLEE": 1,
"ALLEL": 1,
"ALLIS": 1,
"ALLOD": 1,
"ALLYL": 1,
"ALMAH": 1,
"ALMAS": 1,
"ALMEH": 1,
"ALMES": 1,
"ALMUD": 1,
"ALMUG": 1,
"ALODS": 1,
"ALOED": 1,
"ALOES": 1,
"ALOHA": 1,
"ALOIN": 1,
"ALOOS": 1,
"ALOWE": 1,
"ALTHO": 1,
"ALTOS": 1,
"ALULA": 1,
"ALUMS": 1,
"ALURE": 1,
"ALVAR": 1,
"ALWAY": 1,
"AMAHS": 1,
"AMAIN": 1,
"AMATE": 1,
"AMAUT": 1,
"AMBAN": 1,
"AMBIT": 1,
"AMBOS": 1,
"AMBRY": 1,
"AMEBA": 1,
"AMEER": 1,
"AMENE": 1,
"AMENS": 1,
"AMENT": 1,
"AMIAS": 1,
"AMICE": 1,
"AMICI": 1,
"AMIDE": 1,
"AMIDO": 1,
"AMIDS": 1,
"AMIES": 1,
"AMIGA": 1,
"AMIGO": 1,
"AMINE": 1,
"AMINO": 1,
"AMINS": 1,
"AMIRS": 1,
"AMLAS": 1,
"AMMAN": 1,
"AMMON": 1,
"AMMOS": 1,
"AMNIA": 1,
"AMNIC": 1,
"AMNIO": 1,
"AMOKS": 1,
"AMOLE": 1,
"AMORT": 1,
"AMOUR": 1,
"AMOVE": 1,
"AMOWT": 1,
"AMPED": 1,
"AMPUL": 1,
"AMRIT": 1,
"AMUCK": 1,
"AMYLS": 1,
"ANANA": 1,
"ANATA": 1,
"ANCHO": 1,
"ANCLE": 1,
"ANCON": 1,
"ANDRO": 1,
"ANEAR": 1,
"ANELE": 1,
"ANENT": 1,
"ANGAS": 1,
"ANGLO": 1,
"ANIGH": 1,
"ANILE": 1,
"ANILS": 1,
"ANIMA": 1,
"ANIMI": 1,
"ANION": 1,
"ANISE": 1,
"ANKER": 1,
"ANKHS": 1,
"ANKUS": 1,
"ANLAS": 1,
"ANNAL": 1,
"ANNAS": 1,
"ANNAT": 1,
"ANOAS": 1,
"ANOLE": 1,
"ANOMY": 1,
"ANSAE": 1,
"ANTAE": 1,
"ANTAR": 1,
"ANTAS": 1,
"ANTED": 1,
"ANTES": 1,
"ANTIS": 1,
"ANTRA": 1,
"ANTRE": 1,
"ANTSY": 1,
"ANURA": 1,
"ANYON": 1,
"APACE": 1,
"APAGE": 1,
"APAID": 1,
"APAYD": 1,
"APAYS": 1,
"APEAK": 1,
"APEEK": 1,
"APERS": 1,
"APERT": 1,
"APERY": 1,
"APGAR": 1,
"APHIS": 1,
"APIAN": 1,
"APIOL": 1,
"APISH": 1,
"APISM": 1,
"APODE": 1,
"APODS": 1,
"APOOP": 1,
"APORT": 1,
"APPAL": 1,
"APPAY": 1,
"APPEL": 1,
"APPRO": 1,
"APPUI": 1,
"APPUY": 1,
"APRES": 1,
"APSES": 1,
"APSIS": 1,
"APSOS": 1,
"APTED": 1,
"APTER": 1,
"AQUAE": 1,
"AQUAS": 1,
"ARABA": 1,
"ARAKS": 1,
"ARAME": 1,
"ARARS": 1,
"ARBAS": 1,
"ARCED": 1,
"ARCHI": 1,
"ARCOS": 1,
"ARCUS": 1,
"ARDEB": 1,
"ARDRI": 1,
"AREAD": 1,
"AREAE": 1,
"AREAL": 1,
"AREAR": 1,
"AREAS": 1,
"ARECA": 1,
"AREDD": 1,
"AREDE": 1,
"AREFY": 1,
"AREIC": 1,
"ARENE": 1,
"AREPA": 1,
"ARERE": 1,
"ARETE": 1,
"ARETS": 1,
"ARETT": 1,
"ARGAL": 1,
"ARGAN": 1,
"ARGIL": 1,
"ARGLE": 1,
"ARGOL": 1,
"ARGON": 1,
"ARGOT": 1,
"ARGUS": 1,
"ARHAT": 1,
"ARIAS": 1,
"ARIEL": 1,
"ARIKI": 1,
"ARILS": 1,
"ARIOT": 1,
"ARISH": 1,
"ARKED": 1,
"ARLED": 1,
"ARLES": 1,
"ARMED": 1,
"ARMER": 1,
"ARMET": 1,
"ARMIL": 1,
"ARNAS": 1,
"ARNUT": 1,
"AROBA": 1,
"AROHA": 1,
"AROID": 1,
"ARPAS": 1,
"ARPEN": 1,
"ARRAH": 1,
"ARRAS": 1,
"ARRET": 1,
"ARRIS": 1,
"ARROZ": 1,
"ARSED": 1,
"ARSES": 1,
"ARSEY": 1,
"ARSIS": 1,
"ARTAL": 1,
"ARTEL": 1,
"ARTIC": 1,
"ARTIS": 1,
"ARUHE": 1,
"ARUMS": 1,
"ARVAL": 1,
"ARVEE": 1,
"ARVOS": 1,
"ARYLS": 1,
"ASANA": 1,
"ASCON": 1,
"ASCUS": 1,
"ASDIC": 1,
"ASHED": 1,
"ASHES": 1,
"ASHET": 1,
"ASKED": 1,
"ASKER": 1,
"ASKOI": 1,
"ASKOS": 1,
"ASPEN": 1,
"ASPER": 1,
"ASPIC": 1,
"ASPIE": 1,
"ASPIS": 1,
"ASPRO": 1,
"ASSAI": 1,
"ASSAM": 1,
"ASSES": 1,
"ASSEZ": 1,
"ASSOT": 1,
"ASTER": 1,
"ASTIR": 1,
"ASTUN": 1,
"ASURA": 1,
"ASWAY": 1,
"ASWIM": 1,
"ASYLA": 1,
"ATAPS": 1,
"ATAXY": 1,
"ATIGI": 1,
"ATILT": 1,
"ATIMY": 1,
"ATLAS": 1,
"ATMAN": 1,
"ATMAS": 1,
"ATMOS": 1,
"ATOCS": 1,
"ATOKE": 1,
"ATOKS": 1,
"ATOMS": 1,
"ATOMY": 1,
"ATONY": 1,
"ATOPY": 1,
"ATRIA": 1,
"ATRIP": 1,
"ATTAP": 1,
"ATTAR": 1,
"ATUAS": 1,
"AUDAD": 1,
"AUGER": 1,
"AUGHT": 1,
"AULAS": 1,
"AULIC": 1,
"AULOI": 1,
"AULOS": 1,
"AUMIL": 1,
"AUNES": 1,
"AUNTS": 1,
"AURAE": 1,
"AURAL": 1,
"AURAR": 1,
"AURAS": 1,
"AUREI": 1,
"AURES": 1,
"AURIC": 1,
"AURIS": 1,
"AURUM": 1,
"AUTOS": 1,
"AUXIN": 1,
"AVALE": 1,
"AVANT": 1,
"AVAST": 1,
"AVELS": 1,
"AVENS": 1,
"AVERS": 1,
"AVGAS": 1,
"AVINE": 1,
"AVION": 1,
"AVISE": 1,
"AVISO": 1,
"AVIZE": 1,
"AVOWS": 1,
"AVYZE": 1,
"AWARN": 1,
"AWATO": 1,
"AWAVE": 1,
"AWAYS": 1,
"AWDLS": 1,
"AWEEL": 1,
"AWETO": 1,
"AWING": 1,
"AWMRY": 1,
"AWNED": 1,
"AWNER": 1,
"AWOLS": 1,
"AWORK": 1,
"AXELS": 1,
"AXILE": 1,
"AXILS": 1,
"AXING": 1,
"AXITE": 1,
"AXLED": 1,
"AXLES": 1,
"AXMAN": 1,
"AXMEN": 1,
"AXOID": 1,
"AXONE": 1,
"AXONS": 1,
"AYAHS": 1,
"AYAYA": 1,
"AYELP": 1,
"AYGRE": 1,
"AYINS": 1,
"AYONT": 1,
"AYRES": 1,
"AYRIE": 1,
"AZANS": 1,
"AZIDE": 1,
"AZIDO": 1,
"AZINE": 1,
"AZLON": 1,
"AZOIC": 1,
"AZOLE": 1,
"AZONS": 1,
"AZOTE": 1,
"AZOTH": 1,
"AZUKI": 1,
"AZURN": 1,
"AZURY": 1,
"AZYGY": 1,
"AZYME": 1,
"AZYMS": 1,
"BAAED": 1,
"BAALS": 1,
"BABAS": 1,
"BABEL": 1,
"BABES": 1,
"BABKA": 1,
"BABOO": 1,
"BABUL": 1,
"BABUS": 1,
"BACCA": 1,
"BACCO": 1,
"BACCY": 1,
"BACHA": 1,
"BACHS": 1,
"BACKS": 1,
"BADDY": 1,
"BAELS": 1,
"BAFFS": 1,
"BAFFY": 1,
"BAFTS": 1,
"BAGHS": 1,
"BAGIE": 1,
"BAHTS": 1,
"BAHUS": 1,
"BAHUT": 1,
"BAILS": 1,
"BAIRN": 1,
"BAISA": 1,
"BAITH": 1,
"BAITS": 1,
"BAIZA": 1,
"BAIZE": 1,
"BAJAN": 1,
"BAJRA": 1,
"BAJRI": 1,
"BAJUS": 1,
"BAKED": 1,
"BAKEN": 1,
"BAKES": 1,
"BAKRA": 1,
"BALAS": 1,
"BALDS": 1,
"BALDY": 1,
"BALED": 1,
"BALES": 1,
"BALKS": 1,
"BALKY": 1,
"BALLS": 1,
"BALLY": 1,
"BALMS": 1,
"BALOO": 1,
"BALSA": 1,
"BALTI": 1,
"BALUN": 1,
"BALUS": 1,
"BAMBI": 1,
"BANAK": 1,
"BANCO": 1,
"BANCS": 1,
"BANDA": 1,
"BANDH": 1,
"BANDS": 1,
"BANDY": 1,
"BANED": 1,
"BANES": 1,
"BANGS": 1,
"BANIA": 1,
"BANKS": 1,
"BANNS": 1,
"BANTS": 1,
"BANTU": 1,
"BANTY": 1,
"BANYA": 1,
"BAPUS": 1,
"BARBE": 1,
"BARBS": 1,
"BARBY": 1,
"BARCA": 1,
"BARDE": 1,
"BARDO": 1,
"BARDS": 1,
"BARDY": 1,
"BARED": 1,
"BARER": 1,
"BARES": 1,
"BARFI": 1,
"BARFS": 1,
"BARIC": 1,
"BARKS": 1,
"BARKY": 1,
"BARMS": 1,
"BARMY": 1,
"BARNS": 1,
"BARNY": 1,
"BARPS": 1,
"BARRA": 1,
"BARRE": 1,
"BARRO": 1,
"BARRY": 1,
"BARYE": 1,
"BASAN": 1,
"BASED": 1,
"BASEN": 1,
"BASER": 1,
"BASES": 1,
"BASHO": 1,
"BASIJ": 1,
"BASKS": 1,
"BASON": 1,
"BASSE": 1,
"BASSI": 1,
"BASSO": 1,
"BASSY": 1,
"BASTA": 1,
"BASTI": 1,
"BASTO": 1,
"BASTS": 1,
"BATED": 1,
"BATES": 1,
"BATHS": 1,
"BATIK": 1,
"BATTA": 1,
"BATTS": 1,
"BATTU": 1,
"BAUDS": 1,
"BAUKS": 1,
"BAULK": 1,
"BAURS": 1,
"BAVIN": 1,
"BAWDS": 1,
"BAWKS": 1,
"BAWLS": 1,
"BAWNS": 1,
"BAWRS": 1,
"BAWTY": 1,
"BAYED": 1,
"BAYER": 1,
"BAYES": 1,
"BAYLE": 1,
"BAYTS": 1,
"BAZAR": 1,
"BAZOO": 1,
"BEADS": 1,
"BEAKS": 1,
"BEAKY": 1,
"BEALS": 1,
"BEAMS": 1,
"BEAMY": 1,
"BEANO": 1,
"BEANS": 1,
"BEANY": 1,
"BEARE": 1,
"BEARS": 1,
"BEATH": 1,
"BEATS": 1,
"BEATY": 1,
"BEAUS": 1,
"BEAUT": 1,
"BEAUX": 1,
"BEBOP": 1,
"BECAP": 1,
"BECKE": 1,
"BECKS": 1,
"BEDAD": 1,
"BEDEL": 1,
"BEDES": 1,
"BEDEW": 1,
"BEDIM": 1,
"BEDYE": 1,
"BEEDI": 1,
"BEEFS": 1,
"BEEPS": 1,
"BEERS": 1,
"BEERY": 1,
"BEETS": 1,
"BEFOG": 1,
"BEGAD": 1,
"BEGAR": 1,
"BEGEM": 1,
"BEGOT": 1,
"BEGUM": 1,
"BEIGE": 1,
"BEIGY": 1,
"BEINS": 1,
"BEKAH": 1,
"BELAH": 1,
"BELAR": 1,
"BELAY": 1,
"BELEE": 1,
"BELGA": 1,
"BELLS": 1,
"BELON": 1,
"BELTS": 1,
"BEMAD": 1,
"BEMAS": 1,
"BEMIX": 1,
"BEMUD": 1,
"BENDS": 1,
"BENDY": 1,
"BENES": 1,
"BENET": 1,
"BENGA": 1,
"BENIS": 1,
"BENNE": 1,
"BENNI": 1,
"BENNY": 1,
"BENTO": 1,
"BENTS": 1,
"BENTY": 1,
"BEPAT": 1,
"BERAY": 1,
"BERES": 1,
"BERGS": 1,
"BERKO": 1,
"BERKS": 1,
"BERME": 1,
"BERMS": 1,
"BEROB": 1,
"BERYL": 1,
"BESAT": 1,
"BESAW": 1,
"BESEE": 1,
"BESES": 1,
"BESIT": 1,
"BESOM": 1,
"BESOT": 1,
"BESTI": 1,
"BESTS": 1,
"BETAS": 1,
"BETED": 1,
"BETES": 1,
"BETHS": 1,
"BETID": 1,
"BETON": 1,
"BETTA": 1,
"BETTY": 1,
"BEVER": 1,
"BEVOR": 1,
"BEVUE": 1,
"BEVVY": 1,
"BEWET": 1,
"BEWIG": 1,
"BEZES": 1,
"BEZIL": 1,
"BEZZY": 1,
"BHAIS": 1,
"BHAJI": 1,
"BHANG": 1,
"BHATS": 1,
"BHELS": 1,
"BHOOT": 1,
"BHUNA": 1,
"BHUTS": 1,
"BIACH": 1,
"BIALI": 1,
"BIALY": 1,
"BIBBS": 1,
"BIBES": 1,
"BICCY": 1,
"BICES": 1,
"BIDED": 1,
"BIDER": 1,
"BIDES": 1,
"BIDET": 1,
"BIDIS": 1,
"BIDON": 1,
"BIELD": 1,
"BIERS": 1,
"BIFFO": 1,
"BIFFS": 1,
"BIFFY": 1,
"BIFID": 1,
"BIGAE": 1,
"BIGGS": 1,
"BIGGY": 1,
"BIGHA": 1,
"BIGHT": 1,
"BIGLY": 1,
"BIGOS": 1,
"BIJOU": 1,
"BIKED": 1,
"BIKER": 1,
"BIKES": 1,
"BIKIE": 1,
"BILBO": 1,
"BILBY": 1,
"BILED": 1,
"BILES": 1,
"BILGY": 1,
"BILKS": 1,
"BILLS": 1,
"BIMAH": 1,
"BIMAS": 1,
"BIMBO": 1,
"BINAL": 1,
"BINDI": 1,
"BINDS": 1,
"BINER": 1,
"BINES": 1,
"BINGS": 1,
"BINGY": 1,
"BINIT": 1,
"BINKS": 1,
"BINTS": 1,
"BIOGS": 1,
"BIONT": 1,
"BIOTA": 1,
"BIPED": 1,
"BIPOD": 1,
"BIRDS": 1,
"BIRKS": 1,
"BIRLE": 1,
"BIRLS": 1,
"BIROS": 1,
"BIRRS": 1,
"BIRSE": 1,
"BIRSY": 1,
"BISES": 1,
"BISKS": 1,
"BISOM": 1,
"BITCH": 1,
"BITER": 1,
"BITES": 1,
"BITOS": 1,
"BITOU": 1,
"BITSY": 1,
"BITTE": 1,
"BITTS": 1,
"BIVIA": 1,
"BIVVY": 1,
"BIZES": 1,
"BIZZO": 1,
"BIZZY": 1,
"BLABS": 1,
"BLADS": 1,
"BLADY": 1,
"BLAER": 1,
"BLAES": 1,
"BLAFF": 1,
"BLAGS": 1,
"BLAHS": 1,
"BLAIN": 1,
"BLAMS": 1,
"BLART": 1,
"BLASE": 1,
"BLASH": 1,
"BLATE": 1,
"BLATS": 1,
"BLATT": 1,
"BLAUD": 1,
"BLAWN": 1,
"BLAWS": 1,
"BLAYS": 1,
"BLEAR": 1,
"BLEBS": 1,
"BLECH": 1,
"BLEES": 1,
"BLENT": 1,
"BLERT": 1,
"BLEST": 1,
"BLETS": 1,
"BLEYS": 1,
"BLIMY": 1,
"BLING": 1,
"BLINI": 1,
"BLINS": 1,
"BLINY": 1,
"BLIPS": 1,
"BLIST": 1,
"BLITE": 1,
"BLITS": 1,
"BLIVE": 1,
"BLOBS": 1,
"BLOCS": 1,
"BLOGS": 1,
"BLOOK": 1,
"BLOOP": 1,
"BLORE": 1,
"BLOTS": 1,
"BLOWS": 1,
"BLOWY": 1,
"BLUBS": 1,
"BLUDE": 1,
"BLUDS": 1,
"BLUDY": 1,
"BLUED": 1,
"BLUES": 1,
"BLUET": 1,
"BLUEY": 1,
"BLUID": 1,
"BLUME": 1,
"BLUNK": 1,
"BLURS": 1,
"BLYPE": 1,
"BOABS": 1,
"BOAKS": 1,
"BOARS": 1,
"BOART": 1,
"BOATS": 1,
"BOBAC": 1,
"BOBAK": 1,
"BOBAS": 1,
"BOBOL": 1,
"BOBOS": 1,
"BOCCA": 1,
"BOCCE": 1,
"BOCCI": 1,
"BOCHE": 1,
"BOCKS": 1,
"BODED": 1,
"BODES": 1,
"BODGE": 1,
"BODHI": 1,
"BODLE": 1,
"BOEPS": 1,
"BOETS": 1,
"BOEUF": 1,
"BOFFO": 1,
"BOFFS": 1,
"BOGAN": 1,
"BOGEY": 1,
"BOGGY": 1,
"BOGIE": 1,
"BOGLE": 1,
"BOGUE": 1,
"BOGUS": 1,
"BOHEA": 1,
"BOHOS": 1,
"BOILS": 1,
"BOING": 1,
"BOINK": 1,
"BOITE": 1,
"BOKED": 1,
"BOKEH": 1,
"BOKES": 1,
"BOKOS": 1,
"BOLAR": 1,
"BOLAS": 1,
"BOLDS": 1,
"BOLES": 1,
"BOLIX": 1,
"BOLLS": 1,
"BOLOS": 1,
"BOLTS": 1,
"BOLUS": 1,
"BOMAS": 1,
"BOMBE": 1,
"BOMBO": 1,
"BOMBS": 1,
"BONCE": 1,
"BONDS": 1,
"BONED": 1,
"BONER": 1,
"BONES": 1,
"BONGS": 1,
"BONIE": 1,
"BONKS": 1,
"BONNE": 1,
"BONNY": 1,
"BONZA": 1,
"BONZE": 1,
"BOOAI": 1,
"BOOAY": 1,
"BOOBS": 1,
"BOODY": 1,
"BOOED": 1,
"BOOFY": 1,
"BOOGY": 1,
"BOOHS": 1,
"BOOKS": 1,
"BOOKY": 1,
"BOOLS": 1,
"BOOMS": 1,
"BOOMY": 1,
"BOONG": 1,
"BOONS": 1,
"BOORD": 1,
"BOORS": 1,
"BOOSE": 1,
"BOOTS": 1,
"BOPPY": 1,
"BORAK": 1,
"BORAL": 1,
"BORAS": 1,
"BORDE": 1,
"BORDS": 1,
"BORED": 1,
"BOREE": 1,
"BOREL": 1,
"BORER": 1,
"BORES": 1,
"BORGO": 1,
"BORIC": 1,
"BORKS": 1,
"BORMS": 1,
"BORNA": 1,
"BORON": 1,
"BORTS": 1,
"BORTY": 1,
"BORTZ": 1,
"BOSIE": 1,
"BOSKS": 1,
"BOSKY": 1,
"BOSON": 1,
"BOSUN": 1,
"BOTAS": 1,
"BOTEL": 1,
"BOTES": 1,
"BOTHY": 1,
"BOTTE": 1,
"BOTTS": 1,
"BOTTY": 1,
"BOUGE": 1,
"BOUKS": 1,
"BOULT": 1,
"BOUNS": 1,
"BOURD": 1,
"BOURG": 1,
"BOURN": 1,
"BOUSE": 1,
"BOUSY": 1,
"BOUTS": 1,
"BOVID": 1,
"BOWAT": 1,
"BOWED": 1,
"BOWER": 1,
"BOWES": 1,
"BOWET": 1,
"BOWIE": 1,
"BOWLS": 1,
"BOWNE": 1,
"BOWRS": 1,
"BOWSE": 1,
"BOXED": 1,
"BOXEN": 1,
"BOXES": 1,
"BOXLA": 1,
"BOXTY": 1,
"BOYAR": 1,
"BOYAU": 1,
"BOYED": 1,
"BOYFS": 1,
"BOYGS": 1,
"BOYLA": 1,
"BOYOS": 1,
"BOYSY": 1,
"BOZOS": 1,
"BRAAI": 1,
"BRACH": 1,
"BRACK": 1,
"BRACT": 1,
"BRADS": 1,
"BRAES": 1,
"BRAGS": 1,
"BRAIL": 1,
"BRAKS": 1,
"BRAKY": 1,
"BRAME": 1,
"BRANE": 1,
"BRANK": 1,
"BRANS": 1,
"BRANT": 1,
"BRAST": 1,
"BRATS": 1,
"BRAVA": 1,
"BRAVI": 1,
"BRAWS": 1,
"BRAXY": 1,
"BRAYS": 1,
"BRAZA": 1,
"BRAZE": 1,
"BREAM": 1,
"BREDE": 1,
"BREDS": 1,
"BREEM": 1,
"BREER": 1,
"BREES": 1,
"BREID": 1,
"BREIS": 1,
"BREME": 1,
"BRENS": 1,
"BRENT": 1,
"BRERE": 1,
"BRERS": 1,
"BREVE": 1,
"BREWS": 1,
"BREYS": 1,
"BRIER": 1,
"BRIES": 1,
"BRIGS": 1,
"BRIKI": 1,
"BRIKS": 1,
"BRILL": 1,
"BRIMS": 1,
"BRINS": 1,
"BRIOS": 1,
"BRISE": 1,
"BRISS": 1,
"BRITH": 1,
"BRITS": 1,
"BRITT": 1,
"BRIZE": 1,
"BROCH": 1,
"BROCK": 1,
"BRODS": 1,
"BROGH": 1,
"BROGS": 1,
"BROME": 1,
"BROMO": 1,
"BRONC": 1,
"BROND": 1,
"BROOL": 1,
"BROOS": 1,
"BROSE": 1,
"BROSY": 1,
"BROWS": 1,
"BRUGH": 1,
"BRUIN": 1,
"BRUIT": 1,
"BRULE": 1,
"BRUME": 1,
"BRUNG": 1,
"BRUSK": 1,
"BRUST": 1,
"BRUTS": 1,
"BUATS": 1,
"BUAZE": 1,
"BUBAL": 1,
"BUBAS": 1,
"BUBBA": 1,
"BUBBE": 1,
"BUBBY": 1,
"BUBUS": 1,
"BUCHU": 1,
"BUCKO": 1,
"BUCKS": 1,
"BUCKU": 1,
"BUDAS": 1,
"BUDIS": 1,
"BUDOS": 1,
"BUFFA": 1,
"BUFFE": 1,
"BUFFI": 1,
"BUFFO": 1,
"BUFFS": 1,
"BUFFY": 1,
"BUFOS": 1,
"BUFTY": 1,
"BUHLS": 1,
"BUHRS": 1,
"BUIKS": 1,
"BUIST": 1,
"BUKES": 1,
"BULBS": 1,
"BULGY": 1,
"BULKS": 1,
"BULLA": 1,
"BULLS": 1,
"BULSE": 1,
"BUMBO": 1,
"BUMFS": 1,
"BUMPH": 1,
"BUMPS": 1,
"BUMPY": 1,
"BUNAS": 1,
"BUNCE": 1,
"BUNCO": 1,
"BUNDE": 1,
"BUNDH": 1,
"BUNDS": 1,
"BUNDT": 1,
"BUNDU": 1,
"BUNDY": 1,
"BUNGS": 1,
"BUNGY": 1,
"BUNIA": 1,
"BUNJE": 1,
"BUNJY": 1,
"BUNKO": 1,
"BUNKS": 1,
"BUNNS": 1,
"BUNTS": 1,
"BUNTY": 1,
"BUNYA": 1,
"BUOYS": 1,
"BUPPY": 1,
"BURAN": 1,
"BURAS": 1,
"BURBS": 1,
"BURDS": 1,
"BURET": 1,
"BURFI": 1,
"BURGH": 1,
"BURGS": 1,
"BURIN": 1,
"BURKA": 1,
"BURKE": 1,
"BURKS": 1,
"BURLS": 1,
"BURNS": 1,
"BUROO": 1,
"BURPS": 1,
"BURQA": 1,
"BURRO": 1,
"BURRS": 1,
"BURRY": 1,
"BURSA": 1,
"BURSE": 1,
"BUSBY": 1,
"BUSES": 1,
"BUSKS": 1,
"BUSKY": 1,
"BUSSU": 1,
"BUSTI": 1,
"BUSTS": 1,
"BUSTY": 1,
"BUTEO": 1,
"BUTES": 1,
"BUTLE": 1,
"BUTOH": 1,
"BUTTS": 1,
"BUTTY": 1,
"BUTUT": 1,
"BUTYL": 1,
"BUZZY": 1,
"BWANA": 1,
"BWAZI": 1,
"BYDED": 1,
"BYDES": 1,
"BYKED": 1,
"BYKES": 1,
"BYRES": 1,
"BYRLS": 1,
"BYSSI": 1,
"BYTES": 1,
"BYWAY": 1,
"CAAED": 1,
"CABAS": 1,
"CABER": 1,
"CABOB": 1,
"CABOC": 1,
"CABRE": 1,
"CACAS": 1,
"CACKS": 1,
"CACKY": 1,
"CADEE": 1,
"CADES": 1,
"CADGE": 1,
"CADGY": 1,
"CADIE": 1,
"CADIS": 1,
"CADRE": 1,
"CAECA": 1,
"CAESE": 1,
"CAFES": 1,
"CAFFS": 1,
"CAGED": 1,
"CAGER": 1,
"CAGES": 1,
"CAGOT": 1,
"CAHOW": 1,
"CAIDS": 1,
"CAINS": 1,
"CAIRD": 1,
"CAJON": 1,
"CAJUN": 1,
"CAKED": 1,
"CAKES": 1,
"CAKEY": 1,
"CALFS": 1,
"CALID": 1,
"CALIF": 1,
"CALIX": 1,
"CALKS": 1,
"CALLA": 1,
"CALLS": 1,
"CALMS": 1,
"CALMY": 1,
"CALOS": 1,
"CALPA": 1,
"CALPS": 1,
"CALVE": 1,
"CALYX": 1,
"CAMAN": 1,
"CAMAS": 1,
"CAMES": 1,
"CAMIS": 1,
"CAMOS": 1,
"CAMPI": 1,
"CAMPO": 1,
"CAMPS": 1,
"CAMPY": 1,
"CAMUS": 1,
"CANED": 1,
"CANEH": 1,
"CANER": 1,
"CANES": 1,
"CANGS": 1,
"CANID": 1,
"CANNA": 1,
"CANNS": 1,
"CANSO": 1,
"CANST": 1,
"CANTO": 1,
"CANTS": 1,
"CANTY": 1,
"CAPAS": 1,
"CAPED": 1,
"CAPES": 1,
"CAPEX": 1,
"CAPHS": 1,
"CAPIZ": 1,
"CAPLE": 1,
"CAPON": 1,
"CAPOS": 1,
"CAPOT": 1,
"CAPRI": 1,
"CAPUL": 1,
"CARAP": 1,
"CARBO": 1,
"CARBS": 1,
"CARBY": 1,
"CARDI": 1,
"CARDS": 1,
"CARDY": 1,
"CARED": 1,
"CARER": 1,
"CARES": 1,
"CARET": 1,
"CAREX": 1,
"CARKS": 1,
"CARLE": 1,
"CARLS": 1,
"CARNS": 1,
"CARNY": 1,
"CAROB": 1,
"CAROM": 1,
"CARON": 1,
"CARPI": 1,
"CARPS": 1,
"CARRS": 1,
"CARSE": 1,
"CARTA": 1,
"CARTE": 1,
"CARTS": 1,
"CARVY": 1,
"CASAS": 1,
"CASCO": 1,
"CASED": 1,
"CASES": 1,
"CASKS": 1,
"CASKY": 1,
"CASTS": 1,
"CASUS": 1,
"CATES": 1,
"CAUDA": 1,
"CAUKS": 1,
"CAULD": 1,
"CAULS": 1,
"CAUMS": 1,
"CAUPS": 1,
"CAURI": 1,
"CAUSA": 1,
"CAVAS": 1,
"CAVED": 1,
"CAVEL": 1,
"CAVER": 1,
"CAVES": 1,
"CAVIE": 1,
"CAWED": 1,
"CAWKS": 1,
"CAXON": 1,
"CEAZE": 1,
"CEBID": 1,
"CECAL": 1,
"CECUM": 1,
"CEDED": 1,
"CEDER": 1,
"CEDES": 1,
"CEDIS": 1,
"CEIBA": 1,
"CEILI": 1,
"CEILS": 1,
"CELEB": 1,
"CELLA": 1,
"CELLI": 1,
"CELLS": 1,
"CELOM": 1,
"CELTS": 1,
"CENSE": 1,
"CENTO": 1,
"CENTS": 1,
"CENTU": 1,
"CEORL": 1,
"CEPES": 1,
"CERCI": 1,
"CERED": 1,
"CERES": 1,
"CERGE": 1,
"CERIA": 1,
"CERIC": 1,
"CERNE": 1,
"CEROC": 1,
"CEROS": 1,
"CERTS": 1,
"CERTY": 1,
"CESSE": 1,
"CESTA": 1,
"CESTI": 1,
"CETES": 1,
"CETYL": 1,
"CEZVE": 1,
"CHACE": 1,
"CHACK": 1,
"CHACO": 1,
"CHADO": 1,
"CHADS": 1,
"CHAFT": 1,
"CHAIS": 1,
"CHALS": 1,
"CHAMS": 1,
"CHANA": 1,
"CHANG": 1,
"CHANK": 1,
"CHAPE": 1,
"CHAPS": 1,
"CHAPT": 1,
"CHARA": 1,
"CHARE": 1,
"CHARK": 1,
"CHARR": 1,
"CHARS": 1,
"CHARY": 1,
"CHATS": 1,
"CHAVE": 1,
"CHAVS": 1,
"CHAWK": 1,
"CHAWS": 1,
"CHAYA": 1,
"CHAYS": 1,
"CHEEP": 1,
"CHEFS": 1,
"CHEKA": 1,
"CHELA": 1,
"CHELP": 1,
"CHEMO": 1,
"CHEMS": 1,
"CHERE": 1,
"CHERT": 1,
"CHETH": 1,
"CHEVY": 1,
"CHEWS": 1,
"CHEWY": 1,
"CHIAO": 1,
"CHIAS": 1,
"CHIBS": 1,
"CHICA": 1,
"CHICH": 1,
"CHICO": 1,
"CHICS": 1,
"CHIEL": 1,
"CHIKS": 1,
"CHILE": 1,
"CHIMB": 1,
"CHIMO": 1,
"CHIMP": 1,
"CHINE": 1,
"CHING": 1,
"CHINK": 1,
"CHINO": 1,
"CHINS": 1,
"CHIPS": 1,
"CHIRK": 1,
"CHIRL": 1,
"CHIRM": 1,
"CHIRO": 1,
"CHIRR": 1,
"CHIRT": 1,
"CHIRU": 1,
"CHITS": 1,
"CHIVE": 1,
"CHIVS": 1,
"CHIVY": 1,
"CHIZZ": 1,
"CHOCO": 1,
"CHOCS": 1,
"CHODE": 1,
"CHOGS": 1,
"CHOIL": 1,
"CHOKO": 1,
"CHOKY": 1,
"CHOLA": 1,
"CHOLI": 1,
"CHOLO": 1,
"CHOMP": 1,
"CHONS": 1,
"CHOOF": 1,
"CHOOK": 1,
"CHOOM": 1,
"CHOON": 1,
"CHOPS": 1,
"CHOTA": 1,
"CHOTT": 1,
"CHOUT": 1,
"CHOUX": 1,
"CHOWK": 1,
"CHOWS": 1,
"CHUBS": 1,
"CHUFA": 1,
"CHUFF": 1,
"CHUGS": 1,
"CHUMS": 1,
"CHURL": 1,
"CHURR": 1,
"CHUSE": 1,
"CHUTS": 1,
"CHYLE": 1,
"CHYME": 1,
"CHYND": 1,
"CIBOL": 1,
"CIDED": 1,
"CIDES": 1,
"CIELS": 1,
"CIGGY": 1,
"CILIA": 1,
"CILLS": 1,
"CIMAR": 1,
"CIMEX": 1,
"CINCT": 1,
"CINES": 1,
"CINQS": 1,
"CIONS": 1,
"CIPPI": 1,
"CIRCS": 1,
"CIRES": 1,
"CIRLS": 1,
"CIRRI": 1,
"CISCO": 1,
"CISSY": 1,
"CISTS": 1,
"CITAL": 1,
"CITED": 1,
"CITER": 1,
"CITES": 1,
"CIVES": 1,
"CIVET": 1,
"CIVIE": 1,
"CIVVY": 1,
"CLACH": 1,
"CLADE": 1,
"CLADS": 1,
"CLAES": 1,
"CLAGS": 1,
"CLAME": 1,
"CLAMS": 1,
"CLANS": 1,
"CLAPS": 1,
"CLAPT": 1,
"CLARO": 1,
"CLART": 1,
"CLARY": 1,
"CLAST": 1,
"CLATS": 1,
"CLAUT": 1,
"CLAVE": 1,
"CLAVI": 1,
"CLAWS": 1,
"CLAYS": 1,
"CLECK": 1,
"CLEEK": 1,
"CLEEP": 1,
"CLEFS": 1,
"CLEGS": 1,
"CLEIK": 1,
"CLEMS": 1,
"CLEPE": 1,
"CLEPT": 1,
"CLEVE": 1,
"CLEWS": 1,
"CLIED": 1,
"CLIES": 1,
"CLIFT": 1,
"CLIME": 1,
"CLINE": 1,
"CLINT": 1,
"CLIPE": 1,
"CLIPS": 1,
"CLIPT": 1,
"CLITS": 1,
"CLOAM": 1,
"CLODS": 1,
"CLOFF": 1,
"CLOGS": 1,
"CLOKE": 1,
"CLOMB": 1,
"CLOMP": 1,
"CLONK": 1,
"CLONS": 1,
"CLOOP": 1,
"CLOOT": 1,
"CLOPS": 1,
"CLOTE": 1,
"CLOTS": 1,
"CLOUR": 1,
"CLOUS": 1,
"CLOWS": 1,
"CLOYE": 1,
"CLOYS": 1,
"CLOZE": 1,
"CLUBS": 1,
"CLUES": 1,
"CLUEY": 1,
"CLUNK": 1,
"CLYPE": 1,
"CNIDA": 1,
"COACT": 1,
"COADY": 1,
"COALA": 1,
"COALS": 1,
"COALY": 1,
"COAPT": 1,
"COARB": 1,
"COATE": 1,
"COATI": 1,
"COATS": 1,
"COBBS": 1,
"COBBY": 1,
"COBIA": 1,
"COBLE": 1,
"COBZA": 1,
"COCAS": 1,
"COCCI": 1,
"COCCO": 1,
"COCKS": 1,
"COCKY": 1,
"COCOS": 1,
"CODAS": 1,
"CODEC": 1,
"CODED": 1,
"CODEN": 1,
"CODER": 1,
"CODES": 1,
"CODEX": 1,
"CODON": 1,
"COEDS": 1,
"COFFS": 1,
"COGIE": 1,
"COGON": 1,
"COGUE": 1,
"COHAB": 1,
"COHEN": 1,
"COHOE": 1,
"COHOG": 1,
"COHOS": 1,
"COIFS": 1,
"COIGN": 1,
"COILS": 1,
"COINS": 1,
"COIRS": 1,
"COITS": 1,
"COKED": 1,
"COKES": 1,
"COLAS": 1,
"COLBY": 1,
"COLDS": 1,
"COLED": 1,
"COLES": 1,
"COLEY": 1,
"COLIC": 1,
"COLIN": 1,
"COLLS": 1,
"COLLY": 1,
"COLOG": 1,
"COLTS": 1,
"COLZA": 1,
"COMAE": 1,
"COMAL": 1,
"COMAS": 1,
"COMBE": 1,
"COMBI": 1,
"COMBO": 1,
"COMBS": 1,
"COMBY": 1,
"COMER": 1,
"COMES": 1,
"COMIX": 1,
"COMMO": 1,
"COMMS": 1,
"COMMY": 1,
"COMPO": 1,
"COMPS": 1,
"COMPT": 1,
"COMTE": 1,
"COMUS": 1,
"CONED": 1,
"CONES": 1,
"CONEY": 1,
"CONFS": 1,
"CONGA": 1,
"CONGE": 1,
"CONGO": 1,
"CONIA": 1,
"CONIN": 1,
"CONKS": 1,
"CONKY": 1,
"CONNE": 1,
"CONNS": 1,
"CONTE": 1,
"CONTO": 1,
"CONUS": 1,
"CONVO": 1,
"COOCH": 1,
"COOED": 1,
"COOEE": 1,
"COOER": 1,
"COOEY": 1,
"COOFS": 1,
"COOKS": 1,
"COOKY": 1,
"COOLS": 1,
"COOLY": 1,
"COOMB": 1,
"COOMS": 1,
"COOMY": 1,
"COONS": 1,
"COOPS": 1,
"COOPT": 1,
"COOST": 1,
"COOTS": 1,
"COOZE": 1,
"COPAL": 1,
"COPAY": 1,
"COPED": 1,
"COPEN": 1,
"COPER": 1,
"COPES": 1,
"COPPY": 1,
"COPRA": 1,
"COPSY": 1,
"COQUI": 1,
"CORAM": 1,
"CORBE": 1,
"CORBY": 1,
"CORDS": 1,
"CORED": 1,
"CORES": 1,
"COREY": 1,
"CORGI": 1,
"CORIA": 1,
"CORKS": 1,
"CORKY": 1,
"CORMS": 1,
"CORNI": 1,
"CORNO": 1,
"CORNS": 1,
"CORNU": 1,
"CORPS": 1,
"CORSE": 1,
"CORSO": 1,
"COSEC": 1,
"COSED": 1,
"COSES": 1,
"COSET": 1,
"COSEY": 1,
"COSIE": 1,
"COSTA": 1,
"COSTE": 1,
"COSTS": 1,
"COTAN": 1,
"COTED": 1,
"COTES": 1,
"COTHS": 1,
"COTTA": 1,
"COTTS": 1,
"COUDE": 1,
"COUPS": 1,
"COURB": 1,
"COURD": 1,
"COURE": 1,
"COURS": 1,
"COUTA": 1,
"COUTH": 1,
"COVED": 1,
"COVES": 1,
"COVIN": 1,
"COWAL": 1,
"COWAN": 1,
"COWED": 1,
"COWKS": 1,
"COWLS": 1,
"COWPS": 1,
"COWRY": 1,
"COXAE": 1,
"COXAL": 1,
"COXED": 1,
"COXES": 1,
"COXIB": 1,
"COYAU": 1,
"COYED": 1,
"COYER": 1,
"COYPU": 1,
"COZED": 1,
"COZEN": 1,
"COZES": 1,
"COZEY": 1,
"COZIE": 1,
"CRAAL": 1,
"CRABS": 1,
"CRAGS": 1,
"CRAIC": 1,
"CRAIG": 1,
"CRAKE": 1,
"CRAME": 1,
"CRAMS": 1,
"CRANS": 1,
"CRAPE": 1,
"CRAPS": 1,
"CRAPY": 1,
"CRARE": 1,
"CRAWS": 1,
"CRAYS": 1,
"CREDS": 1,
"CREEL": 1,
"CREES": 1,
"CREMS": 1,
"CRENA": 1,
"CREPS": 1,
"CREPY": 1,
"CREWE": 1,
"CREWS": 1,
"CRIAS": 1,
"CRIBS": 1,
"CRIES": 1,
"CRIMS": 1,
"CRINE": 1,
"CRIOS": 1,
"CRIPE": 1,
"CRIPS": 1,
"CRISE": 1,
"CRITH": 1,
"CRITS": 1,
"CROCI": 1,
"CROCS": 1,
"CROFT": 1,
"CROGS": 1,
"CROMB": 1,
"CROME": 1,
"CRONK": 1,
"CRONS": 1,
"CROOL": 1,
"CROON": 1,
"CROPS": 1,
"CRORE": 1,
"CROST": 1,
"CROUT": 1,
"CROWS": 1,
"CROZE": 1,
"CRUCK": 1,
"CRUDO": 1,
"CRUDS": 1,
"CRUDY": 1,
"CRUES": 1,
"CRUET": 1,
"CRUFT": 1,
"CRUNK": 1,
"CRUOR": 1,
"CRURA": 1,
"CRUSE": 1,
"CRUSY": 1,
"CRUVE": 1,
"CRWTH": 1,
"CRYER": 1,
"CTENE": 1,
"CUBBY": 1,
"CUBEB": 1,
"CUBED": 1,
"CUBER": 1,
"CUBES": 1,
"CUBIT": 1,
"CUDDY": 1,
"CUFFO": 1,
"CUFFS": 1,
"CUIFS": 1,
"CUING": 1,
"CUISH": 1,
"CUITS": 1,
"CUKES": 1,
"CULCH": 1,
"CULET": 1,
"CULEX": 1,
"CULLS": 1,
"CULLY": 1,
"CULMS": 1,
"CULPA": 1,
"CULTI": 1,
"CULTS": 1,
"CULTY": 1,
"CUMEC": 1,
"CUNDY": 1,
"CUNEI": 1,
"CUNIT": 1,
"CUNTS": 1,
"CUPEL": 1,
"CUPID": 1,
"CUPPA": 1,
"CUPPY": 1,
"CURAT": 1,
"CURBS": 1,
"CURCH": 1,
"CURDS": 1,
"CURDY": 1,
"CURED": 1,
"CURER": 1,
"CURES": 1,
"CURET": 1,
"CURFS": 1,
"CURIA": 1,
"CURIE": 1,
"CURLI": 1,
"CURLS": 1,
"CURNS": 1,
"CURNY": 1,
"CURRS": 1,
"CURSI": 1,
"CURST": 1,
"CUSEC": 1,
"CUSHY": 1,
"CUSKS": 1,
"CUSPS": 1,
"CUSPY": 1,
"CUSSO": 1,
"CUSUM": 1,
"CUTCH": 1,
"CUTER": 1,
"CUTES": 1,
"CUTEY": 1,
"CUTIN": 1,
"CUTIS": 1,
"CUTTO": 1,
"CUTTY": 1,
"CUTUP": 1,
"CUVEE": 1,
"CUZES": 1,
"CWTCH": 1,
"CYANO": 1,
"CYANS": 1,
"CYCAD": 1,
"CYCAS": 1,
"CYCLO": 1,
"CYDER": 1,
"CYLIX": 1,
"CYMAE": 1,
"CYMAR": 1,
"CYMAS": 1,
"CYMES": 1,
"CYMOL": 1,
"CYSTS": 1,
"CYTES": 1,
"CYTON": 1,
"CZARS": 1,
"DAALS": 1,
"DABBA": 1,
"DACES": 1,
"DACHA": 1,
"DACKS": 1,
"DADAH": 1,
"DADAS": 1,
"DADOS": 1,
"DAFFS": 1,
"DAFFY": 1,
"DAGGA": 1,
"DAGGY": 1,
"DAGOS": 1,
"DAHLS": 1,
"DAIKO": 1,
"DAINE": 1,
"DAINT": 1,
"DAKER": 1,
"DALED": 1,
"DALES": 1,
"DALIS": 1,
"DALLE": 1,
"DALTS": 1,
"DAMAN": 1,
"DAMAR": 1,
"DAMES": 1,
"DAMME": 1,
"DAMNS": 1,
"DAMPS": 1,
"DAMPY": 1,
"DANCY": 1,
"DANGS": 1,
"DANIO": 1,
"DANKS": 1,
"DANNY": 1,
"DANTS": 1,
"DARAF": 1,
"DARBS": 1,
"DARCY": 1,
"DARED": 1,
"DARER": 1,
"DARES": 1,
"DARGA": 1,
"DARGS": 1,
"DARIC": 1,
"DARIS": 1,
"DARKS": 1,
"DARKY": 1,
"DARNS": 1,
"DARRE": 1,
"DARTS": 1,
"DARZI": 1,
"DASHI": 1,
"DASHY": 1,
"DATAL": 1,
"DATED": 1,
"DATER": 1,
"DATES": 1,
"DATOS": 1,
"DATTO": 1,
"DAUBE": 1,
"DAUBS": 1,
"DAUBY": 1,
"DAUDS": 1,
"DAULT": 1,
"DAURS": 1,
"DAUTS": 1,
"DAVEN": 1,
"DAVIT": 1,
"DAWAH": 1,
"DAWDS": 1,
"DAWED": 1,
"DAWEN": 1,
"DAWKS": 1,
"DAWNS": 1,
"DAWTS": 1,
"DAYAN": 1,
"DAYCH": 1,
"DAYNT": 1,
"DAZED": 1,
"DAZER": 1,
"DAZES": 1,
"DEADS": 1,
"DEAIR": 1,
"DEALS": 1,
"DEANS": 1,
"DEARE": 1,
"DEARN": 1,
"DEARS": 1,
"DEARY": 1,
"DEASH": 1,
"DEAVE": 1,
"DEAWS": 1,
"DEAWY": 1,
"DEBAG": 1,
"DEBBY": 1,
"DEBEL": 1,
"DEBES": 1,
"DEBTS": 1,
"DEBUD": 1,
"DEBUR": 1,
"DEBUS": 1,
"DEBYE": 1,
"DECAD": 1,
"DECAF": 1,
"DECAN": 1,
"DECKO": 1,
"DECKS": 1,
"DECOS": 1,
"DEDAL": 1,
"DEEDS": 1,
"DEEDY": 1,
"DEELY": 1,
"DEEMS": 1,
"DEENS": 1,
"DEEPS": 1,
"DEERE": 1,
"DEERS": 1,
"DEETS": 1,
"DEEVE": 1,
"DEEVS": 1,
"DEFAT": 1,
"DEFFO": 1,
"DEFIS": 1,
"DEFOG": 1,
"DEGAS": 1,
"DEGUM": 1,
"DEGUS": 1,
"DEICE": 1,
"DEIDS": 1,
"DEIFY": 1,
"DEILS": 1,
"DEISM": 1,
"DEIST": 1,
"DEKED": 1,
"DEKES": 1,
"DEKKO": 1,
"DELED": 1,
"DELES": 1,
"DELFS": 1,
"DELFT": 1,
"DELIS": 1,
"DELLS": 1,
"DELLY": 1,
"DELOS": 1,
"DELPH": 1,
"DELTS": 1,
"DEMAN": 1,
"DEMES": 1,
"DEMIC": 1,
"DEMIT": 1,
"DEMOB": 1,
"DEMOI": 1,
"DEMOS": 1,
"DEMPT": 1,
"DENAR": 1,
"DENAY": 1,
"DENCH": 1,
"DENES": 1,
"DENET": 1,
"DENIS": 1,
"DENTS": 1,
"DEOXY": 1,
"DERAT": 1,
"DERAY": 1,
"DERED": 1,
"DERES": 1,
"DERIG": 1,
"DERMA": 1,
"DERMS": 1,
"DERNS": 1,
"DERNY": 1,
"DEROS": 1,
"DERRO": 1,
"DERRY": 1,
"DERTH": 1,
"DERVS": 1,
"DESEX": 1,
"DESHI": 1,
"DESIS": 1,
"DESKS": 1,
"DESSE": 1,
"DEVAS": 1,
"DEVEL": 1,
"DEVIS": 1,
"DEVON": 1,
"DEVOS": 1,
"DEVOT": 1,
"DEWAN": 1,
"DEWAR": 1,
"DEWAX": 1,
"DEWED": 1,
"DEXES": 1,
"DEXIE": 1,
"DHABA": 1,
"DHAKS": 1,
"DHALS": 1,
"DHIKR": 1,
"DHOBI": 1,
"DHOLE": 1,
"DHOLL": 1,
"DHOLS": 1,
"DHOTI": 1,
"DHOWS": 1,
"DHUTI": 1,
"DIACT": 1,
"DIALS": 1,
"DIANE": 1,
"DIAZO": 1,
"DIBBS": 1,
"DICED": 1,
"DICER": 1,
"DICES": 1,
"DICHT": 1,
"DICKS": 1,
"DICKY": 1,
"DICOT": 1,
"DICTA": 1,
"DICTS": 1,
"DICTY": 1,
"DIDDY": 1,
"DIDIE": 1,
"DIDOS": 1,
"DIDST": 1,
"DIEBS": 1,
"DIELS": 1,
"DIENE": 1,
"DIETS": 1,
"DIFFS": 1,
"DIGHT": 1,
"DIKAS": 1,
"DIKED": 1,
"DIKER": 1,
"DIKES": 1,
"DIKEY": 1,
"DILDO": 1,
"DILLI": 1,
"DILLS": 1,
"DIMBO": 1,
"DIMER": 1,
"DIMES": 1,
"DIMPS": 1,
"DINAR": 1,
"DINED": 1,
"DINES": 1,
"DINGE": 1,
"DINGS": 1,
"DINIC": 1,
"DINKS": 1,
"DINKY": 1,
"DINNA": 1,
"DINOS": 1,
"DINTS": 1,
"DIOLS": 1,
"DIOTA": 1,
"DIPPY": 1,
"DIPSO": 1,
"DIRAM": 1,
"DIRER": 1,
"DIRKE": 1,
"DIRKS": 1,
"DIRLS": 1,
"DIRTS": 1,
"DISAS": 1,
"DISCI": 1,
"DISCS": 1,
"DISHY": 1,
"DISKS": 1,
"DISME": 1,
"DITAL": 1,
"DITAS": 1,
"DITED": 1,
"DITES": 1,
"DITSY": 1,
"DITTS": 1,
"DITZY": 1,
"DIVAN": 1,
"DIVAS": 1,
"DIVED": 1,
"DIVES": 1,
"DIVIS": 1,
"DIVNA": 1,
"DIVOS": 1,
"DIVOT": 1,
"DIVVY": 1,
"DIWAN": 1,
"DIXIE": 1,
"DIXIT": 1,
"DIYAS": 1,
"DIZEN": 1,
"DJINN": 1,
"DJINS": 1,
"DOABS": 1,
"DOATS": 1,
"DOBBY": 1,
"DOBES": 1,
"DOBIE": 1,
"DOBLA": 1,
"DOBRA": 1,
"DOBRO": 1,
"DOCHT": 1,
"DOCKS": 1,
"DOCOS": 1,
"DOCUS": 1,
"DODDY": 1,
"DODOS": 1,
"DOEKS": 1,
"DOERS": 1,
"DOEST": 1,
"DOETH": 1,
"DOFFS": 1,
"DOGAN": 1,
"DOGES": 1,
"DOGEY": 1,
"DOGGO": 1,
"DOGGY": 1,
"DOGIE": 1,
"DOHYO": 1,
"DOILT": 1,
"DOILY": 1,
"DOITS": 1,
"DOJOS": 1,
"DOLCE": 1,
"DOLCI": 1,
"DOLED": 1,
"DOLES": 1,
"DOLIA": 1,
"DOLLS": 1,
"DOLMA": 1,
"DOLOR": 1,
"DOLOS": 1,
"DOLTS": 1,
"DOMAL": 1,
"DOMED": 1,
"DOMES": 1,
"DOMIC": 1,
"DONAH": 1,
"DONAS": 1,
"DONEE": 1,
"DONER": 1,
"DONGA": 1,
"DONGS": 1,
"DONKO": 1,
"DONNA": 1,
"DONNE": 1,
"DONNY": 1,
"DONSY": 1,
"DOOBS": 1,
"DOOCE": 1,
"DOODY": 1,
"DOOKS": 1,
"DOOLE": 1,
"DOOLS": 1,
"DOOLY": 1,
"DOOMS": 1,
"DOOMY": 1,
"DOONA": 1,
"DOORN": 1,
"DOORS": 1,
"DOOZY": 1,
"DOPAS": 1,
"DOPED": 1,
"DOPER": 1,
"DOPES": 1,
"DORAD": 1,
"DORBA": 1,
"DORBS": 1,
"DOREE": 1,
"DORES": 1,
"DORIC": 1,
"DORIS": 1,
"DORKS": 1,
"DORKY": 1,
"DORMS": 1,
"DORMY": 1,
"DORPS": 1,
"DORRS": 1,
"DORSA": 1,
"DORSE": 1,
"DORTS": 1,
"DORTY": 1,
"DOSAI": 1,
"DOSAS": 1,
"DOSED": 1,
"DOSEH": 1,
"DOSER": 1,
"DOSES": 1,
"DOSHA": 1,
"DOTAL": 1,
"DOTED": 1,
"DOTER": 1,
"DOTES": 1,
"DOTTY": 1,
"DOUAR": 1,
"DOUCE": 1,
"DOUCS": 1,
"DOUKS": 1,
"DOULA": 1,
"DOUMA": 1,
"DOUMS": 1,
"DOUPS": 1,
"DOURA": 1,
"DOUSE": 1,
"DOUTS": 1,
"DOVED": 1,
"DOVEN": 1,
"DOVER": 1,
"DOVES": 1,
"DOVIE": 1,
"DOWAR": 1,
"DOWDS": 1,
"DOWED": 1,
"DOWER": 1,
"DOWIE": 1,
"DOWLE": 1,
"DOWLS": 1,
"DOWLY": 1,
"DOWNA": 1,
"DOWNS": 1,
"DOWPS": 1,
"DOWSE": 1,
"DOWTS": 1,
"DOXED": 1,
"DOXES": 1,
"DOXIE": 1,
"DOYEN": 1,
"DOYLY": 1,
"DOZED": 1,
"DOZER": 1,
"DOZES": 1,
"DRABS": 1,
"DRACK": 1,
"DRACO": 1,
"DRAFF": 1,
"DRAGS": 1,
"DRAIL": 1,
"DRAMS": 1,
"DRANT": 1,
"DRAPS": 1,
"DRATS": 1,
"DRAVE": 1,
"DRAWS": 1,
"DRAYS": 1,
"DREAR": 1,
"DRECK": 1,
"DREED": 1,
"DREER": 1,
"DREES": 1,
"DREGS": 1,
"DREKS": 1,
"DRENT": 1,
"DRERE": 1,
"DREST": 1,
"DREYS": 1,
"DRIBS": 1,
"DRICE": 1,
"DRIES": 1,
"DRILY": 1,
"DRIPS": 1,
"DRIPT": 1,
"DROID": 1,
"DROIL": 1,
"DROKE": 1,
"DROLE": 1,
"DROME": 1,
"DRONY": 1,
"DROOB": 1,
"DROOG": 1,
"DROOK": 1,
"DROPS": 1,
"DROPT": 1,
"DROUK": 1,
"DROWS": 1,
"DRUBS": 1,
"DRUGS": 1,
"DRUMS": 1,
"DRUPE": 1,
"DRUSE": 1,
"DRUSY": 1,
"DRUXY": 1,
"DRYAD": 1,
"DRYAS": 1,
"DSOBO": 1,
"DSOMO": 1,
"DUADS": 1,
"DUALS": 1,
"DUANS": 1,
"DUARS": 1,
"DUBBO": 1,
"DUCAL": 1,
"DUCAT": 1,
"DUCES": 1,
"DUCKS": 1,
"DUCKY": 1,
"DUCTS": 1,
"DUDDY": 1,
"DUDED": 1,
"DUDES": 1,
"DUELS": 1,
"DUETS": 1,
"DUETT": 1,
"DUFFS": 1,
"DUFUS": 1,
"DUING": 1,
"DUITS": 1,
"DUKAS": 1,
"DUKED": 1,
"DUKES": 1,
"DUKKA": 1,
"DULCE": 1,
"DULES": 1,
"DULIA": 1,
"DULLS": 1,
"DULSE": 1,
"DUMAS": 1,
"DUMBO": 1,
"DUMBS": 1,
"DUMKA": 1,
"DUMKY": 1,
"DUMPS": 1,
"DUNAM": 1,
"DUNCH": 1,
"DUNES": 1,
"DUNGS": 1,
"DUNGY": 1,
"DUNKS": 1,
"DUNNO": 1,
"DUNNY": 1,
"DUNSH": 1,
"DUNTS": 1,
"DUOMI": 1,
"DUOMO": 1,
"DUPED": 1,
"DUPER": 1,
"DUPES": 1,
"DUPLE": 1,
"DUPLY": 1,
"DUPPY": 1,
"DURAL": 1,
"DURAS": 1,
"DURED": 1,
"DURES": 1,
"DURGY": 1,
"DURNS": 1,
"DUROC": 1,
"DUROS": 1,
"DUROY": 1,
"DURRA": 1,
"DURRS": 1,
"DURRY": 1,
"DURST": 1,
"DURUM": 1,
"DURZI": 1,
"DUSKS": 1,
"DUSTS": 1,
"DUXES": 1,
"DWAAL": 1,
"DWALE": 1,
"DWALM": 1,
"DWAMS": 1,
"DWANG": 1,
"DWAUM": 1,
"DWEEB": 1,
"DWILE": 1,
"DWINE": 1,
"DYADS": 1,
"DYERS": 1,
"DYKED": 1,
"DYKES": 1,
"DYKEY": 1,
"DYKON": 1,
"DYNEL": 1,
"DYNES": 1,
"DZHOS": 1,
"EAGRE": 1,
"EALED": 1,
"EALES": 1,
"EANED": 1,
"EARDS": 1,
"EARED": 1,
"EARLS": 1,
"EARNS": 1,
"EARNT": 1,
"EARST": 1,
"EASED": 1,
"EASER": 1,
"EASES": 1,
"EASLE": 1,
"EASTS": 1,
"EATHE": 1,
"EAVED": 1,
"EAVES": 1,
"EBBED": 1,
"EBBET": 1,
"EBONS": 1,
"EBOOK": 1,
"ECADS": 1,
"ECHED": 1,
"ECHES": 1,
"ECHOS": 1,
"ECRUS": 1,
"EDEMA": 1,
"EDGED": 1,
"EDGER": 1,
"EDGES": 1,
"EDILE": 1,
"EDITS": 1,
"EDUCE": 1,
"EDUCT": 1,
"EEJIT": 1,
"EENSY": 1,
"EEVEN": 1,
"EEVNS": 1,
"EFFED": 1,
"EGADS": 1,
"EGERS": 1,
"EGEST": 1,
"EGGAR": 1,
"EGGED": 1,
"EGGER": 1,
"EGMAS": 1,
"EHING": 1,
"EIDER": 1,
"EIDOS": 1,
"EIGNE": 1,
"EIKED": 1,
"EIKON": 1,
"EILDS": 1,
"EISEL": 1,
"EJIDO": 1,
"EKKAS": 1,
"ELAIN": 1,
"ELAND": 1,
"ELANS": 1,
"ELCHI": 1,
"ELDIN": 1,
"ELEMI": 1,
"ELFED": 1,
"ELIAD": 1,
"ELINT": 1,
"ELMEN": 1,
"ELOGE": 1,
"ELOGY": 1,
"ELOIN": 1,
"ELOPS": 1,
"ELPEE": 1,
"ELSIN": 1,
"ELUTE": 1,
"ELVAN": 1,
"ELVEN": 1,
"ELVER": 1,
"ELVES": 1,
"EMACS": 1,
"EMBAR": 1,
"EMBAY": 1,
"EMBOG": 1,
"EMBOW": 1,
"EMBOX": 1,
"EMBUS": 1,
"EMEER": 1,
"EMEND": 1,
"EMERG": 1,
"EMERY": 1,
"EMEUS": 1,
"EMICS": 1,
"EMIRS": 1,
"EMITS": 1,
"EMMAS": 1,
"EMMER": 1,
"EMMET": 1,
"EMMEW": 1,
"EMMYS": 1,
"EMOJI": 1,
"EMONG": 1,
"EMOTE": 1,
"EMOVE": 1,
"EMPTS": 1,
"EMULE": 1,
"EMURE": 1,
"EMYDE": 1,
"EMYDS": 1,
"ENARM": 1,
"ENATE": 1,
"ENDED": 1,
"ENDER": 1,
"ENDEW": 1,
"ENDUE": 1,
"ENEWS": 1,
"ENFIX": 1,
"ENIAC": 1,
"ENLIT": 1,
"ENMEW": 1,
"ENNOG": 1,
"ENOKI": 1,
"ENOLS": 1,
"ENORM": 1,
"ENOWS": 1,
"ENROL": 1,
"ENSEW": 1,
"ENSKY": 1,
"ENTIA": 1,
"ENURE": 1,
"ENURN": 1,
"ENVOI": 1,
"ENZYM": 1,
"EORLS": 1,
"EOSIN": 1,
"EPACT": 1,
"EPEES": 1,
"EPHAH": 1,
"EPHAS": 1,
"EPHOD": 1,
"EPHOR": 1,
"EPICS": 1,
"EPODE": 1,
"EPOPT": 1,
"EPRIS": 1,
"EQUES": 1,
"EQUID": 1,
"ERBIA": 1,
"EREVS": 1,
"ERGON": 1,
"ERGOS": 1,
"ERGOT": 1,
"ERHUS": 1,
"ERICA": 1,
"ERICK": 1,
"ERICS": 1,
"ERING": 1,
"ERNED": 1,
"ERNES": 1,
"EROSE": 1,
"ERRED": 1,
"ERSES": 1,
"ERUCT": 1,
"ERUGO": 1,
"ERUVS": 1,
"ERVEN": 1,
"ERVIL": 1,
"ESCAR": 1,
"ESCOT": 1,
"ESILE": 1,
"ESKAR": 1,
"ESKER": 1,
"ESNES": 1,
"ESSES": 1,
"ESTOC": 1,
"ESTOP": 1,
"ESTRO": 1,
"ETAGE": 1,
"ETAPE": 1,
"ETATS": 1,
"ETENS": 1,
"ETHAL": 1,
"ETHNE": 1,
"ETHYL": 1,
"ETICS": 1,
"ETNAS": 1,
"ETTIN": 1,
"ETTLE": 1,
"ETUIS": 1,
"ETWEE": 1,
"ETYMA": 1,
"EUGHS": 1,
"EUKED": 1,
"EUPAD": 1,
"EUROS": 1,
"EUSOL": 1,
"EVENS": 1,
"EVERT": 1,
"EVETS": 1,
"EVHOE": 1,
"EVILS": 1,
"EVITE": 1,
"EVOHE": 1,
"EWERS": 1,
"EWEST": 1,
"EWHOW": 1,
"EWKED": 1,
"EXAMS": 1,
"EXEAT": 1,
"EXECS": 1,
"EXEEM": 1,
"EXEME": 1,
"EXFIL": 1,
"EXIES": 1,
"EXINE": 1,
"EXING": 1,
"EXITS": 1,
"EXODE": 1,
"EXOME": 1,
"EXONS": 1,
"EXPAT": 1,
"EXPOS": 1,
"EXUDE": 1,
"EXULS": 1,
"EXURB": 1,
"EYASS": 1,
"EYERS": 1,
"EYOTS": 1,
"EYRAS": 1,
"EYRES": 1,
"EYRIE": 1,
"EYRIR": 1,
"EZINE": 1,
"FABBY": 1,
"FACED": 1,
"FACER": 1,
"FACES": 1,
"FACIA": 1,
"FACTA": 1,
"FACTS": 1,
"FADDY": 1,
"FADED": 1,
"FADER": 1,
"FADES": 1,
"FADGE": 1,
"FADOS": 1,
"FAENA": 1,
"FAERY": 1,
"FAFFS": 1,
"FAFFY": 1,
"FAGGY": 1,
"FAGIN": 1,
"FAGOT": 1,
"FAIKS": 1,
"FAILS": 1,
"FAINE": 1,
"FAINS": 1,
"FAIRS": 1,
"FAKED": 1,
"FAKER": 1,
"FAKES": 1,
"FAKEY": 1,
"FAKIE": 1,
"FAKIR": 1,
"FALAJ": 1,
"FALLS": 1,
"FAMED": 1,
"FAMES": 1,
"FANAL": 1,
"FANDS": 1,
"FANES": 1,
"FANGA": 1,
"FANGO": 1,
"FANGS": 1,
"FANKS": 1,
"FANON": 1,
"FANOS": 1,
"FANUM": 1,
"FAQIR": 1,
"FARAD": 1,
"FARCI": 1,
"FARCY": 1,
"FARDS": 1,
"FARED": 1,
"FARER": 1,
"FARES": 1,
"FARLE": 1,
"FARLS": 1,
"FARMS": 1,
"FAROS": 1,
"FARRO": 1,
"FARSE": 1,
"FARTS": 1,
"FASCI": 1,
"FASTI": 1,
"FASTS": 1,
"FATED": 1,
"FATES": 1,
"FATLY": 1,
"FATSO": 1,
"FATWA": 1,
"FAUGH": 1,
"FAULD": 1,
"FAUNS": 1,
"FAURD": 1,
"FAUTS": 1,
"FAUVE": 1,
"FAVAS": 1,
"FAVEL": 1,
"FAVER": 1,
"FAVES": 1,
"FAVUS": 1,
"FAWNS": 1,
"FAWNY": 1,
"FAXED": 1,
"FAXES": 1,
"FAYED": 1,
"FAYER": 1,
"FAYNE": 1,
"FAYRE": 1,
"FAZED": 1,
"FAZES": 1,
"FEALS": 1,
"FEARE": 1,
"FEARS": 1,
"FEART": 1,
"FEASE": 1,
"FEATS": 1,
"FEAZE": 1,
"FECES": 1,
"FECHT": 1,
"FECIT": 1,
"FECKS": 1,
"FEDEX": 1,
"FEEBS": 1,
"FEEDS": 1,
"FEELS": 1,
"FEENS": 1,
"FEERS": 1,
"FEESE": 1,
"FEEZE": 1,
"FEHME": 1,
"FEINT": 1,
"FEIST": 1,
"FELCH": 1,
"FELID": 1,
"FELLS": 1,
"FELLY": 1,
"FELTS": 1,
"FELTY": 1,
"FEMAL": 1,
"FEMES": 1,
"FEMMY": 1,
"FENDS": 1,
"FENDY": 1,
"FENIS": 1,
"FENKS": 1,
"FENNY": 1,
"FENTS": 1,
"FEODS": 1,
"FEOFF": 1,
"FERER": 1,
"FERES": 1,
"FERIA": 1,
"FERLY": 1,
"FERMI": 1,
"FERMS": 1,
"FERNS": 1,
"FERNY": 1,
"FESSE": 1,
"FESTA": 1,
"FESTS": 1,
"FESTY": 1,
"FETAS": 1,
"FETED": 1,
"FETES": 1,
"FETOR": 1,
"FETTA": 1,
"FETTS": 1,
"FETWA": 1,
"FEUAR": 1,
"FEUDS": 1,
"FEUED": 1,
"FEYED": 1,
"FEYER": 1,
"FEYLY": 1,
"FEZES": 1,
"FEZZY": 1,
"FIARS": 1,
"FIATS": 1,
"FIBRO": 1,
"FICES": 1,
"FICHE": 1,
"FICHU": 1,
"FICIN": 1,
"FICOS": 1,
"FIDES": 1,
"FIDGE": 1,
"FIDOS": 1,
"FIEFS": 1,
"FIENT": 1,
"FIERE": 1,
"FIERS": 1,
"FIEST": 1,
"FIFED": 1,
"FIFER": 1,
"FIFES": 1,
"FIFIS": 1,
"FIGGY": 1,
"FIGOS": 1,
"FIKED": 1,
"FIKES": 1,
"FILAR": 1,
"FILCH": 1,
"FILED": 1,
"FILES": 1,
"FILII": 1,
"FILKS": 1,
"FILLE": 1,
"FILLO": 1,
"FILLS": 1,
"FILMI": 1,
"FILMS": 1,
"FILOS": 1,
"FILUM": 1,
"FINCA": 1,
"FINDS": 1,
"FINED": 1,
"FINES": 1,
"FINIS": 1,
"FINKS": 1,
"FINNY": 1,
"FINOS": 1,
"FIORD": 1,
"FIQHS": 1,
"FIQUE": 1,
"FIRED": 1,
"FIRER": 1,
"FIRES": 1,
"FIRIE": 1,
"FIRKS": 1,
"FIRMS": 1,
"FIRNS": 1,
"FIRRY": 1,
"FIRTH": 1,
"FISCS": 1,
"FISKS": 1,
"FISTS": 1,
"FISTY": 1,
"FITCH": 1,
"FITLY": 1,
"FITNA": 1,
"FITTE": 1,
"FITTS": 1,
"FIVER": 1,
"FIVES": 1,
"FIXED": 1,
"FIXES": 1,
"FIXIT": 1,
"FJELD": 1,
"FLABS": 1,
"FLAFF": 1,
"FLAGS": 1,
"FLAKS": 1,
"FLAMM": 1,
"FLAMS": 1,
"FLAMY": 1,
"FLANE": 1,
"FLANS": 1,
"FLAPS": 1,
"FLARY": 1,
"FLATS": 1,
"FLAVA": 1,
"FLAWN": 1,
"FLAWS": 1,
"FLAWY": 1,
"FLAXY": 1,
"FLAYS": 1,
"FLEAM": 1,
"FLEAS": 1,
"FLEEK": 1,
"FLEER": 1,
"FLEES": 1,
"FLEGS": 1,
"FLEME": 1,
"FLEUR": 1,
"FLEWS": 1,
"FLEXI": 1,
"FLEXO": 1,
"FLEYS": 1,
"FLICS": 1,
"FLIED": 1,
"FLIES": 1,
"FLIMP": 1,
"FLIMS": 1,
"FLIPS": 1,
"FLIRS": 1,
"FLISK": 1,
"FLITE": 1,
"FLITS": 1,
"FLITT": 1,
"FLOBS": 1,
"FLOCS": 1,
"FLOES": 1,
"FLOGS": 1,
"FLONG": 1,
"FLOPS": 1,
"FLORS": 1,
"FLORY": 1,
"FLOSH": 1,
"FLOTA": 1,
"FLOTE": 1,
"FLOWS": 1,
"FLUBS": 1,
"FLUED": 1,
"FLUES": 1,
"FLUEY": 1,
"FLUKY": 1,
"FLUMP": 1,
"FLUOR": 1,
"FLURR": 1,
"FLUTY": 1,
"FLUYT": 1,
"FLYBY": 1,
"FLYPE": 1,
"FLYTE": 1,
"FOALS": 1,
"FOAMS": 1,
"FOEHN": 1,
"FOGEY": 1,
"FOGIE": 1,
"FOGLE": 1,
"FOGOU": 1,
"FOHNS": 1,
"FOIDS": 1,
"FOILS": 1,
"FOINS": 1,
"FOLDS": 1,
"FOLEY": 1,
"FOLIA": 1,
"FOLIC": 1,
"FOLIE": 1,
"FOLKS": 1,
"FOLKY": 1,
"FOMES": 1,
"FONDA": 1,
"FONDS": 1,
"FONDU": 1,
"FONES": 1,
"FONLY": 1,
"FONTS": 1,
"FOODS": 1,
"FOODY": 1,
"FOOLS": 1,
"FOOTS": 1,
"FOOTY": 1,
"FORAM": 1,
"FORBS": 1,
"FORBY": 1,
"FORDO": 1,
"FORDS": 1,
"FOREL": 1,
"FORES": 1,
"FOREX": 1,
"FORKS": 1,
"FORKY": 1,
"FORME": 1,
"FORMS": 1,
"FORTS": 1,
"FORZA": 1,
"FORZE": 1,
"FOSSA": 1,
"FOSSE": 1,
"FOUAT": 1,
"FOUDS": 1,
"FOUER": 1,
"FOUET": 1,
"FOULE": 1,
"FOULS": 1,
"FOUNT": 1,
"FOURS": 1,
"FOUTH": 1,
"FOVEA": 1,
"FOWLS": 1,
"FOWTH": 1,
"FOXED": 1,
"FOXES": 1,
"FOXIE": 1,
"FOYLE": 1,
"FOYNE": 1,
"FRABS": 1,
"FRACK": 1,
"FRACT": 1,
"FRAGS": 1,
"FRAIM": 1,
"FRANC": 1,
"FRAPE": 1,
"FRAPS": 1,
"FRASS": 1,
"FRATE": 1,
"FRATI": 1,
"FRATS": 1,
"FRAUS": 1,
"FRAYS": 1,
"FREES": 1,
"FREET": 1,
"FREIT": 1,
"FREMD": 1,
"FRENA": 1,
"FREON": 1,
"FRERE": 1,
"FRETS": 1,
"FRIBS": 1,
"FRIER": 1,
"FRIES": 1,
"FRIGS": 1,
"FRISE": 1,
"FRIST": 1,
"FRITH": 1,
"FRITS": 1,
"FRITT": 1,
"FRIZE": 1,
"FRIZZ": 1,
"FROES": 1,
"FROGS": 1,
"FRONS": 1,
"FRORE": 1,
"FRORN": 1,
"FRORY": 1,
"FROSH": 1,
"FROWS": 1,
"FROWY": 1,
"FRUGS": 1,
"FRUMP": 1,
"FRUSH": 1,
"FRUST": 1,
"FRYER": 1,
"FUBAR": 1,
"FUBBY": 1,
"FUBSY": 1,
"FUCKS": 1,
"FUCUS": 1,
"FUDDY": 1,
"FUDGY": 1,
"FUELS": 1,
"FUERO": 1,
"FUFFS": 1,
"FUFFY": 1,
"FUGAL": 1,
"FUGGY": 1,
"FUGIE": 1,
"FUGIO": 1,
"FUGLE": 1,
"FUGLY": 1,
"FUGUS": 1,
"FUJIS": 1,
"FULLS": 1,
"FUMED": 1,
"FUMER": 1,
"FUMES": 1,
"FUMET": 1,
"FUNDI": 1,
"FUNDS": 1,
"FUNDY": 1,
"FUNGO": 1,
"FUNGS": 1,
"FUNKS": 1,
"FURAL": 1,
"FURAN": 1,
"FURCA": 1,
"FURLS": 1,
"FUROL": 1,
"FURRS": 1,
"FURTH": 1,
"FURZE": 1,
"FURZY": 1,
"FUSED": 1,
"FUSEE": 1,
"FUSEL": 1,
"FUSES": 1,
"FUSIL": 1,
"FUSKS": 1,
"FUSTS": 1,
"FUSTY": 1,
"FUTON": 1,
"FUZED": 1,
"FUZEE": 1,
"FUZES": 1,
"FUZIL": 1,
"FYCES": 1,
"FYKED": 1,
"FYKES": 1,
"FYLES": 1,
"FYRDS": 1,
"FYTTE": 1,
"GABBA": 1,
"GABBY": 1,
"GABLE": 1,
"GADDI": 1,
"GADES": 1,
"GADGE": 1,
"GADID": 1,
"GADIS": 1,
"GADJE": 1,
"GADJO": 1,
"GADSO": 1,
"GAFFS": 1,
"GAGED": 1,
"GAGER": 1,
"GAGES": 1,
"GAIDS": 1,
"GAINS": 1,
"GAIRS": 1,
"GAITA": 1,
"GAITS": 1,
"GAITT": 1,
"GAJOS": 1,
"GALAH": 1,
"GALAS": 1,
"GALAX": 1,
"GALEA": 1,
"GALED": 1,
"GALES": 1,
"GALLS": 1,
"GALLY": 1,
"GALOP": 1,
"GALUT": 1,
"GALVO": 1,
"GAMAS": 1,
"GAMAY": 1,
"GAMBA": 1,
"GAMBE": 1,
"GAMBO": 1,
"GAMBS": 1,
"GAMED": 1,
"GAMES": 1,
"GAMEY": 1,
"GAMIC": 1,
"GAMIN": 1,
"GAMME": 1,
"GAMMY": 1,
"GAMPS": 1,
"GANCH": 1,
"GANDY": 1,
"GANEF": 1,
"GANEV": 1,
"GANGS": 1,
"GANJA": 1,
"GANOF": 1,
"GANTS": 1,
"GAOLS": 1,
"GAPED": 1,
"GAPER": 1,
"GAPES": 1,
"GAPOS": 1,
"GAPPY": 1,
"GARBE": 1,
"GARBO": 1,
"GARBS": 1,
"GARDA": 1,
"GARES": 1,
"GARIS": 1,
"GARMS": 1,
"GARNI": 1,
"GARRE": 1,
"GARTH": 1,
"GARUM": 1,
"GASES": 1,
"GASPS": 1,
"GASPY": 1,
"GASTS": 1,
"GATCH": 1,
"GATED": 1,
"GATER": 1,
"GATES": 1,
"GATHS": 1,
"GATOR": 1,
"GAUCH": 1,
"GAUCY": 1,
"GAUDS": 1,
"GAUJE": 1,
"GAULT": 1,
"GAUMS": 1,
"GAUMY": 1,
"GAUPS": 1,
"GAURS": 1,
"GAUSS": 1,
"GAUZY": 1,
"GAVOT": 1,
"GAWCY": 1,
"GAWDS": 1,
"GAWKS": 1,
"GAWPS": 1,
"GAWSY": 1,
"GAYAL": 1,
"GAZAL": 1,
"GAZAR": 1,
"GAZED": 1,
"GAZES": 1,
"GAZON": 1,
"GAZOO": 1,
"GEALS": 1,
"GEANS": 1,
"GEARE": 1,
"GEARS": 1,
"GEATS": 1,
"GEBUR": 1,
"GECKS": 1,
"GEEKS": 1,
"GEEPS": 1,
"GEEST": 1,
"GEIST": 1,
"GEITS": 1,
"GELDS": 1,
"GELEE": 1,
"GELID": 1,
"GELLY": 1,
"GELTS": 1,
"GEMEL": 1,
"GEMMA": 1,
"GEMMY": 1,
"GEMOT": 1,
"GENAL": 1,
"GENAS": 1,
"GENES": 1,
"GENET": 1,
"GENIC": 1,
"GENII": 1,
"GENIP": 1,
"GENNY": 1,
"GENOA": 1,
"GENOM": 1,
"GENRO": 1,
"GENTS": 1,
"GENTY": 1,
"GENUA": 1,
"GENUS": 1,
"GEODE": 1,
"GEOID": 1,
"GERAH": 1,
"GERBE": 1,
"GERES": 1,
"GERLE": 1,
"GERMS": 1,
"GERMY": 1,
"GERNE": 1,
"GESSE": 1,
"GESSO": 1,
"GESTE": 1,
"GESTS": 1,
"GETAS": 1,
"GETUP": 1,
"GEUMS": 1,
"GEYAN": 1,
"GEYER": 1,
"GHAST": 1,
"GHATS": 1,
"GHAUT": 1,
"GHAZI": 1,
"GHEES": 1,
"GHEST": 1,
"GHYLL": 1,
"GIBED": 1,
"GIBEL": 1,
"GIBER": 1,
"GIBES": 1,
"GIBLI": 1,
"GIBUS": 1,
"GIFTS": 1,
"GIGAS": 1,
"GIGHE": 1,
"GIGOT": 1,
"GIGUE": 1,
"GILAS": 1,
"GILDS": 1,
"GILET": 1,
"GILLS": 1,
"GILLY": 1,
"GILPY": 1,
"GILTS": 1,
"GIMEL": 1,
"GIMME": 1,
"GIMPS": 1,
"GIMPY": 1,
"GINCH": 1,
"GINGE": 1,
"GINGS": 1,
"GINKS": 1,
"GINNY": 1,
"GINZO": 1,
"GIPON": 1,
"GIPPO": 1,
"GIPPY": 1,
"GIRDS": 1,
"GIRLS": 1,
"GIRNS": 1,
"GIRON": 1,
"GIROS": 1,
"GIRRS": 1,
"GIRSH": 1,
"GIRTS": 1,
"GISMO": 1,
"GISMS": 1,
"GISTS": 1,
"GITCH": 1,
"GITES": 1,
"GIUST": 1,
"GIVED": 1,
"GIVES": 1,
"GIZMO": 1,
"GLACE": 1,
"GLADS": 1,
"GLADY": 1,
"GLAIK": 1,
"GLAIR": 1,
"GLAMS": 1,
"GLANS": 1,
"GLARY": 1,
"GLAUM": 1,
"GLAUR": 1,
"GLAZY": 1,
"GLEBA": 1,
"GLEBE": 1,
"GLEBY": 1,
"GLEDE": 1,
"GLEDS": 1,
"GLEED": 1,
"GLEEK": 1,
"GLEES": 1,
"GLEET": 1,
"GLEIS": 1,
"GLENS": 1,
"GLENT": 1,
"GLEYS": 1,
"GLIAL": 1,
"GLIAS": 1,
"GLIBS": 1,
"GLIFF": 1,
"GLIFT": 1,
"GLIKE": 1,
"GLIME": 1,
"GLIMS": 1,
"GLISK": 1,
"GLITS": 1,
"GLITZ": 1,
"GLOAM": 1,
"GLOBI": 1,
"GLOBS": 1,
"GLOBY": 1,
"GLODE": 1,
"GLOGG": 1,
"GLOMS": 1,
"GLOOP": 1,
"GLOPS": 1,
"GLOST": 1,
"GLOUT": 1,
"GLOWS": 1,
"GLOZE": 1,
"GLUED": 1,
"GLUER": 1,
"GLUES": 1,
"GLUEY": 1,
"GLUGS": 1,
"GLUME": 1,
"GLUMS": 1,
"GLUON": 1,
"GLUTE": 1,
"GLUTS": 1,
"GNARL": 1,
"GNARR": 1,
"GNARS": 1,
"GNATS": 1,
"GNAWN": 1,
"GNAWS": 1,
"GNOWS": 1,
"GOADS": 1,
"GOAFS": 1,
"GOALS": 1,
"GOARY": 1,
"GOATS": 1,
"GOATY": 1,
"GOBAN": 1,
"GOBAR": 1,
"GOBBI": 1,
"GOBBO": 1,
"GOBBY": 1,
"GOBIS": 1,
"GOBOS": 1,
"GODET": 1,
"GODSO": 1,
"GOELS": 1,
"GOERS": 1,
"GOEST": 1,
"GOETH": 1,
"GOETY": 1,
"GOFER": 1,
"GOFFS": 1,
"GOGGA": 1,
"GOGOS": 1,
"GOIER": 1,
"GOJIS": 1,
"GOLDS": 1,
"GOLDY": 1,
"GOLES": 1,
"GOLFS": 1,
"GOLPE": 1,
"GOLPS": 1,
"GOMBO": 1,
"GOMER": 1,
"GOMPA": 1,
"GONCH": 1,
"GONEF": 1,
"GONGS": 1,
"GONIA": 1,
"GONIF": 1,
"GONKS": 1,
"GONNA": 1,
"GONOF": 1,
"GONYS": 1,
"GONZO": 1,
"GOOBY": 1,
"GOODS": 1,
"GOOFS": 1,
"GOOGS": 1,
"GOOKS": 1,
"GOOKY": 1,
"GOOLD": 1,
"GOOLS": 1,
"GOOLY": 1,
"GOONS": 1,
"GOONY": 1,
"GOOPS": 1,
"GOOPY": 1,
"GOORS": 1,
"GOORY": 1,
"GOOSY": 1,
"GOPAK": 1,
"GOPIK": 1,
"GORAL": 1,
"GORAS": 1,
"GORED": 1,
"GORES": 1,
"GORIS": 1,
"GORMS": 1,
"GORMY": 1,
"GORPS": 1,
"GORSE": 1,
"GORSY": 1,
"GOSHT": 1,
"GOSSE": 1,
"GOTCH": 1,
"GOTHS": 1,
"GOTHY": 1,
"GOTTA": 1,
"GOUCH": 1,
"GOUKS": 1,
"GOURA": 1,
"GOUTS": 1,
"GOUTY": 1,
"GOWAN": 1,
"GOWDS": 1,
"GOWFS": 1,
"GOWKS": 1,
"GOWLS": 1,
"GOWNS": 1,
"GOXES": 1,
"GOYIM": 1,
"GOYLE": 1,
"GRAAL": 1,
"GRABS": 1,
"GRADS": 1,
"GRAFF": 1,
"GRAIP": 1,
"GRAMA": 1,
"GRAME": 1,
"GRAMP": 1,
"GRAMS": 1,
"GRANA": 1,
"GRANS": 1,
"GRAPY": 1,
"GRAVS": 1,
"GRAYS": 1,
"GREBE": 1,
"GREBO": 1,
"GRECE": 1,
"GREEK": 1,
"GREES": 1,
"GREGE": 1,
"GREGO": 1,
"GREIN": 1,
"GRENS": 1,
"GRESE": 1,
"GREVE": 1,
"GREWS": 1,
"GREYS": 1,
"GRICE": 1,
"GRIDE": 1,
"GRIDS": 1,
"GRIFF": 1,
"GRIFT": 1,
"GRIGS": 1,
"GRIKE": 1,
"GRINS": 1,
"GRIOT": 1,
"GRIPS": 1,
"GRIPT": 1,
"GRIPY": 1,
"GRISE": 1,
"GRIST": 1,
"GRISY": 1,
"GRITH": 1,
"GRITS": 1,
"GRIZE": 1,
"GROAT": 1,
"GRODY": 1,
"GROGS": 1,
"GROKS": 1,
"GROMA": 1,
"GRONE": 1,
"GROOF": 1,
"GROSZ": 1,
"GROTS": 1,
"GROUF": 1,
"GROVY": 1,
"GROWS": 1,
"GRRLS": 1,
"GRRRL": 1,
"GRUBS": 1,
"GRUED": 1,
"GRUES": 1,
"GRUFE": 1,
"GRUME": 1,
"GRUMP": 1,
"GRUND": 1,
"GRYCE": 1,
"GRYDE": 1,
"GRYKE": 1,
"GRYPE": 1,
"GRYPT": 1,
"GUACO": 1,
"GUANA": 1,
"GUANO": 1,
"GUANS": 1,
"GUARS": 1,
"GUCKS": 1,
"GUCKY": 1,
"GUDES": 1,
"GUFFS": 1,
"GUGAS": 1,
"GUIDS": 1,
"GUIMP": 1,
"GUIRO": 1,
"GULAG": 1,
"GULAR": 1,
"GULAS": 1,
"GULES": 1,
"GULET": 1,
"GULFS": 1,
"GULFY": 1,
"GULLS": 1,
"GULPH": 1,
"GULPS": 1,
"GULPY": 1,
"GUMMA": 1,
"GUMMI": 1,
"GUMPS": 1,
"GUNDY": 1,
"GUNGE": 1,
"GUNGY": 1,
"GUNKS": 1,
"GUNKY": 1,
"GUNNY": 1,
"GUQIN": 1,
"GURDY": 1,
"GURGE": 1,
"GURLS": 1,
"GURLY": 1,
"GURNS": 1,
"GURRY": 1,
"GURSH": 1,
"GURUS": 1,
"GUSHY": 1,
"GUSLA": 1,
"GUSLE": 1,
"GUSLI": 1,
"GUSSY": 1,
"GUSTS": 1,
"GUTSY": 1,
"GUTTA": 1,
"GUTTY": 1,
"GUYED": 1,
"GUYLE": 1,
"GUYOT": 1,
"GUYSE": 1,
"GWINE": 1,
"GYALS": 1,
"GYANS": 1,
"GYBED": 1,
"GYBES": 1,
"GYELD": 1,
"GYMPS": 1,
"GYNAE": 1,
"GYNIE": 1,
"GYNNY": 1,
"GYNOS": 1,
"GYOZA": 1,
"GYPOS": 1,
"GYPPO": 1,
"GYPPY": 1,
"GYRAL": 1,
"GYRED": 1,
"GYRES": 1,
"GYRON": 1,
"GYROS": 1,
"GYRUS": 1,
"GYTES": 1,
"GYVED": 1,
"GYVES": 1,
"HAAFS": 1,
"HAARS": 1,
"HABLE": 1,
"HABUS": 1,
"HACEK": 1,
"HACKS": 1,
"HADAL": 1,
"HADED": 1,
"HADES": 1,
"HADJI": 1,
"HADST": 1,
"HAEMS": 1,
"HAETS": 1,
"HAFFS": 1,
"HAFIZ": 1,
"HAFTS": 1,
"HAGGS": 1,
"HAHAS": 1,
"HAICK": 1,
"HAIKA": 1,
"HAIKS": 1,
"HAIKU": 1,
"HAILS": 1,
"HAILY": 1,
"HAINS": 1,
"HAINT": 1,
"HAIRS": 1,
"HAITH": 1,
"HAJES": 1,
"HAJIS": 1,
"HAJJI": 1,
"HAKAM": 1,
"HAKAS": 1,
"HAKEA": 1,
"HAKES": 1,
"HAKIM": 1,
"HAKUS": 1,
"HALAL": 1,
"HALED": 1,
"HALER": 1,
"HALES": 1,
"HALFA": 1,
"HALFS": 1,
"HALID": 1,
"HALLO": 1,
"HALLS": 1,
"HALMA": 1,
"HALMS": 1,
"HALON": 1,
"HALOS": 1,
"HALSE": 1,
"HALTS": 1,
"HALVA": 1,
"HALWA": 1,
"HAMAL": 1,
"HAMBA": 1,
"HAMED": 1,
"HAMES": 1,
"HAMMY": 1,
"HAMZA": 1,
"HANAP": 1,
"HANCE": 1,
"HANCH": 1,
"HANDS": 1,
"HANGI": 1,
"HANGS": 1,
"HANKS": 1,
"HANKY": 1,
"HANSA": 1,
"HANSE": 1,
"HANTS": 1,
"HAOLE": 1,
"HAOMA": 1,
"HAPAX": 1,
"HAPLY": 1,
"HAPPI": 1,
"HAPUS": 1,
"HARAM": 1,
"HARDS": 1,
"HARED": 1,
"HARES": 1,
"HARIM": 1,
"HARKS": 1,
"HARLS": 1,
"HARMS": 1,
"HARNS": 1,
"HAROS": 1,
"HARPS": 1,
"HARTS": 1,
"HASHY": 1,
"HASKS": 1,
"HASPS": 1,
"HASTA": 1,
"HATED": 1,
"HATES": 1,
"HATHA": 1,
"HAUDS": 1,
"HAUFS": 1,
"HAUGH": 1,
"HAULD": 1,
"HAULM": 1,
"HAULS": 1,
"HAULT": 1,
"HAUNS": 1,
"HAUSE": 1,
"HAVER": 1,
"HAVES": 1,
"HAWED": 1,
"HAWKS": 1,
"HAWMS": 1,
"HAWSE": 1,
"HAYED": 1,
"HAYER": 1,
"HAYEY": 1,
"HAYLE": 1,
"HAZAN": 1,
"HAZED": 1,
"HAZER": 1,
"HAZES": 1,
"HEADS": 1,
"HEALD": 1,
"HEALS": 1,
"HEAME": 1,
"HEAPS": 1,
"HEAPY": 1,
"HEARE": 1,
"HEARS": 1,
"HEAST": 1,
"HEATS": 1,
"HEBEN": 1,
"HEBES": 1,
"HECHT": 1,
"HECKS": 1,
"HEDER": 1,
"HEDGY": 1,
"HEEDS": 1,
"HEEDY": 1,
"HEELS": 1,
"HEEZE": 1,
"HEFTE": 1,
"HEFTS": 1,
"HEIDS": 1,
"HEIGH": 1,
"HEILS": 1,
"HEIRS": 1,
"HEJAB": 1,
"HEJRA": 1,
"HELED": 1,
"HELES": 1,
"HELIO": 1,
"HELLS": 1,
"HELMS": 1,
"HELOS": 1,
"HELOT": 1,
"HELPS": 1,
"HELVE": 1,
"HEMAL": 1,
"HEMES": 1,
"HEMIC": 1,
"HEMIN": 1,
"HEMPS": 1,
"HEMPY": 1,
"HENCH": 1,
"HENDS": 1,
"HENGE": 1,
"HENNA": 1,
"HENNY": 1,
"HENRY": 1,
"HENTS": 1,
"HEPAR": 1,
"HERBS": 1,
"HERBY": 1,
"HERDS": 1,
"HERES": 1,
"HERLS": 1,
"HERMA": 1,
"HERMS": 1,
"HERNS": 1,
"HEROS": 1,
"HERRY": 1,
"HERSE": 1,
"HERTZ": 1,
"HERYE": 1,
"HESPS": 1,
"HESTS": 1,
"HETES": 1,
"HETHS": 1,
"HEUCH": 1,
"HEUGH": 1,
"HEVEA": 1,
"HEWED": 1,
"HEWER": 1,
"HEWGH": 1,
"HEXAD": 1,
"HEXED": 1,
"HEXER": 1,
"HEXES": 1,
"HEXYL": 1,
"HEYED": 1,
"HIANT": 1,
"HICKS": 1,
"HIDED": 1,
"HIDER": 1,
"HIDES": 1,
"HIEMS": 1,
"HIGHS": 1,
"HIGHT": 1,
"HIJAB": 1,
"HIJRA": 1,
"HIKED": 1,
"HIKER": 1,
"HIKES": 1,
"HIKOI": 1,
"HILAR": 1,
"HILCH": 1,
"HILLO": 1,
"HILLS": 1,
"HILTS": 1,
"HILUM": 1,
"HILUS": 1,
"HIMBO": 1,
"HINAU": 1,
"HINDS": 1,
"HINGS": 1,
"HINKY": 1,
"HINNY": 1,
"HINTS": 1,
"HIOIS": 1,
"HIPLY": 1,
"HIRED": 1,
"HIREE": 1,
"HIRER": 1,
"HIRES": 1,
"HISSY": 1,
"HISTS": 1,
"HITHE": 1,
"HIVED": 1,
"HIVER": 1,
"HIVES": 1,
"HIZEN": 1,
"HOAED": 1,
"HOAGY": 1,
"HOARS": 1,
"HOARY": 1,
"HOAST": 1,
"HOBOS": 1,
"HOCKS": 1,
"HOCUS": 1,
"HODAD": 1,
"HODJA": 1,
"HOERS": 1,
"HOGAN": 1,
"HOGEN": 1,
"HOGGS": 1,
"HOGHS": 1,
"HOHED": 1,
"HOICK": 1,
"HOIED": 1,
"HOIKS": 1,
"HOING": 1,
"HOISE": 1,
"HOKAS": 1,
"HOKED": 1,
"HOKES": 1,
"HOKEY": 1,
"HOKIS": 1,
"HOKKU": 1,
"HOKUM": 1,
"HOLDS": 1,
"HOLED": 1,
"HOLES": 1,
"HOLEY": 1,
"HOLKS": 1,
"HOLLA": 1,
"HOLLO": 1,
"HOLME": 1,
"HOLMS": 1,
"HOLON": 1,
"HOLOS": 1,
"HOLTS": 1,
"HOMAS": 1,
"HOMED": 1,
"HOMES": 1,
"HOMEY": 1,
"HOMIE": 1,
"HOMME": 1,
"HOMOS": 1,
"HONAN": 1,
"HONDA": 1,
"HONDS": 1,
"HONED": 1,
"HONER": 1,
"HONES": 1,
"HONGI": 1,
"HONGS": 1,
"HONKS": 1,
"HONKY": 1,
"HOOCH": 1,
"HOODS": 1,
"HOODY": 1,
"HOOEY": 1,
"HOOFS": 1,
"HOOKA": 1,
"HOOKS": 1,
"HOOKY": 1,
"HOOLY": 1,
"HOONS": 1,
"HOOPS": 1,
"HOORD": 1,
"HOORS": 1,
"HOOSH": 1,
"HOOTS": 1,
"HOOTY": 1,
"HOOVE": 1,
"HOPAK": 1,
"HOPED": 1,
"HOPER": 1,
"HOPES": 1,
"HOPPY": 1,
"HORAH": 1,
"HORAL": 1,
"HORAS": 1,
"HORIS": 1,
"HORKS": 1,
"HORME": 1,
"HORNS": 1,
"HORST": 1,
"HORSY": 1,
"HOSED": 1,
"HOSEL": 1,
"HOSEN": 1,
"HOSER": 1,
"HOSES": 1,
"HOSEY": 1,
"HOSTA": 1,
"HOSTS": 1,
"HOTCH": 1,
"HOTEN": 1,
"HOTTY": 1,
"HOUFF": 1,
"HOUFS": 1,
"HOUGH": 1,
"HOURI": 1,
"HOURS": 1,
"HOUTS": 1,
"HOVEA": 1,
"HOVED": 1,
"HOVEN": 1,
"HOVES": 1,
"HOWBE": 1,
"HOWES": 1,
"HOWFF": 1,
"HOWFS": 1,
"HOWKS": 1,
"HOWLS": 1,
"HOWRE": 1,
"HOWSO": 1,
"HOXED": 1,
"HOXES": 1,
"HOYAS": 1,
"HOYED": 1,
"HOYLE": 1,
"HUBBY": 1,
"HUCKS": 1,
"HUDNA": 1,
"HUDUD": 1,
"HUERS": 1,
"HUFFS": 1,
"HUFFY": 1,
"HUGER": 1,
"HUGGY": 1,
"HUHUS": 1,
"HUIAS": 1,
"HULAS": 1,
"HULES": 1,
"HULKS": 1,
"HULKY": 1,
"HULLO": 1,
"HULLS": 1,
"HULLY": 1,
"HUMAS": 1,
"HUMFS": 1,
"HUMIC": 1,
"HUMPS": 1,
"HUMPY": 1,
"HUNKS": 1,
"HUNTS": 1,
"HURDS": 1,
"HURLS": 1,
"HURLY": 1,
"HURRA": 1,
"HURST": 1,
"HURTS": 1,
"HUSHY": 1,
"HUSKS": 1,
"HUSOS": 1,
"HUTIA": 1,
"HUZZA": 1,
"HUZZY": 1,
"HWYLS": 1,
"HYDRA": 1,
"HYENS": 1,
"HYGGE": 1,
"HYING": 1,
"HYKES": 1,
"HYLAS": 1,
"HYLEG": 1,
"HYLES": 1,
"HYLIC": 1,
"HYMNS": 1,
"HYNDE": 1,
"HYOID": 1,
"HYPED": 1,
"HYPES": 1,
"HYPHA": 1,
"HYPHY": 1,
"HYPOS": 1,
"HYRAX": 1,
"HYSON": 1,
"HYTHE": 1,
"IAMBI": 1,
"IAMBS": 1,
"IBRIK": 1,
"ICERS": 1,
"ICHED": 1,
"ICHES": 1,
"ICHOR": 1,
"ICIER": 1,
"ICKER": 1,
"ICKLE": 1,
"ICONS": 1,
"ICTAL": 1,
"ICTIC": 1,
"ICTUS": 1,
"IDANT": 1,
"IDEAS": 1,
"IDEES": 1,
"IDENT": 1,
"IDLED": 1,
"IDLES": 1,
"IDOLA": 1,
"IDOLS": 1,
"IDYLS": 1,
"IFTAR": 1,
"IGAPO": 1,
"IGGED": 1,
"IGLUS": 1,
"IHRAM": 1,
"IKANS": 1,
"IKATS": 1,
"IKONS": 1,
"ILEAC": 1,
"ILEAL": 1,
"ILEUM": 1,
"ILEUS": 1,
"ILIAD": 1,
"ILIAL": 1,
"ILIUM": 1,
"ILLER": 1,
"ILLTH": 1,
"IMAGO": 1,
"IMAMS": 1,
"IMARI": 1,
"IMAUM": 1,
"IMBAR": 1,
"IMBED": 1,
"IMIDE": 1,
"IMIDO": 1,
"IMIDS": 1,
"IMINE": 1,
"IMINO": 1,
"IMMEW": 1,
"IMMIT": 1,
"IMMIX": 1,
"IMPED": 1,
"IMPIS": 1,
"IMPOT": 1,
"IMPRO": 1,
"IMSHI": 1,
"IMSHY": 1,
"INAPT": 1,
"INARM": 1,
"INBYE": 1,
"INCEL": 1,
"INCLE": 1,
"INCOG": 1,
"INCUS": 1,
"INCUT": 1,
"INDEW": 1,
"INDIA": 1,
"INDIE": 1,
"INDOL": 1,
"INDOW": 1,
"INDRI": 1,
"INDUE": 1,
"INERM": 1,
"INFIX": 1,
"INFOS": 1,
"INFRA": 1,
"INGAN": 1,
"INGLE": 1,
"INION": 1,
"INKED": 1,
"INKER": 1,
"INKLE": 1,
"INNED": 1,
"INNIT": 1,
"INORB": 1,
"INRUN": 1,
"INSET": 1,
"INSPO": 1,
"INTEL": 1,
"INTIL": 1,
"INTIS": 1,
"INTRA": 1,
"INULA": 1,
"INURE": 1,
"INURN": 1,
"INUST": 1,
"INVAR": 1,
"INWIT": 1,
"IODIC": 1,
"IODID": 1,
"IODIN": 1,
"IOTAS": 1,
"IPPON": 1,
"IRADE": 1,
"IRIDS": 1,
"IRING": 1,
"IRKED": 1,
"IROKO": 1,
"IRONE": 1,
"IRONS": 1,
"ISBAS": 1,
"ISHES": 1,
"ISLED": 1,
"ISLES": 1,
"ISNAE": 1,
"ISSEI": 1,
"ISTLE": 1,
"ITEMS": 1,
"ITHER": 1,
"IVIED": 1,
"IVIES": 1,
"IXIAS": 1,
"IXNAY": 1,
"IXORA": 1,
"IXTLE": 1,
"IZARD": 1,
"IZARS": 1,
"IZZAT": 1,
"JAAPS": 1,
"JABOT": 1,
"JACAL": 1,
"JACKS": 1,
"JACKY": 1,
"JADED": 1,
"JADES": 1,
"JAFAS": 1,
"JAFFA": 1,
"JAGAS": 1,
"JAGER": 1,
"JAGGS": 1,
"JAGGY": 1,
"JAGIR": 1,
"JAGRA": 1,
"JAILS": 1,
"JAKER": 1,
"JAKES": 1,
"JAKEY": 1,
"JALAP": 1,
"JALOP": 1,
"JAMBE": 1,
"JAMBO": 1,
"JAMBS": 1,
"JAMBU": 1,
"JAMES": 1,
"JAMMY": 1,
"JAMON": 1,
"JANES": 1,
"JANNS": 1,
"JANNY": 1,
"JANTY": 1,
"JAPAN": 1,
"JAPED": 1,
"JAPER": 1,
"JAPES": 1,
"JARKS": 1,
"JARLS": 1,
"JARPS": 1,
"JARTA": 1,
"JARUL": 1,
"JASEY": 1,
"JASPE": 1,
"JASPS": 1,
"JATOS": 1,
"JAUKS": 1,
"JAUPS": 1,
"JAVAS": 1,
"JAVEL": 1,
"JAWAN": 1,
"JAWED": 1,
"JAXIE": 1,
"JEANS": 1,
"JEATS": 1,
"JEBEL": 1,
"JEDIS": 1,
"JEELS": 1,
"JEELY": 1,
"JEEPS": 1,
"JEERS": 1,
"JEEZE": 1,
"JEFES": 1,
"JEFFS": 1,
"JEHAD": 1,
"JEHUS": 1,
"JELAB": 1,
"JELLO": 1,
"JELLS": 1,
"JEMBE": 1,
"JEMMY": 1,
"JENNY": 1,
"JEONS": 1,
"JERID": 1,
"JERKS": 1,
"JERRY": 1,
"JESSE": 1,
"JESTS": 1,
"JESUS": 1,
"JETES": 1,
"JETON": 1,
"JEUNE": 1,
"JEWED": 1,
"JEWIE": 1,
"JHALA": 1,
"JIAOS": 1,
"JIBBA": 1,
"JIBBS": 1,
"JIBED": 1,
"JIBER": 1,
"JIBES": 1,
"JIFFS": 1,
"JIGGY": 1,
"JIGOT": 1,
"JIHAD": 1,
"JILLS": 1,
"JILTS": 1,
"JIMMY": 1,
"JIMPY": 1,
"JINGO": 1,
"JINKS": 1,
"JINNE": 1,
"JINNI": 1,
"JINNS": 1,
"JIRDS": 1,
"JIRGA": 1,
"JIRRE": 1,
"JISMS": 1,
"JIVED": 1,
"JIVER": 1,
"JIVES": 1,
"JIVEY": 1,
"JNANA": 1,
"JOBED": 1,
"JOBES": 1,
"JOCKO": 1,
"JOCKS": 1,
"JOCKY": 1,
"JOCOS": 1,
"JODEL": 1,
"JOEYS": 1,
"JOHNS": 1,
"JOINS": 1,
"JOKED": 1,
"JOKES": 1,
"JOKEY": 1,
"JOKOL": 1,
"JOLED": 1,
"JOLES": 1,
"JOLLS": 1,
"JOLTS": 1,
"JOLTY": 1,
"JOMON": 1,
"JOMOS": 1,
"JONES": 1,
"JONGS": 1,
"JONTY": 1,
"JOOKS": 1,
"JORAM": 1,
"JORUM": 1,
"JOTAS": 1,
"JOTTY": 1,
"JOTUN": 1,
"JOUAL": 1,
"JOUGS": 1,
"JOUKS": 1,
"JOULE": 1,
"JOURS": 1,
"JOWAR": 1,
"JOWED": 1,
"JOWLS": 1,
"JOWLY": 1,
"JOYED": 1,
"JUBAS": 1,
"JUBES": 1,
"JUCOS": 1,
"JUDAS": 1,
"JUDGY": 1,
"JUDOS": 1,
"JUGAL": 1,
"JUGUM": 1,
"JUJUS": 1,
"JUKED": 1,
"JUKES": 1,
"JUKUS": 1,
"JULEP": 1,
"JUMAR": 1,
"JUMBY": 1,
"JUMPS": 1,
"JUNCO": 1,
"JUNKS": 1,
"JUNKY": 1,
"JUPES": 1,
"JUPON": 1,
"JURAL": 1,
"JURAT": 1,
"JUREL": 1,
"JURES": 1,
"JUSTS": 1,
"JUTES": 1,
"JUTTY": 1,
"JUVES": 1,
"JUVIE": 1,
"KAAMA": 1,
"KABAB": 1,
"KABAR": 1,
"KABOB": 1,
"KACHA": 1,
"KACKS": 1,
"KADAI": 1,
"KADES": 1,
"KADIS": 1,
"KAFIR": 1,
"KAGOS": 1,
"KAGUS": 1,
"KAHAL": 1,
"KAIAK": 1,
"KAIDS": 1,
"KAIES": 1,
"KAIFS": 1,
"KAIKA": 1,
"KAIKS": 1,
"KAILS": 1,
"KAIMS": 1,
"KAING": 1,
"KAINS": 1,
"KAKAS": 1,
"KAKIS": 1,
"KALAM": 1,
"KALES": 1,
"KALIF": 1,
"KALIS": 1,
"KALPA": 1,
"KAMAS": 1,
"KAMES": 1,
"KAMIK": 1,
"KAMIS": 1,
"KAMME": 1,
"KANAE": 1,
"KANAS": 1,
"KANDY": 1,
"KANEH": 1,
"KANES": 1,
"KANGA": 1,
"KANGS": 1,
"KANJI": 1,
"KANTS": 1,
"KANZU": 1,
"KAONS": 1,
"KAPAS": 1,
"KAPHS": 1,
"KAPOK": 1,
"KAPOW": 1,
"KAPUS": 1,
"KAPUT": 1,
"KARAS": 1,
"KARAT": 1,
"KARKS": 1,
"KARNS": 1,
"KAROO": 1,
"KAROS": 1,
"KARRI": 1,
"KARST": 1,
"KARSY": 1,
"KARTS": 1,
"KARZY": 1,
"KASHA": 1,
"KASME": 1,
"KATAL": 1,
"KATAS": 1,
"KATIS": 1,
"KATTI": 1,
"KAUGH": 1,
"KAURI": 1,
"KAURU": 1,
"KAURY": 1,
"KAVAL": 1,
"KAVAS": 1,
"KAWAS": 1,
"KAWAU": 1,
"KAWED": 1,
"KAYLE": 1,
"KAYOS": 1,
"KAZIS": 1,
"KAZOO": 1,
"KBARS": 1,
"KEBAR": 1,
"KEBOB": 1,
"KECKS": 1,
"KEDGE": 1,
"KEDGY": 1,
"KEECH": 1,
"KEEFS": 1,
"KEEKS": 1,
"KEELS": 1,
"KEEMA": 1,
"KEENO": 1,
"KEENS": 1,
"KEEPS": 1,
"KEETS": 1,
"KEEVE": 1,
"KEFIR": 1,
"KEHUA": 1,
"KEIRS": 1,
"KELEP": 1,
"KELIM": 1,
"KELLS": 1,
"KELLY": 1,
"KELPS": 1,
"KELPY": 1,
"KELTS": 1,
"KELTY": 1,
"KEMBO": 1,
"KEMBS": 1,
"KEMPS": 1,
"KEMPT": 1,
"KEMPY": 1,
"KENAF": 1,
"KENCH": 1,
"KENDO": 1,
"KENOS": 1,
"KENTE": 1,
"KENTS": 1,
"KEPIS": 1,
"KERBS": 1,
"KEREL": 1,
"KERFS": 1,
"KERKY": 1,
"KERMA": 1,
"KERNE": 1,
"KERNS": 1,
"KEROS": 1,
"KERRY": 1,
"KERVE": 1,
"KESAR": 1,
"KESTS": 1,
"KETAS": 1,
"KETCH": 1,
"KETES": 1,
"KETOL": 1,
"KEVEL": 1,
"KEVIL": 1,
"KEXES": 1,
"KEYED": 1,
"KEYER": 1,
"KHADI": 1,
"KHAFS": 1,
"KHANS": 1,
"KHAPH": 1,
"KHATS": 1,
"KHAYA": 1,
"KHAZI": 1,
"KHEDA": 1,
"KHETH": 1,
"KHETS": 1,
"KHOJA": 1,
"KHORS": 1,
"KHOUM": 1,
"KHUDS": 1,
"KIAAT": 1,
"KIACK": 1,
"KIANG": 1,
"KIBBE": 1,
"KIBBI": 1,
"KIBEI": 1,
"KIBES": 1,
"KIBLA": 1,
"KICKS": 1,
"KICKY": 1,
"KIDDO": 1,
"KIDDY": 1,
"KIDEL": 1,
"KIDGE": 1,
"KIEFS": 1,
"KIERS": 1,
"KIEVE": 1,
"KIEVS": 1,
"KIGHT": 1,
"KIKES": 1,
"KIKOI": 1,
"KILEY": 1,
"KILIM": 1,
"KILLS": 1,
"KILNS": 1,
"KILOS": 1,
"KILPS": 1,
"KILTS": 1,
"KILTY": 1,
"KIMBO": 1,
"KINAS": 1,
"KINDA": 1,
"KINDS": 1,
"KINDY": 1,
"KINES": 1,
"KINGS": 1,
"KININ": 1,
"KINKS": 1,
"KINOS": 1,
"KIORE": 1,
"KIPES": 1,
"KIPPA": 1,
"KIPPS": 1,
"KIRBY": 1,
"KIRKS": 1,
"KIRNS": 1,
"KIRRI": 1,
"KISAN": 1,
"KISSY": 1,
"KISTS": 1,
"KITED": 1,
"KITER": 1,
"KITES": 1,
"KITHE": 1,
"KITHS": 1,
"KITUL": 1,
"KIVAS": 1,
"KIWIS": 1,
"KLANG": 1,
"KLAPS": 1,
"KLETT": 1,
"KLICK": 1,
"KLIEG": 1,
"KLIKS": 1,
"KLONG": 1,
"KLOOF": 1,
"KLUGE": 1,
"KLUTZ": 1,
"KNAGS": 1,
"KNAPS": 1,
"KNARL": 1,
"KNARS": 1,
"KNAUR": 1,
"KNAWE": 1,
"KNEES": 1,
"KNELL": 1,
"KNISH": 1,
"KNITS": 1,
"KNIVE": 1,
"KNOBS": 1,
"KNOPS": 1,
"KNOSP": 1,
"KNOTS": 1,
"KNOUT": 1,
"KNOWE": 1,
"KNOWS": 1,
"KNUBS": 1,
"KNURL": 1,
"KNURR": 1,
"KNURS": 1,
"KNUTS": 1,
"KOANS": 1,
"KOAPS": 1,
"KOBAN": 1,
"KOBOS": 1,
"KOELS": 1,
"KOFFS": 1,
"KOFTA": 1,
"KOGAL": 1,
"KOHAS": 1,
"KOHEN": 1,
"KOHLS": 1,
"KOINE": 1,
"KOJIS": 1,
"KOKAM": 1,
"KOKAS": 1,
"KOKER": 1,
"KOKRA": 1,
"KOKUM": 1,
"KOLAS": 1,
"KOLOS": 1,
"KOMBU": 1,
"KONBU": 1,
"KONDO": 1,
"KONKS": 1,
"KOOKS": 1,
"KOOKY": 1,
"KOORI": 1,
"KOPEK": 1,
"KOPHS": 1,
"KOPJE": 1,
"KOPPA": 1,
"KORAI": 1,
"KORAS": 1,
"KORAT": 1,
"KORES": 1,
"KORMA": 1,
"KOROS": 1,
"KORUN": 1,
"KORUS": 1,
"KOSES": 1,
"KOTCH": 1,
"KOTOS": 1,
"KOTOW": 1,
"KOURA": 1,
"KRAAL": 1,
"KRABS": 1,
"KRAFT": 1,
"KRAIS": 1,
"KRAIT": 1,
"KRANG": 1,
"KRANS": 1,
"KRANZ": 1,
"KRAUT": 1,
"KRAYS": 1,
"KREEP": 1,
"KRENG": 1,
"KREWE": 1,
"KRONA": 1,
"KRONE": 1,
"KROON": 1,
"KRUBI": 1,
"KRUNK": 1,
"KSARS": 1,
"KUBIE": 1,
"KUDOS": 1,
"KUDUS": 1,
"KUDZU": 1,
"KUFIS": 1,
"KUGEL": 1,
"KUIAS": 1,
"KUKRI": 1,
"KUKUS": 1,
"KULAK": 1,
"KULAN": 1,
"KULAS": 1,
"KULFI": 1,
"KUMIS": 1,
"KUMYS": 1,
"KURIS": 1,
"KURRE": 1,
"KURTA": 1,
"KURUS": 1,
"KUSSO": 1,
"KUTAS": 1,
"KUTCH": 1,
"KUTIS": 1,
"KUTUS": 1,
"KUZUS": 1,
"KVASS": 1,
"KVELL": 1,
"KWELA": 1,
"KYACK": 1,
"KYAKS": 1,
"KYANG": 1,
"KYARS": 1,
"KYATS": 1,
"KYBOS": 1,
"KYDST": 1,
"KYLES": 1,
"KYLIE": 1,
"KYLIN": 1,
"KYLIX": 1,
"KYLOE": 1,
"KYNDE": 1,
"KYNDS": 1,
"KYPES": 1,
"KYRIE": 1,
"KYTES": 1,
"KYTHE": 1,
"LAARI": 1,
"LABDA": 1,
"LABIA": 1,
"LABIS": 1,
"LABRA": 1,
"LACED": 1,
"LACER": 1,
"LACES": 1,
"LACET": 1,
"LACEY": 1,
"LACKS": 1,
"LADDY": 1,
"LADED": 1,
"LADER": 1,
"LADES": 1,
"LAERS": 1,
"LAEVO": 1,
"LAGAN": 1,
"LAHAL": 1,
"LAHAR": 1,
"LAICH": 1,
"LAICS": 1,
"LAIDS": 1,
"LAIGH": 1,
"LAIKA": 1,
"LAIKS": 1,
"LAIRD": 1,
"LAIRS": 1,
"LAIRY": 1,
"LAITH": 1,
"LAITY": 1,
"LAKED": 1,
"LAKER": 1,
"LAKES": 1,
"LAKHS": 1,
"LAKIN": 1,
"LAKSA": 1,
"LALDY": 1,
"LALLS": 1,
"LAMAS": 1,
"LAMBS": 1,
"LAMBY": 1,
"LAMED": 1,
"LAMER": 1,
"LAMES": 1,
"LAMIA": 1,
"LAMMY": 1,
"LAMPS": 1,
"LANAI": 1,
"LANAS": 1,
"LANCH": 1,
"LANDE": 1,
"LANDS": 1,
"LANES": 1,
"LANKS": 1,
"LANTS": 1,
"LAPIN": 1,
"LAPIS": 1,
"LAPJE": 1,
"LARCH": 1,
"LARDS": 1,
"LARDY": 1,
"LAREE": 1,
"LARES": 1,
"LARGO": 1,
"LARIS": 1,
"LARKS": 1,
"LARKY": 1,
"LARNS": 1,
"LARNT": 1,
"LARUM": 1,
"LASED": 1,
"LASER": 1,
"LASES": 1,
"LASSI": 1,
"LASSU": 1,
"LASSY": 1,
"LASTS": 1,
"LATAH": 1,
"LATED": 1,
"LATEN": 1,
"LATEX": 1,
"LATHI": 1,
"LATHS": 1,
"LATHY": 1,
"LATKE": 1,
"LATUS": 1,
"LAUAN": 1,
"LAUCH": 1,
"LAUDS": 1,
"LAUFS": 1,
"LAUND": 1,
"LAURA": 1,
"LAVAL": 1,
"LAVAS": 1,
"LAVED": 1,
"LAVER": 1,
"LAVES": 1,
"LAVRA": 1,
"LAVVY": 1,
"LAWED": 1,
"LAWER": 1,
"LAWIN": 1,
"LAWKS": 1,
"LAWNS": 1,
"LAWNY": 1,
"LAXED": 1,
"LAXER": 1,
"LAXES": 1,
"LAXLY": 1,
"LAYED": 1,
"LAYIN": 1,
"LAYUP": 1,
"LAZAR": 1,
"LAZED": 1,
"LAZES": 1,
"LAZOS": 1,
"LAZZI": 1,
"LAZZO": 1,
"LEADS": 1,
"LEADY": 1,
"LEAFS": 1,
"LEAKS": 1,
"LEAMS": 1,
"LEANS": 1,
"LEANY": 1,
"LEAPS": 1,
"LEARE": 1,
"LEARS": 1,
"LEARY": 1,
"LEATS": 1,
"LEAVY": 1,
"LEAZE": 1,
"LEBEN": 1,
"LECCY": 1,
"LEDES": 1,
"LEDGY": 1,
"LEDUM": 1,
"LEEAR": 1,
"LEEKS": 1,
"LEEPS": 1,
"LEERS": 1,
"LEESE": 1,
"LEETS": 1,
"LEEZE": 1,
"LEFTE": 1,
"LEFTS": 1,
"LEGER": 1,
"LEGES": 1,
"LEGGE": 1,
"LEGGO": 1,
"LEGIT": 1,
"LEHRS": 1,
"LEHUA": 1,
"LEIRS": 1,
"LEISH": 1,
"LEMAN": 1,
"LEMED": 1,
"LEMEL": 1,
"LEMES": 1,
"LEMMA": 1,
"LEMME": 1,
"LENDS": 1,
"LENES": 1,
"LENGS": 1,
"LENIS": 1,
"LENOS": 1,
"LENSE": 1,
"LENTI": 1,
"LENTO": 1,
"LEONE": 1,
"LEPID": 1,
"LEPRA": 1,
"LEPTA": 1,
"LERED": 1,
"LERES": 1,
"LERPS": 1,
"LESBO": 1,
"LESES": 1,
"LESTS": 1,
"LETCH": 1,
"LETHE": 1,
"LETUP": 1,
"LEUCH": 1,
"LEUCO": 1,
"LEUDS": 1,
"LEUGH": 1,
"LEVAS": 1,
"LEVEE": 1,
"LEVES": 1,
"LEVIN": 1,
"LEVIS": 1,
"LEWIS": 1,
"LEXES": 1,
"LEXIS": 1,
"LEZES": 1,
"LEZZA": 1,
"LEZZY": 1,
"LIANA": 1,
"LIANE": 1,
"LIANG": 1,
"LIARD": 1,
"LIARS": 1,
"LIART": 1,
"LIBER": 1,
"LIBRA": 1,
"LIBRI": 1,
"LICHI": 1,
"LICHT": 1,
"LICIT": 1,
"LICKS": 1,
"LIDAR": 1,
"LIDOS": 1,
"LIEFS": 1,
"LIENS": 1,
"LIERS": 1,
"LIEUS": 1,
"LIEVE": 1,
"LIFER": 1,
"LIFES": 1,
"LIFTS": 1,
"LIGAN": 1,
"LIGER": 1,
"LIGGE": 1,
"LIGNE": 1,
"LIKED": 1,
"LIKER": 1,
"LIKES": 1,
"LIKIN": 1,
"LILLS": 1,
"LILOS": 1,
"LILTS": 1,
"LIMAN": 1,
"LIMAS": 1,
"LIMAX": 1,
"LIMBA": 1,
"LIMBI": 1,
"LIMBS": 1,
"LIMBY": 1,
"LIMED": 1,
"LIMEN": 1,
"LIMES": 1,
"LIMEY": 1,
"LIMMA": 1,
"LIMNS": 1,
"LIMOS": 1,
"LIMPA": 1,
"LIMPS": 1,
"LINAC": 1,
"LINCH": 1,
"LINDS": 1,
"LINDY": 1,
"LINED": 1,
"LINES": 1,
"LINEY": 1,
"LINGA": 1,
"LINGS": 1,
"LINGY": 1,
"LININ": 1,
"LINKS": 1,
"LINKY": 1,
"LINNS": 1,
"LINNY": 1,
"LINOS": 1,
"LINTS": 1,
"LINTY": 1,
"LINUM": 1,
"LINUX": 1,
"LIONS": 1,
"LIPAS": 1,
"LIPES": 1,
"LIPIN": 1,
"LIPOS": 1,
"LIPPY": 1,
"LIRAS": 1,
"LIRKS": 1,
"LIROT": 1,
"LISKS": 1,
"LISLE": 1,
"LISPS": 1,
"LISTS": 1,
"LITAI": 1,
"LITAS": 1,
"LITED": 1,
"LITER": 1,
"LITES": 1,
"LITHO": 1,
"LITHS": 1,
"LITRE": 1,
"LIVED": 1,
"LIVEN": 1,
"LIVES": 1,
"LIVOR": 1,
"LIVRE": 1,
"LLANO": 1,
"LOACH": 1,
"LOADS": 1,
"LOAFS": 1,
"LOAMS": 1,
"LOANS": 1,
"LOAST": 1,
"LOAVE": 1,
"LOBAR": 1,
"LOBED": 1,
"LOBES": 1,
"LOBOS": 1,
"LOBUS": 1,
"LOCHE": 1,
"LOCHS": 1,
"LOCIE": 1,
"LOCIS": 1,
"LOCKS": 1,
"LOCOS": 1,
"LOCUM": 1,
"LODEN": 1,
"LODES": 1,
"LOESS": 1,
"LOFTS": 1,
"LOGAN": 1,
"LOGES": 1,
"LOGGY": 1,
"LOGIA": 1,
"LOGIE": 1,
"LOGOI": 1,
"LOGON": 1,
"LOGOS": 1,
"LOHAN": 1,
"LOIDS": 1,
"LOINS": 1,
"LOIPE": 1,
"LOIRS": 1,
"LOKES": 1,
"LOLLS": 1,
"LOLLY": 1,
"LOLOG": 1,
"LOMAS": 1,
"LOMED": 1,
"LOMES": 1,
"LONER": 1,
"LONGA": 1,
"LONGE": 1,
"LONGS": 1,
"LOOBY": 1,
"LOOED": 1,
"LOOEY": 1,
"LOOFA": 1,
"LOOFS": 1,
"LOOIE": 1,
"LOOKS": 1,
"LOOKY": 1,
"LOOMS": 1,
"LOONS": 1,
"LOONY": 1,
"LOOPS": 1,
"LOORD": 1,
"LOOTS": 1,
"LOPED": 1,
"LOPER": 1,
"LOPES": 1,
"LOPPY": 1,
"LORAL": 1,
"LORAN": 1,
"LORDS": 1,
"LORDY": 1,
"LOREL": 1,
"LORES": 1,
"LORIC": 1,
"LORIS": 1,
"LOSED": 1,
"LOSEL": 1,
"LOSEN": 1,
"LOSES": 1,
"LOSSY": 1,
"LOTAH": 1,
"LOTAS": 1,
"LOTES": 1,
"LOTIC": 1,
"LOTOS": 1,
"LOTSA": 1,
"LOTTA": 1,
"LOTTE": 1,
"LOTTO": 1,
"LOTUS": 1,
"LOUED": 1,
"LOUGH": 1,
"LOUIE": 1,
"LOUIS": 1,
"LOUMA": 1,
"LOUND": 1,
"LOUNS": 1,
"LOUPE": 1,
"LOUPS": 1,
"LOURE": 1,
"LOURS": 1,
"LOURY": 1,
"LOUTS": 1,
"LOVAT": 1,
"LOVED": 1,
"LOVES": 1,
"LOVEY": 1,
"LOVIE": 1,
"LOWAN": 1,
"LOWED": 1,
"LOWES": 1,
"LOWND": 1,
"LOWNE": 1,
"LOWNS": 1,
"LOWPS": 1,
"LOWRY": 1,
"LOWSE": 1,
"LOWTS": 1,
"LOXED": 1,
"LOXES": 1,
"LOZEN": 1,
"LUACH": 1,
"LUAUS": 1,
"LUBED": 1,
"LUBES": 1,
"LUBRA": 1,
"LUCES": 1,
"LUCKS": 1,
"LUCRE": 1,
"LUDES": 1,
"LUDIC": 1,
"LUDOS": 1,
"LUFFA": 1,
"LUFFS": 1,
"LUGED": 1,
"LUGER": 1,
"LUGES": 1,
"LULLS": 1,
"LULUS": 1,
"LUMAS": 1,
"LUMBI": 1,
"LUMME": 1,
"LUMMY": 1,
"LUMPS": 1,
"LUNAS": 1,
"LUNES": 1,
"LUNET": 1,
"LUNGI": 1,
"LUNGS": 1,
"LUNKS": 1,
"LUNTS": 1,
"LUPIN": 1,
"LURED": 1,
"LURER": 1,
"LURES": 1,
"LUREX": 1,
"LURGI": 1,
"LURGY": 1,
"LURKS": 1,
"LURRY": 1,
"LURVE": 1,
"LUSER": 1,
"LUSHY": 1,
"LUSKS": 1,
"LUSTS": 1,
"LUSUS": 1,
"LUTEA": 1,
"LUTED": 1,
"LUTER": 1,
"LUTES": 1,
"LUVVY": 1,
"LUXED": 1,
"LUXER": 1,
"LUXES": 1,
"LWEIS": 1,
"LYAMS": 1,
"LYARD": 1,
"LYART": 1,
"LYASE": 1,
"LYCEA": 1,
"LYCEE": 1,
"LYCRA": 1,
"LYMES": 1,
"LYNES": 1,
"LYRES": 1,
"LYSED": 1,
"LYSES": 1,
"LYSIN": 1,
"LYSIS": 1,
"LYSOL": 1,
"LYSSA": 1,
"LYTED": 1,
"LYTES": 1,
"LYTHE": 1,
"LYTIC": 1,
"LYTTA": 1,
"MAAED": 1,
"MAARE": 1,
"MAARS": 1,
"MABES": 1,
"MACAS": 1,
"MACED": 1,
"MACER": 1,
"MACES": 1,
"MACHE": 1,
"MACHI": 1,
"MACHS": 1,
"MACKS": 1,
"MACLE": 1,
"MACON": 1,
"MADGE": 1,
"MADID": 1,
"MADRE": 1,
"MAERL": 1,
"MAFIC": 1,
"MAGES": 1,
"MAGGS": 1,
"MAGOT": 1,
"MAGUS": 1,
"MAHOE": 1,
"MAHUA": 1,
"MAHWA": 1,
"MAIDS": 1,
"MAIKO": 1,
"MAIKS": 1,
"MAILE": 1,
"MAILL": 1,
"MAILS": 1,
"MAIMS": 1,
"MAINS": 1,
"MAIRE": 1,
"MAIRS": 1,
"MAISE": 1,
"MAIST": 1,
"MAKAR": 1,
"MAKES": 1,
"MAKIS": 1,
"MAKOS": 1,
"MALAM": 1,
"MALAR": 1,
"MALAS": 1,
"MALAX": 1,
"MALES": 1,
"MALIC": 1,
"MALIK": 1,
"MALIS": 1,
"MALLS": 1,
"MALMS": 1,
"MALMY": 1,
"MALTS": 1,
"MALTY": 1,
"MALUS": 1,
"MALVA": 1,
"MALWA": 1,
"MAMAS": 1,
"MAMBA": 1,
"MAMEE": 1,
"MAMEY": 1,
"MAMIE": 1,
"MANAS": 1,
"MANAT": 1,
"MANDI": 1,
"MANEB": 1,
"MANED": 1,
"MANEH": 1,
"MANES": 1,
"MANET": 1,
"MANGS": 1,
"MANIS": 1,
"MANKY": 1,
"MANNA": 1,
"MANOS": 1,
"MANSE": 1,
"MANTA": 1,
"MANTO": 1,
"MANTY": 1,
"MANUL": 1,
"MANUS": 1,
"MAPAU": 1,
"MAQUI": 1,
"MARAE": 1,
"MARAH": 1,
"MARAS": 1,
"MARCS": 1,
"MARDY": 1,
"MARES": 1,
"MARGE": 1,
"MARGS": 1,
"MARIA": 1,
"MARID": 1,
"MARKA": 1,
"MARKS": 1,
"MARLE": 1,
"MARLS": 1,
"MARLY": 1,
"MARMS": 1,
"MARON": 1,
"MAROR": 1,
"MARRA": 1,
"MARRI": 1,
"MARSE": 1,
"MARTS": 1,
"MARVY": 1,
"MASAS": 1,
"MASED": 1,
"MASER": 1,
"MASES": 1,
"MASHY": 1,
"MASKS": 1,
"MASSA": 1,
"MASSY": 1,
"MASTS": 1,
"MASTY": 1,
"MASUS": 1,
"MATAI": 1,
"MATED": 1,
"MATER": 1,
"MATES": 1,
"MATHS": 1,
"MATIN": 1,
"MATLO": 1,
"MATTE": 1,
"MATTS": 1,
"MATZA": 1,
"MATZO": 1,
"MAUBY": 1,
"MAUDS": 1,
"MAULS": 1,
"MAUND": 1,
"MAURI": 1,
"MAUSY": 1,
"MAUTS": 1,
"MAUZY": 1,
"MAVEN": 1,
"MAVIE": 1,
"MAVIN": 1,
"MAVIS": 1,
"MAWED": 1,
"MAWKS": 1,
"MAWKY": 1,
"MAWNS": 1,
"MAWRS": 1,
"MAXED": 1,
"MAXES": 1,
"MAXIS": 1,
"MAYAN": 1,
"MAYAS": 1,
"MAYED": 1,
"MAYOS": 1,
"MAYST": 1,
"MAZED": 1,
"MAZER": 1,
"MAZES": 1,
"MAZEY": 1,
"MAZUT": 1,
"MBIRA": 1,
"MEADS": 1,
"MEALS": 1,
"MEANE": 1,
"MEANS": 1,
"MEANY": 1,
"MEARE": 1,
"MEASE": 1,
"MEATH": 1,
"MEATS": 1,
"MEBOS": 1,
"MECHS": 1,
"MECKS": 1,
"MEDII": 1,
"MEDLE": 1,
"MEEDS": 1,
"MEERS": 1,
"MEETS": 1,
"MEFFS": 1,
"MEINS": 1,
"MEINT": 1,
"MEINY": 1,
"MEITH": 1,
"MEKKA": 1,
"MELAS": 1,
"MELBA": 1,
"MELDS": 1,
"MELIC": 1,
"MELIK": 1,
"MELLS": 1,
"MELTS": 1,
"MELTY": 1,
"MEMES": 1,
"MEMOS": 1,
"MENAD": 1,
"MENDS": 1,
"MENED": 1,
"MENES": 1,
"MENGE": 1,
"MENGS": 1,
"MENSA": 1,
"MENSE": 1,
"MENSH": 1,
"MENTA": 1,
"MENTO": 1,
"MENUS": 1,
"MEOUS": 1,
"MEOWS": 1,
"MERCH": 1,
"MERCS": 1,
"MERDE": 1,
"MERED": 1,
"MEREL": 1,
"MERER": 1,
"MERES": 1,
"MERIL": 1,
"MERIS": 1,
"MERKS": 1,
"MERLE": 1,
"MERLS": 1,
"MERSE": 1,
"MESAL": 1,
"MESAS": 1,
"MESEL": 1,
"MESES": 1,
"MESHY": 1,
"MESIC": 1,
"MESNE": 1,
"MESON": 1,
"MESSY": 1,
"MESTO": 1,
"METED": 1,
"METES": 1,
"METHO": 1,
"METHS": 1,
"METIC": 1,
"METIF": 1,
"METIS": 1,
"METOL": 1,
"METRE": 1,
"MEUSE": 1,
"MEVED": 1,
"MEVES": 1,
"MEWED": 1,
"MEWLS": 1,
"MEYNT": 1,
"MEZES": 1,
"MEZZE": 1,
"MEZZO": 1,
"MHORR": 1,
"MIAOU": 1,
"MIAOW": 1,
"MIASM": 1,
"MIAUL": 1,
"MICAS": 1,
"MICHE": 1,
"MICHT": 1,
"MICKS": 1,
"MICKY": 1,
"MICOS": 1,
"MICRA": 1,
"MIDDY": 1,
"MIDGY": 1,
"MIDIS": 1,
"MIENS": 1,
"MIEVE": 1,
"MIFFS": 1,
"MIFFY": 1,
"MIFTY": 1,
"MIGGS": 1,
"MIHAS": 1,
"MIHIS": 1,
"MIKED": 1,
"MIKES": 1,
"MIKRA": 1,
"MIKVA": 1,
"MILCH": 1,
"MILDS": 1,
"MILER": 1,
"MILES": 1,
"MILFS": 1,
"MILIA": 1,
"MILKO": 1,
"MILKS": 1,
"MILLE": 1,
"MILLS": 1,
"MILOR": 1,
"MILOS": 1,
"MILPA": 1,
"MILTS": 1,
"MILTY": 1,
"MILTZ": 1,
"MIMED": 1,
"MIMEO": 1,
"MIMER": 1,
"MIMES": 1,
"MIMSY": 1,
"MINAE": 1,
"MINAR": 1,
"MINAS": 1,
"MINCY": 1,
"MINDS": 1,
"MINED": 1,
"MINES": 1,
"MINGE": 1,
"MINGS": 1,
"MINGY": 1,
"MINIS": 1,
"MINKE": 1,
"MINKS": 1,
"MINNY": 1,
"MINOS": 1,
"MINTS": 1,
"MIRED": 1,
"MIRES": 1,
"MIREX": 1,
"MIRID": 1,
"MIRIN": 1,
"MIRKS": 1,
"MIRKY": 1,
"MIRLY": 1,
"MIROS": 1,
"MIRVS": 1,
"MIRZA": 1,
"MISCH": 1,
"MISDO": 1,
"MISES": 1,
"MISGO": 1,
"MISOS": 1,
"MISSA": 1,
"MISTS": 1,
"MISTY": 1,
"MITCH": 1,
"MITER": 1,
"MITES": 1,
"MITIS": 1,
"MITRE": 1,
"MITTS": 1,
"MIXED": 1,
"MIXEN": 1,
"MIXER": 1,
"MIXES": 1,
"MIXTE": 1,
"MIXUP": 1,
"MIZEN": 1,
"MIZZY": 1,
"MNEME": 1,
"MOANS": 1,
"MOATS": 1,
"MOBBY": 1,
"MOBES": 1,
"MOBEY": 1,
"MOBIE": 1,
"MOBLE": 1,
"MOCHI": 1,
"MOCHS": 1,
"MOCHY": 1,
"MOCKS": 1,
"MODER": 1,
"MODES": 1,
"MODGE": 1,
"MODII": 1,
"MODUS": 1,
"MOERS": 1,
"MOFOS": 1,
"MOGGY": 1,
"MOHEL": 1,
"MOHOS": 1,
"MOHRS": 1,
"MOHUA": 1,
"MOHUR": 1,
"MOILE": 1,
"MOILS": 1,
"MOIRA": 1,
"MOIRE": 1,
"MOITS": 1,
"MOJOS": 1,
"MOKES": 1,
"MOKIS": 1,
"MOKOS": 1,
"MOLAL": 1,
"MOLAS": 1,
"MOLDS": 1,
"MOLED": 1,
"MOLES": 1,
"MOLLA": 1,
"MOLLS": 1,
"MOLLY": 1,
"MOLTO": 1,
"MOLTS": 1,
"MOLYS": 1,
"MOMES": 1,
"MOMMA": 1,
"MOMMY": 1,
"MOMUS": 1,
"MONAD": 1,
"MONAL": 1,
"MONAS": 1,
"MONDE": 1,
"MONDO": 1,
"MONER": 1,
"MONGO": 1,
"MONGS": 1,
"MONIC": 1,
"MONIE": 1,
"MONKS": 1,
"MONOS": 1,
"MONTE": 1,
"MONTY": 1,
"MOOBS": 1,
"MOOCH": 1,
"MOODS": 1,
"MOOED": 1,
"MOOKS": 1,
"MOOLA": 1,
"MOOLI": 1,
"MOOLS": 1,
"MOOLY": 1,
"MOONG": 1,
"MOONS": 1,
"MOONY": 1,
"MOOPS": 1,
"MOORS": 1,
"MOORY": 1,
"MOOTS": 1,
"MOOVE": 1,
"MOPED": 1,
"MOPER": 1,
"MOPES": 1,
"MOPEY": 1,
"MOPPY": 1,
"MOPSY": 1,
"MOPUS": 1,
"MORAE": 1,
"MORAS": 1,
"MORAT": 1,
"MORAY": 1,
"MOREL": 1,
"MORES": 1,
"MORIA": 1,
"MORNE": 1,
"MORNS": 1,
"MORRA": 1,
"MORRO": 1,
"MORSE": 1,
"MORTS": 1,
"MOSED": 1,
"MOSES": 1,
"MOSEY": 1,
"MOSKS": 1,
"MOSSO": 1,
"MOSTE": 1,
"MOSTS": 1,
"MOTED": 1,
"MOTEN": 1,
"MOTES": 1,
"MOTET": 1,
"MOTEY": 1,
"MOTHS": 1,
"MOTHY": 1,
"MOTIS": 1,
"MOTTE": 1,
"MOTTS": 1,
"MOTTY": 1,
"MOTUS": 1,
"MOTZA": 1,
"MOUCH": 1,
"MOUES": 1,
"MOULD": 1,
"MOULS": 1,
"MOUPS": 1,
"MOUST": 1,
"MOUSY": 1,
"MOVED": 1,
"MOVES": 1,
"MOWAS": 1,
"MOWED": 1,
"MOWRA": 1,
"MOXAS": 1,
"MOXIE": 1,
"MOYAS": 1,
"MOYLE": 1,
"MOYLS": 1,
"MOZED": 1,
"MOZES": 1,
"MOZOS": 1,
"MPRET": 1,
"MUCHO": 1,
"MUCIC": 1,
"MUCID": 1,
"MUCIN": 1,
"MUCKS": 1,
"MUCOR": 1,
"MUCRO": 1,
"MUDGE": 1,
"MUDIR": 1,
"MUDRA": 1,
"MUFFS": 1,
"MUFTI": 1,
"MUGGA": 1,
"MUGGS": 1,
"MUGGY": 1,
"MUHLY": 1,
"MUIDS": 1,
"MUILS": 1,
"MUIRS": 1,
"MUIST": 1,
"MUJIK": 1,
"MULCT": 1,
"MULED": 1,
"MULES": 1,
"MULEY": 1,
"MULGA": 1,
"MULIE": 1,
"MULLA": 1,
"MULLS": 1,
"MULSE": 1,
"MULSH": 1,
"MUMMS": 1,
"MUMPS": 1,
"MUMSY": 1,
"MUMUS": 1,
"MUNGA": 1,
"MUNGE": 1,
"MUNGO": 1,
"MUNGS": 1,
"MUNIS": 1,
"MUNTS": 1,
"MUNTU": 1,
"MUONS": 1,
"MURAS": 1,
"MURED": 1,
"MURES": 1,
"MUREX": 1,
"MURID": 1,
"MURKS": 1,
"MURLS": 1,
"MURLY": 1,
"MURRA": 1,
"MURRE": 1,
"MURRI": 1,
"MURRS": 1,
"MURRY": 1,
"MURTI": 1,
"MURVA": 1,
"MUSAR": 1,
"MUSCA": 1,
"MUSED": 1,
"MUSER": 1,
"MUSES": 1,
"MUSET": 1,
"MUSHA": 1,
"MUSIT": 1,
"MUSKS": 1,
"MUSOS": 1,
"MUSSE": 1,
"MUSSY": 1,
"MUSTH": 1,
"MUSTS": 1,
"MUTCH": 1,
"MUTED": 1,
"MUTER": 1,
"MUTES": 1,
"MUTHA": 1,
"MUTIS": 1,
"MUTON": 1,
"MUTTS": 1,
"MUXED": 1,
"MUXES": 1,
"MUZAK": 1,
"MUZZY": 1,
"MVULE": 1,
"MYALL": 1,
"MYLAR": 1,
"MYNAH": 1,
"MYNAS": 1,
"MYOID": 1,
"MYOMA": 1,
"MYOPE": 1,
"MYOPS": 1,
"MYOPY": 1,
"MYSID": 1,
"MYTHI": 1,
"MYTHS": 1,
"MYTHY": 1,
"MYXOS": 1,
"MZEES": 1,
"NAAMS": 1,
"NAANS": 1,
"NABES": 1,
"NABIS": 1,
"NABKS": 1,
"NABLA": 1,
"NABOB": 1,
"NACHE": 1,
"NACHO": 1,
"NACRE": 1,
"NADAS": 1,
"NAEVE": 1,
"NAEVI": 1,
"NAFFS": 1,
"NAGAS": 1,
"NAGGY": 1,
"NAGOR": 1,
"NAHAL": 1,
"NAIAD": 1,
"NAIFS": 1,
"NAIKS": 1,
"NAILS": 1,
"NAIRA": 1,
"NAIRU": 1,
"NAKED": 1,
"NAKER": 1,
"NAKFA": 1,
"NALAS": 1,
"NALED": 1,
"NALLA": 1,
"NAMED": 1,
"NAMER": 1,
"NAMES": 1,
"NAMMA": 1,
"NAMUS": 1,
"NANAS": 1,
"NANCE": 1,
"NANCY": 1,
"NANDU": 1,
"NANNA": 1,
"NANOS": 1,
"NANUA": 1,
"NAPAS": 1,
"NAPED": 1,
"NAPES": 1,
"NAPOO": 1,
"NAPPA": 1,
"NAPPE": 1,
"NAPPY": 1,
"NARAS": 1,
"NARCO": 1,
"NARCS": 1,
"NARDS": 1,
"NARES": 1,
"NARIC": 1,
"NARIS": 1,
"NARKS": 1,
"NARKY": 1,
"NARRE": 1,
"NASHI": 1,
"NATCH": 1,
"NATES": 1,
"NATIS": 1,
"NATTY": 1,
"NAUCH": 1,
"NAUNT": 1,
"NAVAR": 1,
"NAVES": 1,
"NAVEW": 1,
"NAVVY": 1,
"NAWAB": 1,
"NAZES": 1,
"NAZIR": 1,
"NAZIS": 1,
"NDUJA": 1,
"NEAFE": 1,
"NEALS": 1,
"NEAPS": 1,
"NEARS": 1,
"NEATH": 1,
"NEATS": 1,
"NEBEK": 1,
"NEBEL": 1,
"NECKS": 1,
"NEDDY": 1,
"NEEDS": 1,
"NEELD": 1,
"NEELE": 1,
"NEEMB": 1,
"NEEMS": 1,
"NEEPS": 1,
"NEESE": 1,
"NEEZE": 1,
"NEGRO": 1,
"NEGUS": 1,
"NEIFS": 1,
"NEIST": 1,
"NEIVE": 1,
"NELIS": 1,
"NELLY": 1,
"NEMAS": 1,
"NEMNS": 1,
"NEMPT": 1,
"NENES": 1,
"NEONS": 1,
"NEPER": 1,
"NEPIT": 1,
"NERAL": 1,
"NERDS": 1,
"NERKA": 1,
"NERKS": 1,
"NEROL": 1,
"NERTS": 1,
"NERTZ": 1,
"NERVY": 1,
"NESTS": 1,
"NETES": 1,
"NETOP": 1,
"NETTS": 1,
"NETTY": 1,
"NEUKS": 1,
"NEUME": 1,
"NEUMS": 1,
"NEVEL": 1,
"NEVES": 1,
"NEVUS": 1,
"NEWBS": 1,
"NEWED": 1,
"NEWEL": 1,
"NEWIE": 1,
"NEWSY": 1,
"NEWTS": 1,
"NEXTS": 1,
"NEXUS": 1,
"NGAIO": 1,
"NGANA": 1,
"NGATI": 1,
"NGOMA": 1,
"NGWEE": 1,
"NICAD": 1,
"NICHT": 1,
"NICKS": 1,
"NICOL": 1,
"NIDAL": 1,
"NIDED": 1,
"NIDES": 1,
"NIDOR": 1,
"NIDUS": 1,
"NIEFS": 1,
"NIEVE": 1,
"NIFES": 1,
"NIFFS": 1,
"NIFFY": 1,
"NIFTY": 1,
"NIGER": 1,
"NIGHS": 1,
"NIHIL": 1,
"NIKAB": 1,
"NIKAH": 1,
"NIKAU": 1,
"NILLS": 1,
"NIMBI": 1,
"NIMBS": 1,
"NIMPS": 1,
"NINER": 1,
"NINES": 1,
"NINON": 1,
"NIPAS": 1,
"NIPPY": 1,
"NIQAB": 1,
"NIRLS": 1,
"NIRLY": 1,
"NISEI": 1,
"NISSE": 1,
"NISUS": 1,
"NITER": 1,
"NITES": 1,
"NITID": 1,
"NITON": 1,
"NITRE": 1,
"NITRO": 1,
"NITRY": 1,
"NITTY": 1,
"NIVAL": 1,
"NIXED": 1,
"NIXER": 1,
"NIXES": 1,
"NIXIE": 1,
"NIZAM": 1,
"NKOSI": 1,
"NOAHS": 1,
"NOBBY": 1,
"NOCKS": 1,
"NODAL": 1,
"NODDY": 1,
"NODES": 1,
"NODUS": 1,
"NOELS": 1,
"NOGGS": 1,
"NOHOW": 1,
"NOILS": 1,
"NOILY": 1,
"NOINT": 1,
"NOIRS": 1,
"NOLES": 1,
"NOLLS": 1,
"NOLOS": 1,
"NOMAS": 1,
"NOMEN": 1,
"NOMES": 1,
"NOMIC": 1,
"NOMOI": 1,
"NOMOS": 1,
"NONAS": 1,
"NONCE": 1,
"NONES": 1,
"NONET": 1,
"NONGS": 1,
"NONIS": 1,
"NONNY": 1,
"NONYL": 1,
"NOOBS": 1,
"NOOIT": 1,
"NOOKS": 1,
"NOOKY": 1,
"NOONS": 1,
"NOOPS": 1,
"NOPAL": 1,
"NORIA": 1,
"NORIS": 1,
"NORKS": 1,
"NORMA": 1,
"NORMS": 1,
"NOSED": 1,
"NOSER": 1,
"NOSES": 1,
"NOTAL": 1,
"NOTED": 1,
"NOTER": 1,
"NOTES": 1,
"NOTUM": 1,
"NOULD": 1,
"NOULE": 1,
"NOULS": 1,
"NOUNS": 1,
"NOUNY": 1,
"NOUPS": 1,
"NOVAE": 1,
"NOVAS": 1,
"NOVUM": 1,
"NOWAY": 1,
"NOWED": 1,
"NOWLS": 1,
"NOWTS": 1,
"NOWTY": 1,
"NOXAL": 1,
"NOXES": 1,
"NOYAU": 1,
"NOYED": 1,
"NOYES": 1,
"NUBBY": 1,
"NUBIA": 1,
"NUCHA": 1,
"NUDDY": 1,
"NUDER": 1,
"NUDES": 1,
"NUDIE": 1,
"NUDZH": 1,
"NUFFS": 1,
"NUGAE": 1,
"NUKED": 1,
"NUKES": 1,
"NULLA": 1,
"NULLS": 1,
"NUMBS": 1,
"NUMEN": 1,
"NUMMY": 1,
"NUNNY": 1,
"NURDS": 1,
"NURDY": 1,
"NURLS": 1,
"NURRS": 1,
"NUTSO": 1,
"NUTSY": 1,
"NYAFF": 1,
"NYALA": 1,
"NYING": 1,
"NYSSA": 1,
"OAKED": 1,
"OAKER": 1,
"OAKUM": 1,
"OARED": 1,
"OASES": 1,
"OASIS": 1,
"OASTS": 1,
"OATEN": 1,
"OATER": 1,
"OATHS": 1,
"OAVES": 1,
"OBANG": 1,
"OBEAH": 1,
"OBELI": 1,
"OBEYS": 1,
"OBIAS": 1,
"OBIED": 1,
"OBIIT": 1,
"OBITS": 1,
"OBJET": 1,
"OBOES": 1,
"OBOLE": 1,
"OBOLI": 1,
"OBOLS": 1,
"OCCAM": 1,
"OCHER": 1,
"OCHES": 1,
"OCHRE": 1,
"OCHRY": 1,
"OCKER": 1,
"OCREA": 1,
"OCTAD": 1,
"OCTAN": 1,
"OCTAS": 1,
"OCTYL": 1,
"OCULI": 1,
"ODAHS": 1,
"ODALS": 1,
"ODEON": 1,
"ODEUM": 1,
"ODISM": 1,
"ODIST": 1,
"ODIUM": 1,
"ODORS": 1,
"ODOUR": 1,
"ODYLE": 1,
"ODYLS": 1,
"OFAYS": 1,
"OFFED": 1,
"OFFIE": 1,
"OFLAG": 1,
"OFTER": 1,
"OGAMS": 1,
"OGEED": 1,
"OGEES": 1,
"OGGIN": 1,
"OGHAM": 1,
"OGIVE": 1,
"OGLED": 1,
"OGLER": 1,
"OGLES": 1,
"OGMIC": 1,
"OGRES": 1,
"OHIAS": 1,
"OHING": 1,
"OHMIC": 1,
"OHONE": 1,
"OIDIA": 1,
"OILED": 1,
"OILER": 1,
"OINKS": 1,
"OINTS": 1,
"OJIME": 1,
"OKAPI": 1,
"OKAYS": 1,
"OKEHS": 1,
"OKRAS": 1,
"OKTAS": 1,
"OLDIE": 1,
"OLEIC": 1,
"OLEIN": 1,
"OLENT": 1,
"OLEOS": 1,
"OLEUM": 1,
"OLIOS": 1,
"OLLAS": 1,
"OLLAV": 1,
"OLLER": 1,
"OLLIE": 1,
"OLOGY": 1,
"OLPAE": 1,
"OLPES": 1,
"OMASA": 1,
"OMBER": 1,
"OMBUS": 1,
"OMENS": 1,
"OMERS": 1,
"OMITS": 1,
"OMLAH": 1,
"OMOVS": 1,
"OMRAH": 1,
"ONCER": 1,
"ONCES": 1,
"ONCET": 1,
"ONCUS": 1,
"ONELY": 1,
"ONERS": 1,
"ONERY": 1,
"ONIUM": 1,
"ONKUS": 1,
"ONLAY": 1,
"ONNED": 1,
"ONTIC": 1,
"OOBIT": 1,
"OOHED": 1,
"OOMPH": 1,
"OONTS": 1,
"OOPED": 1,
"OORIE": 1,
"OOSES": 1,
"OOTID": 1,
"OOZED": 1,
"OOZES": 1,
"OPAHS": 1,
"OPALS": 1,
"OPENS": 1,
"OPEPE": 1,
"OPING": 1,
"OPPOS": 1,
"OPSIN": 1,
"OPTED": 1,
"OPTER": 1,
"ORACH": 1,
"ORACY": 1,
"ORALS": 1,
"ORANG": 1,
"ORANT": 1,
"ORATE": 1,
"ORBED": 1,
"ORCAS": 1,
"ORCIN": 1,
"ORDOS": 1,
"OREAD": 1,
"ORFES": 1,
"ORGIA": 1,
"ORGIC": 1,
"ORGUE": 1,
"ORIBI": 1,
"ORIEL": 1,
"ORIXA": 1,
"ORLES": 1,
"ORLON": 1,
"ORLOP": 1,
"ORMER": 1,
"ORNIS": 1,
"ORPIN": 1,
"ORRIS": 1,
"ORTHO": 1,
"ORVAL": 1,
"ORZOS": 1,
"OSCAR": 1,
"OSHAC": 1,
"OSIER": 1,
"OSMIC": 1,
"OSMOL": 1,
"OSSIA": 1,
"OSTIA": 1,
"OTAKU": 1,
"OTARY": 1,
"OTTAR": 1,
"OTTOS": 1,
"OUBIT": 1,
"OUCHT": 1,
"OUENS": 1,
"OUIJA": 1,
"OULKS": 1,
"OUMAS": 1,
"OUNDY": 1,
"OUPAS": 1,
"OUPED": 1,
"OUPHE": 1,
"OUPHS": 1,
"OURIE": 1,
"OUSEL": 1,
"OUSTS": 1,
"OUTBY": 1,
"OUTED": 1,
"OUTRE": 1,
"OUTRO": 1,
"OUTTA": 1,
"OUZEL": 1,
"OUZOS": 1,
"OVALS": 1,
"OVELS": 1,
"OVENS": 1,
"OVERS": 1,
"OVIST": 1,
"OVOLI": 1,
"OVOLO": 1,
"OVULE": 1,
"OWCHE": 1,
"OWIES": 1,
"OWLED": 1,
"OWLER": 1,
"OWLET": 1,
"OWNED": 1,
"OWRES": 1,
"OWRIE": 1,
"OWSEN": 1,
"OXBOW": 1,
"OXERS": 1,
"OXEYE": 1,
"OXIDS": 1,
"OXIES": 1,
"OXIME": 1,
"OXIMS": 1,
"OXLIP": 1,
"OXTER": 1,
"OYERS": 1,
"OZEKI": 1,
"OZZIE": 1,
"PAALS": 1,
"PAANS": 1,
"PACAS": 1,
"PACED": 1,
"PACER": 1,
"PACES": 1,
"PACEY": 1,
"PACHA": 1,
"PACKS": 1,
"PACOS": 1,
"PACTA": 1,
"PACTS": 1,
"PADIS": 1,
"PADLE": 1,
"PADMA": 1,
"PADRE": 1,
"PADRI": 1,
"PAEAN": 1,
"PAEDO": 1,
"PAEON": 1,
"PAGED": 1,
"PAGER": 1,
"PAGES": 1,
"PAGLE": 1,
"PAGOD": 1,
"PAGRI": 1,
"PAIKS": 1,
"PAILS": 1,
"PAINS": 1,
"PAIRE": 1,
"PAIRS": 1,
"PAISA": 1,
"PAISE": 1,
"PAKKA": 1,
"PALAS": 1,
"PALAY": 1,
"PALEA": 1,
"PALED": 1,
"PALES": 1,
"PALET": 1,
"PALIS": 1,
"PALKI": 1,
"PALLA": 1,
"PALLS": 1,
"PALLY": 1,
"PALMS": 1,
"PALMY": 1,
"PALPI": 1,
"PALPS": 1,
"PALSA": 1,
"PAMPA": 1,
"PANAX": 1,
"PANCE": 1,
"PANDA": 1,
"PANDS": 1,
"PANDY": 1,
"PANED": 1,
"PANES": 1,
"PANGA": 1,
"PANGS": 1,
"PANIM": 1,
"PANKO": 1,
"PANNE": 1,
"PANNI": 1,
"PANTO": 1,
"PANTS": 1,
"PANTY": 1,
"PAOLI": 1,
"PAOLO": 1,
"PAPAS": 1,
"PAPAW": 1,
"PAPES": 1,
"PAPPI": 1,
"PAPPY": 1,
"PARAE": 1,
"PARAS": 1,
"PARCH": 1,
"PARDI": 1,
"PARDS": 1,
"PARDY": 1,
"PARED": 1,
"PAREN": 1,
"PAREO": 1,
"PARES": 1,
"PAREU": 1,
"PAREV": 1,
"PARGE": 1,
"PARGO": 1,
"PARIS": 1,
"PARKI": 1,
"PARKS": 1,
"PARKY": 1,
"PARLE": 1,
"PARLY": 1,
"PARMA": 1,
"PAROL": 1,
"PARPS": 1,
"PARRA": 1,
"PARRS": 1,
"PARTI": 1,
"PARTS": 1,
"PARVE": 1,
"PARVO": 1,
"PASEO": 1,
"PASES": 1,
"PASHA": 1,
"PASHM": 1,
"PASKA": 1,
"PASPY": 1,
"PASSE": 1,
"PASTS": 1,
"PATED": 1,
"PATEN": 1,
"PATER": 1,
"PATES": 1,
"PATHS": 1,
"PATIN": 1,
"PATKA": 1,
"PATLY": 1,
"PATTE": 1,
"PATUS": 1,
"PAUAS": 1,
"PAULS": 1,
"PAVAN": 1,
"PAVED": 1,
"PAVEN": 1,
"PAVER": 1,
"PAVES": 1,
"PAVID": 1,
"PAVIN": 1,
"PAVIS": 1,
"PAWAS": 1,
"PAWAW": 1,
"PAWED": 1,
"PAWER": 1,
"PAWKS": 1,
"PAWKY": 1,
"PAWLS": 1,
"PAWNS": 1,
"PAXES": 1,
"PAYED": 1,
"PAYOR": 1,
"PAYSD": 1,
"PEAGE": 1,
"PEAGS": 1,
"PEAKS": 1,
"PEAKY": 1,
"PEALS": 1,
"PEANS": 1,
"PEARE": 1,
"PEARS": 1,
"PEART": 1,
"PEASE": 1,
"PEATS": 1,
"PEATY": 1,
"PEAVY": 1,
"PEAZE": 1,
"PEBAS": 1,
"PECHS": 1,
"PECKE": 1,
"PECKS": 1,
"PECKY": 1,
"PEDES": 1,
"PEDIS": 1,
"PEDRO": 1,
"PEECE": 1,
"PEEKS": 1,
"PEELS": 1,
"PEENS": 1,
"PEEOY": 1,
"PEEPE": 1,
"PEEPS": 1,
"PEERS": 1,
"PEERY": 1,
"PEEVE": 1,
"PEGGY": 1,
"PEGHS": 1,
"PEINS": 1,
"PEISE": 1,
"PEIZE": 1,
"PEKAN": 1,
"PEKES": 1,
"PEKIN": 1,
"PEKOE": 1,
"PELAS": 1,
"PELAU": 1,
"PELES": 1,
"PELFS": 1,
"PELLS": 1,
"PELMA": 1,
"PELON": 1,
"PELTA": 1,
"PELTS": 1,
"PENDS": 1,
"PENDU": 1,
"PENED": 1,
"PENES": 1,
"PENGO": 1,
"PENIE": 1,
"PENIS": 1,
"PENKS": 1,
"PENNA": 1,
"PENNI": 1,
"PENTS": 1,
"PEONS": 1,
"PEONY": 1,
"PEPLA": 1,
"PEPOS": 1,
"PEPPY": 1,
"PEPSI": 1,
"PERAI": 1,
"PERCE": 1,
"PERCS": 1,
"PERDU": 1,
"PERDY": 1,
"PEREA": 1,
"PERES": 1,
"PERIS": 1,
"PERKS": 1,
"PERMS": 1,
"PERNS": 1,
"PEROG": 1,
"PERPS": 1,
"PERRY": 1,
"PERSE": 1,
"PERST": 1,
"PERTS": 1,
"PERVE": 1,
"PERVO": 1,
"PERVS": 1,
"PERVY": 1,
"PESOS": 1,
"PESTS": 1,
"PESTY": 1,
"PETAR": 1,
"PETER": 1,
"PETIT": 1,
"PETRE": 1,
"PETRI": 1,
"PETTI": 1,
"PETTO": 1,
"PEWEE": 1,
"PEWIT": 1,
"PEYSE": 1,
"PHAGE": 1,
"PHANG": 1,
"PHARE": 1,
"PHARM": 1,
"PHEER": 1,
"PHENE": 1,
"PHEON": 1,
"PHESE": 1,
"PHIAL": 1,
"PHISH": 1,
"PHIZZ": 1,
"PHLOX": 1,
"PHOCA": 1,
"PHONO": 1,
"PHONS": 1,
"PHOTS": 1,
"PHPHT": 1,
"PHUTS": 1,
"PHYLA": 1,
"PHYLE": 1,
"PIANI": 1,
"PIANS": 1,
"PIBAL": 1,
"PICAL": 1,
"PICAS": 1,
"PICCY": 1,
"PICKS": 1,
"PICOT": 1,
"PICRA": 1,
"PICUL": 1,
"PIEND": 1,
"PIERS": 1,
"PIERT": 1,
"PIETA": 1,
"PIETS": 1,
"PIEZO": 1,
"PIGHT": 1,
"PIGMY": 1,
"PIING": 1,
"PIKAS": 1,
"PIKAU": 1,
"PIKED": 1,
"PIKER": 1,
"PIKES": 1,
"PIKEY": 1,
"PIKIS": 1,
"PIKUL": 1,
"PILAE": 1,
"PILAF": 1,
"PILAO": 1,
"PILAR": 1,
"PILAU": 1,
"PILAW": 1,
"PILCH": 1,
"PILEA": 1,
"PILED": 1,
"PILEI": 1,
"PILER": 1,
"PILES": 1,
"PILIS": 1,
"PILLS": 1,
"PILOW": 1,
"PILUM": 1,
"PILUS": 1,
"PIMAS": 1,
"PIMPS": 1,
"PINAS": 1,
"PINED": 1,
"PINES": 1,
"PINGO": 1,
"PINGS": 1,
"PINKO": 1,
"PINKS": 1,
"PINNA": 1,
"PINNY": 1,
"PINON": 1,
"PINOT": 1,
"PINTA": 1,
"PINTS": 1,
"PINUP": 1,
"PIONS": 1,
"PIONY": 1,
"PIOUS": 1,
"PIOYE": 1,
"PIOYS": 1,
"PIPAL": 1,
"PIPAS": 1,
"PIPED": 1,
"PIPES": 1,
"PIPET": 1,
"PIPIS": 1,
"PIPIT": 1,
"PIPPY": 1,
"PIPUL": 1,
"PIRAI": 1,
"PIRLS": 1,
"PIRNS": 1,
"PIROG": 1,
"PISCO": 1,
"PISES": 1,
"PISKY": 1,
"PISOS": 1,
"PISSY": 1,
"PISTE": 1,
"PITAS": 1,
"PITHS": 1,
"PITON": 1,
"PITOT": 1,
"PITTA": 1,
"PIUMS": 1,
"PIXES": 1,
"PIZED": 1,
"PIZES": 1,
"PLAAS": 1,
"PLACK": 1,
"PLAGE": 1,
"PLANS": 1,
"PLAPS": 1,
"PLASH": 1,
"PLASM": 1,
"PLAST": 1,
"PLATS": 1,
"PLATT": 1,
"PLATY": 1,
"PLAYA": 1,
"PLAYS": 1,
"PLEAS": 1,
"PLEBE": 1,
"PLEBS": 1,
"PLENA": 1,
"PLEON": 1,
"PLESH": 1,
"PLEWS": 1,
"PLICA": 1,
"PLIES": 1,
"PLIMS": 1,
"PLING": 1,
"PLINK": 1,
"PLOAT": 1,
"PLODS": 1,
"PLONG": 1,
"PLONK": 1,
"PLOOK": 1,
"PLOPS": 1,
"PLOTS": 1,
"PLOTZ": 1,
"PLOUK": 1,
"PLOWS": 1,
"PLOYE": 1,
"PLOYS": 1,
"PLUES": 1,
"PLUFF": 1,
"PLUGS": 1,
"PLUMS": 1,
"PLUMY": 1,
"PLUOT": 1,
"PLUTO": 1,
"PLYER": 1,
"POACH": 1,
"POAKA": 1,
"POAKE": 1,
"POBOY": 1,
"POCKS": 1,
"POCKY": 1,
"PODAL": 1,
"PODDY": 1,
"PODEX": 1,
"PODGE": 1,
"PODGY": 1,
"PODIA": 1,
"POEMS": 1,
"POEPS": 1,
"POETS": 1,
"POGEY": 1,
"POGGE": 1,
"POGOS": 1,
"POHED": 1,
"POILU": 1,
"POIND": 1,
"POKAL": 1,
"POKED": 1,
"POKES": 1,
"POKEY": 1,
"POKIE": 1,
"POLED": 1,
"POLER": 1,
"POLES": 1,
"POLEY": 1,
"POLIO": 1,
"POLIS": 1,
"POLJE": 1,
"POLKS": 1,
"POLLS": 1,
"POLLY": 1,
"POLOS": 1,
"POLTS": 1,
"POLYS": 1,
"POMBE": 1,
"POMES": 1,
"POMMY": 1,
"POMOS": 1,
"POMPS": 1,
"PONCE": 1,
"PONCY": 1,
"PONDS": 1,
"PONES": 1,
"PONEY": 1,
"PONGA": 1,
"PONGO": 1,
"PONGS": 1,
"PONGY": 1,
"PONKS": 1,
"PONTS": 1,
"PONTY": 1,
"PONZU": 1,
"POODS": 1,
"POOED": 1,
"POOFS": 1,
"POOFY": 1,
"POOHS": 1,
"POOJA": 1,
"POOKA": 1,
"POOKS": 1,
"POOLS": 1,
"POONS": 1,
"POOPS": 1,
"POOPY": 1,
"POORI": 1,
"POORT": 1,
"POOTS": 1,
"POOVE": 1,
"POOVY": 1,
"POPES": 1,
"POPPA": 1,
"POPSY": 1,
"PORAE": 1,
"PORAL": 1,
"PORED": 1,
"PORER": 1,
"PORES": 1,
"PORGE": 1,
"PORGY": 1,
"PORIN": 1,
"PORKS": 1,
"PORKY": 1,
"PORNO": 1,
"PORNS": 1,
"PORNY": 1,
"PORTA": 1,
"PORTS": 1,
"PORTY": 1,
"POSED": 1,
"POSES": 1,
"POSEY": 1,
"POSHO": 1,
"POSTS": 1,
"POTAE": 1,
"POTCH": 1,
"POTED": 1,
"POTES": 1,
"POTIN": 1,
"POTOO": 1,
"POTSY": 1,
"POTTO": 1,
"POTTS": 1,
"POTTY": 1,
"POUFF": 1,
"POUFS": 1,
"POUKE": 1,
"POUKS": 1,
"POULE": 1,
"POULP": 1,
"POULT": 1,
"POUPE": 1,
"POUPT": 1,
"POURS": 1,
"POUTS": 1,
"POWAN": 1,
"POWIN": 1,
"POWND": 1,
"POWNS": 1,
"POWNY": 1,
"POWRE": 1,
"POXED": 1,
"POXES": 1,
"POYNT": 1,
"POYOU": 1,
"POYSE": 1,
"POZZY": 1,
"PRAAM": 1,
"PRADS": 1,
"PRAHU": 1,
"PRAMS": 1,
"PRANA": 1,
"PRANG": 1,
"PRAOS": 1,
"PRASE": 1,
"PRATE": 1,
"PRATS": 1,
"PRATT": 1,
"PRATY": 1,
"PRAUS": 1,
"PRAYS": 1,
"PREDY": 1,
"PREED": 1,
"PREES": 1,
"PREIF": 1,
"PREMS": 1,
"PREMY": 1,
"PRENT": 1,
"PREON": 1,
"PREOP": 1,
"PREPS": 1,
"PRESA": 1,
"PRESE": 1,
"PREST": 1,
"PREVE": 1,
"PREXY": 1,
"PREYS": 1,
"PRIAL": 1,
"PRICY": 1,
"PRIEF": 1,
"PRIER": 1,
"PRIES": 1,
"PRIGS": 1,
"PRILL": 1,
"PRIMA": 1,
"PRIMI": 1,
"PRIMP": 1,
"PRIMS": 1,
"PRIMY": 1,
"PRINK": 1,
"PRION": 1,
"PRISE": 1,
"PRISS": 1,
"PROAS": 1,
"PROBS": 1,
"PRODS": 1,
"PROEM": 1,
"PROFS": 1,
"PROGS": 1,
"PROIN": 1,
"PROKE": 1,
"PROLE": 1,
"PROLL": 1,
"PROMO": 1,
"PROMS": 1,
"PRONK": 1,
"PROPS": 1,
"PRORE": 1,
"PROSO": 1,
"PROSS": 1,
"PROST": 1,
"PROSY": 1,
"PROTO": 1,
"PROUL": 1,
"PROWS": 1,
"PROYN": 1,
"PRUNT": 1,
"PRUTA": 1,
"PRYER": 1,
"PRYSE": 1,
"PSEUD": 1,
"PSHAW": 1,
"PSION": 1,
"PSOAE": 1,
"PSOAI": 1,
"PSOAS": 1,
"PSORA": 1,
"PSYCH": 1,
"PSYOP": 1,
"PUBCO": 1,
"PUBES": 1,
"PUBIS": 1,
"PUCAN": 1,
"PUCER": 1,
"PUCES": 1,
"PUCKA": 1,
"PUCKS": 1,
"PUDDY": 1,
"PUDGE": 1,
"PUDIC": 1,
"PUDOR": 1,
"PUDSY": 1,
"PUDUS": 1,
"PUERS": 1,
"PUFFA": 1,
"PUFFS": 1,
"PUGGY": 1,
"PUGIL": 1,
"PUHAS": 1,
"PUJAH": 1,
"PUJAS": 1,
"PUKAS": 1,
"PUKED": 1,
"PUKER": 1,
"PUKES": 1,
"PUKEY": 1,
"PUKKA": 1,
"PUKUS": 1,
"PULAO": 1,
"PULAS": 1,
"PULED": 1,
"PULER": 1,
"PULES": 1,
"PULIK": 1,
"PULIS": 1,
"PULKA": 1,
"PULKS": 1,
"PULLI": 1,
"PULLS": 1,
"PULLY": 1,
"PULMO": 1,
"PULPS": 1,
"PULUS": 1,
"PUMAS": 1,
"PUMIE": 1,
"PUMPS": 1,
"PUNAS": 1,
"PUNCE": 1,
"PUNGA": 1,
"PUNGS": 1,
"PUNJI": 1,
"PUNKA": 1,
"PUNKS": 1,
"PUNKY": 1,
"PUNNY": 1,
"PUNTO": 1,
"PUNTS": 1,
"PUNTY": 1,
"PUPAE": 1,
"PUPAS": 1,
"PUPUS": 1,
"PURDA": 1,
"PURED": 1,
"PURES": 1,
"PURIN": 1,
"PURIS": 1,
"PURLS": 1,
"PURPY": 1,
"PURRS": 1,
"PURSY": 1,
"PURTY": 1,
"PUSES": 1,
"PUSLE": 1,
"PUSSY": 1,
"PUTID": 1,
"PUTON": 1,
"PUTTI": 1,
"PUTTO": 1,
"PUTTS": 1,
"PUZEL": 1,
"PWNED": 1,
"PYATS": 1,
"PYETS": 1,
"PYGAL": 1,
"PYINS": 1,
"PYLON": 1,
"PYNED": 1,
"PYNES": 1,
"PYOID": 1,
"PYOTS": 1,
"PYRAL": 1,
"PYRAN": 1,
"PYRES": 1,
"PYREX": 1,
"PYRIC": 1,
"PYROS": 1,
"PYXED": 1,
"PYXES": 1,
"PYXIE": 1,
"PYXIS": 1,
"PZAZZ": 1,
"QADIS": 1,
"QAIDS": 1,
"QAJAQ": 1,
"QANAT": 1,
"QAPIK": 1,
"QIBLA": 1,
"QOPHS": 1,
"QORMA": 1,
"QUADS": 1,
"QUAFF": 1,
"QUAGS": 1,
"QUAIR": 1,
"QUAIS": 1,
"QUAKY": 1,
"QUALE": 1,
"QUANT": 1,
"QUARE": 1,
"QUASS": 1,
"QUATE": 1,
"QUATS": 1,
"QUAYD": 1,
"QUAYS": 1,
"QUBIT": 1,
"QUEAN": 1,
"QUEME": 1,
"QUENA": 1,
"QUERN": 1,
"QUEYN": 1,
"QUEYS": 1,
"QUICH": 1,
"QUIDS": 1,
"QUIFF": 1,
"QUIMS": 1,
"QUINA": 1,
"QUINE": 1,
"QUINO": 1,
"QUINS": 1,
"QUINT": 1,
"QUIPO": 1,
"QUIPS": 1,
"QUIPU": 1,
"QUIRE": 1,
"QUIRT": 1,
"QUIST": 1,
"QUITS": 1,
"QUOAD": 1,
"QUODS": 1,
"QUOIF": 1,
"QUOIN": 1,
"QUOIT": 1,
"QUOLL": 1,
"QUONK": 1,
"QUOPS": 1,
"QURSH": 1,
"QUYTE": 1,
"RABAT": 1,
"RABIC": 1,
"RABIS": 1,
"RACED": 1,
"RACES": 1,
"RACHE": 1,
"RACKS": 1,
"RACON": 1,
"RADGE": 1,
"RADIX": 1,
"RADON": 1,
"RAFFS": 1,
"RAFTS": 1,
"RAGAS": 1,
"RAGDE": 1,
"RAGED": 1,
"RAGEE": 1,
"RAGER": 1,
"RAGES": 1,
"RAGGA": 1,
"RAGGS": 1,
"RAGGY": 1,
"RAGIS": 1,
"RAGUS": 1,
"RAHED": 1,
"RAHUI": 1,
"RAIAS": 1,
"RAIDS": 1,
"RAIKS": 1,
"RAILE": 1,
"RAILS": 1,
"RAINE": 1,
"RAINS": 1,
"RAIRD": 1,
"RAITA": 1,
"RAITS": 1,
"RAJAS": 1,
"RAJES": 1,
"RAKED": 1,
"RAKEE": 1,
"RAKER": 1,
"RAKES": 1,
"RAKIA": 1,
"RAKIS": 1,
"RAKUS": 1,
"RALES": 1,
"RAMAL": 1,
"RAMEE": 1,
"RAMET": 1,
"RAMIE": 1,
"RAMIN": 1,
"RAMIS": 1,
"RAMMY": 1,
"RAMPS": 1,
"RAMUS": 1,
"RANAS": 1,
"RANCE": 1,
"RANDS": 1,
"RANEE": 1,
"RANGA": 1,
"RANGI": 1,
"RANGS": 1,
"RANGY": 1,
"RANID": 1,
"RANIS": 1,
"RANKE": 1,
"RANKS": 1,
"RANTS": 1,
"RAPED": 1,
"RAPER": 1,
"RAPES": 1,
"RAPHE": 1,
"RAPPE": 1,
"RARED": 1,
"RAREE": 1,
"RARES": 1,
"RARKS": 1,
"RASED": 1,
"RASER": 1,
"RASES": 1,
"RASPS": 1,
"RASSE": 1,
"RASTA": 1,
"RATAL": 1,
"RATAN": 1,
"RATAS": 1,
"RATCH": 1,
"RATED": 1,
"RATEL": 1,
"RATER": 1,
"RATES": 1,
"RATHA": 1,
"RATHE": 1,
"RATHS": 1,
"RATOO": 1,
"RATOS": 1,
"RATUS": 1,
"RAUNS": 1,
"RAUPO": 1,
"RAVED": 1,
"RAVEL": 1,
"RAVER": 1,
"RAVES": 1,
"RAVEY": 1,
"RAVIN": 1,
"RAWER": 1,
"RAWIN": 1,
"RAWLY": 1,
"RAWNS": 1,
"RAXED": 1,
"RAXES": 1,
"RAYAH": 1,
"RAYAS": 1,
"RAYED": 1,
"RAYLE": 1,
"RAYNE": 1,
"RAZED": 1,
"RAZEE": 1,
"RAZER": 1,
"RAZES": 1,
"RAZOO": 1,
"READD": 1,
"READS": 1,
"REAIS": 1,
"REAKS": 1,
"REALO": 1,
"REALS": 1,
"REAME": 1,
"REAMS": 1,
"REAMY": 1,
"REANS": 1,
"REAPS": 1,
"REARS": 1,
"REAST": 1,
"REATA": 1,
"REATE": 1,
"REAVE": 1,
"REBBE": 1,
"REBEC": 1,
"REBID": 1,
"REBIT": 1,
"REBOP": 1,
"REBUY": 1,
"RECAL": 1,
"RECCE": 1,
"RECCO": 1,
"RECCY": 1,
"RECIT": 1,
"RECKS": 1,
"RECON": 1,
"RECTA": 1,
"RECTI": 1,
"RECTO": 1,
"REDAN": 1,
"REDDS": 1,
"REDDY": 1,
"REDED": 1,
"REDES": 1,
"REDIA": 1,
"REDID": 1,
"REDIP": 1,
"REDLY": 1,
"REDON": 1,
"REDOS": 1,
"REDOX": 1,
"REDRY": 1,
"REDUB": 1,
"REDUX": 1,
"REDYE": 1,
"REECH": 1,
"REEDE": 1,
"REEDS": 1,
"REEFS": 1,
"REEFY": 1,
"REEKS": 1,
"REEKY": 1,
"REELS": 1,
"REENS": 1,
"REEST": 1,
"REEVE": 1,
"REFED": 1,
"REFEL": 1,
"REFFO": 1,
"REFIS": 1,
"REFIX": 1,
"REFLY": 1,
"REFRY": 1,
"REGAR": 1,
"REGES": 1,
"REGGO": 1,
"REGIE": 1,
"REGMA": 1,
"REGNA": 1,
"REGOS": 1,
"REGUR": 1,
"REHEM": 1,
"REIFS": 1,
"REIFY": 1,
"REIKI": 1,
"REIKS": 1,
"REINK": 1,
"REINS": 1,
"REIRD": 1,
"REIST": 1,
"REIVE": 1,
"REJIG": 1,
"REJON": 1,
"REKED": 1,
"REKES": 1,
"REKEY": 1,
"RELET": 1,
"RELIE": 1,
"RELIT": 1,
"RELLO": 1,
"REMAN": 1,
"REMAP": 1,
"REMEN": 1,
"REMET": 1,
"REMEX": 1,
"REMIX": 1,
"RENAY": 1,
"RENDS": 1,
"RENEY": 1,
"RENGA": 1,
"RENIG": 1,
"RENIN": 1,
"RENNE": 1,
"RENOS": 1,
"RENTE": 1,
"RENTS": 1,
"REOIL": 1,
"REORG": 1,
"REPEG": 1,
"REPIN": 1,
"REPLA": 1,
"REPOS": 1,
"REPOT": 1,
"REPPS": 1,
"REPRO": 1,
"RERAN": 1,
"RERIG": 1,
"RESAT": 1,
"RESAW": 1,
"RESAY": 1,
"RESEE": 1,
"RESES": 1,
"RESEW": 1,
"RESID": 1,
"RESIT": 1,
"RESOD": 1,
"RESOW": 1,
"RESTO": 1,
"RESTS": 1,
"RESTY": 1,
"RESUS": 1,
"RETAG": 1,
"RETAX": 1,
"RETEM": 1,
"RETIA": 1,
"RETIE": 1,
"RETOX": 1,
"REVET": 1,
"REVIE": 1,
"REWAN": 1,
"REWAX": 1,
"REWED": 1,
"REWET": 1,
"REWIN": 1,
"REWON": 1,
"REWTH": 1,
"REXES": 1,
"REZES": 1,
"RHEAS": 1,
"RHEME": 1,
"RHEUM": 1,
"RHIES": 1,
"RHIME": 1,
"RHINE": 1,
"RHODY": 1,
"RHOMB": 1,
"RHONE": 1,
"RHUMB": 1,
"RHYNE": 1,
"RHYTA": 1,
"RIADS": 1,
"RIALS": 1,
"RIANT": 1,
"RIATA": 1,
"RIBAS": 1,
"RIBBY": 1,
"RIBES": 1,
"RICED": 1,
"RICER": 1,
"RICES": 1,
"RICEY": 1,
"RICHT": 1,
"RICIN": 1,
"RICKS": 1,
"RIDES": 1,
"RIDGY": 1,
"RIDIC": 1,
"RIELS": 1,
"RIEMS": 1,
"RIEVE": 1,
"RIFER": 1,
"RIFFS": 1,
"RIFTE": 1,
"RIFTS": 1,
"RIFTY": 1,
"RIGGS": 1,
"RIGOL": 1,
"RILED": 1,
"RILES": 1,
"RILEY": 1,
"RILLE": 1,
"RILLS": 1,
"RIMAE": 1,
"RIMED": 1,
"RIMER": 1,
"RIMES": 1,
"RIMUS": 1,
"RINDS": 1,
"RINDY": 1,
"RINES": 1,
"RINGS": 1,
"RINKS": 1,
"RIOJA": 1,
"RIOTS": 1,
"RIPED": 1,
"RIPES": 1,
"RIPPS": 1,
"RISES": 1,
"RISHI": 1,
"RISKS": 1,
"RISPS": 1,
"RISUS": 1,
"RITES": 1,
"RITTS": 1,
"RITZY": 1,
"RIVAS": 1,
"RIVED": 1,
"RIVEL": 1,
"RIVEN": 1,
"RIVES": 1,
"RIYAL": 1,
"RIZAS": 1,
"ROADS": 1,
"ROAMS": 1,
"ROANS": 1,
"ROARS": 1,
"ROARY": 1,
"ROATE": 1,
"ROBED": 1,
"ROBES": 1,
"ROBLE": 1,
"ROCKS": 1,
"RODED": 1,
"RODES": 1,
"ROGUY": 1,
"ROHES": 1,
"ROIDS": 1,
"ROILS": 1,
"ROILY": 1,
"ROINS": 1,
"ROIST": 1,
"ROJAK": 1,
"ROJIS": 1,
"ROKED": 1,
"ROKER": 1,
"ROKES": 1,
"ROLAG": 1,
"ROLES": 1,
"ROLFS": 1,
"ROLLS": 1,
"ROMAL": 1,
"ROMAN": 1,
"ROMEO": 1,
"ROMPS": 1,
"RONDE": 1,
"RONDO": 1,
"RONEO": 1,
"RONES": 1,
"RONIN": 1,
"RONNE": 1,
"RONTE": 1,
"RONTS": 1,
"ROODS": 1,
"ROOFS": 1,
"ROOFY": 1,
"ROOKS": 1,
"ROOKY": 1,
"ROOMS": 1,
"ROONS": 1,
"ROOPS": 1,
"ROOPY": 1,
"ROOSA": 1,
"ROOSE": 1,
"ROOTS": 1,
"ROOTY": 1,
"ROPED": 1,
"ROPER": 1,
"ROPES": 1,
"ROPEY": 1,
"ROQUE": 1,
"RORAL": 1,
"RORES": 1,
"RORIC": 1,
"RORID": 1,
"RORIE": 1,
"RORTS": 1,
"RORTY": 1,
"ROSED": 1,
"ROSES": 1,
"ROSET": 1,
"ROSHI": 1,
"ROSIN": 1,
"ROSIT": 1,
"ROSTI": 1,
"ROSTS": 1,
"ROTAL": 1,
"ROTAN": 1,
"ROTAS": 1,
"ROTCH": 1,
"ROTED": 1,
"ROTES": 1,
"ROTIS": 1,
"ROTLS": 1,
"ROTON": 1,
"ROTOS": 1,
"ROTTE": 1,
"ROUEN": 1,
"ROUES": 1,
"ROULE": 1,
"ROULS": 1,
"ROUMS": 1,
"ROUPS": 1,
"ROUPY": 1,
"ROUST": 1,
"ROUTH": 1,
"ROUTS": 1,
"ROVED": 1,
"ROVEN": 1,
"ROVES": 1,
"ROWAN": 1,
"ROWED": 1,
"ROWEL": 1,
"ROWEN": 1,
"ROWIE": 1,
"ROWME": 1,
"ROWND": 1,
"ROWTH": 1,
"ROWTS": 1,
"ROYNE": 1,
"ROYST": 1,
"ROZET": 1,
"ROZIT": 1,
"RUANA": 1,
"RUBAI": 1,
"RUBBY": 1,
"RUBEL": 1,
"RUBES": 1,
"RUBIN": 1,
"RUBLE": 1,
"RUBLI": 1,
"RUBUS": 1,
"RUCHE": 1,
"RUCKS": 1,
"RUDAS": 1,
"RUDDS": 1,
"RUDES": 1,
"RUDIE": 1,
"RUDIS": 1,
"RUEDA": 1,
"RUERS": 1,
"RUFFE": 1,
"RUFFS": 1,
"RUGAE": 1,
"RUGAL": 1,
"RUGGY": 1,
"RUING": 1,
"RUINS": 1,
"RUKHS": 1,
"RULED": 1,
"RULES": 1,
"RUMAL": 1,
"RUMBO": 1,
"RUMEN": 1,
"RUMES": 1,
"RUMLY": 1,
"RUMMY": 1,
"RUMPO": 1,
"RUMPS": 1,
"RUMPY": 1,
"RUNCH": 1,
"RUNDS": 1,
"RUNED": 1,
"RUNES": 1,
"RUNGS": 1,
"RUNIC": 1,
"RUNNY": 1,
"RUNTS": 1,
"RUNTY": 1,
"RUPIA": 1,
"RURPS": 1,
"RURUS": 1,
"RUSAS": 1,
"RUSES": 1,
"RUSHY": 1,
"RUSKS": 1,
"RUSMA": 1,
"RUSSE": 1,
"RUSTS": 1,
"RUTHS": 1,
"RUTIN": 1,
"RUTTY": 1,
"RYALS": 1,
"RYBAT": 1,
"RYKED": 1,
"RYKES": 1,
"RYMME": 1,
"RYNDS": 1,
"RYOTS": 1,
"RYPER": 1,
"SAAGS": 1,
"SABAL": 1,
"SABED": 1,
"SABER": 1,
"SABES": 1,
"SABHA": 1,
"SABIN": 1,
"SABIR": 1,
"SABLE": 1,
"SABOT": 1,
"SABRA": 1,
"SABRE": 1,
"SACKS": 1,
"SACRA": 1,
"SADDO": 1,
"SADES": 1,
"SADHE": 1,
"SADHU": 1,
"SADIS": 1,
"SADOS": 1,
"SADZA": 1,
"SAFED": 1,
"SAFES": 1,
"SAGAS": 1,
"SAGER": 1,
"SAGES": 1,
"SAGGY": 1,
"SAGOS": 1,
"SAGUM": 1,
"SAHEB": 1,
"SAHIB": 1,
"SAICE": 1,
"SAICK": 1,
"SAICS": 1,
"SAIDS": 1,
"SAIGA": 1,
"SAILS": 1,
"SAIMS": 1,
"SAINE": 1,
"SAINS": 1,
"SAIRS": 1,
"SAIST": 1,
"SAITH": 1,
"SAJOU": 1,
"SAKAI": 1,
"SAKER": 1,
"SAKES": 1,
"SAKIA": 1,
"SAKIS": 1,
"SAKTI": 1,
"SALAL": 1,
"SALAT": 1,
"SALEP": 1,
"SALES": 1,
"SALET": 1,
"SALIC": 1,
"SALIX": 1,
"SALLE": 1,
"SALMI": 1,
"SALOL": 1,
"SALOP": 1,
"SALPA": 1,
"SALPS": 1,
"SALSE": 1,
"SALTO": 1,
"SALTS": 1,
"SALUE": 1,
"SALUT": 1,
"SAMAN": 1,
"SAMAS": 1,
"SAMBA": 1,
"SAMBO": 1,
"SAMEK": 1,
"SAMEL": 1,
"SAMEN": 1,
"SAMES": 1,
"SAMEY": 1,
"SAMFU": 1,
"SAMMY": 1,
"SAMPI": 1,
"SAMPS": 1,
"SANDS": 1,
"SANED": 1,
"SANES": 1,
"SANGA": 1,
"SANGH": 1,
"SANGO": 1,
"SANGS": 1,
"SANKO": 1,
"SANSA": 1,
"SANTO": 1,
"SANTS": 1,
"SAOLA": 1,
"SAPAN": 1,
"SAPID": 1,
"SAPOR": 1,
"SARAN": 1,
"SARDS": 1,
"SARED": 1,
"SAREE": 1,
"SARGE": 1,
"SARGO": 1,
"SARIN": 1,
"SARIS": 1,
"SARKS": 1,
"SARKY": 1,
"SAROD": 1,
"SAROS": 1,
"SARUS": 1,
"SASER": 1,
"SASIN": 1,
"SASSE": 1,
"SATAI": 1,
"SATAY": 1,
"SATED": 1,
"SATEM": 1,
"SATES": 1,
"SATIS": 1,
"SAUBA": 1,
"SAUCH": 1,
"SAUGH": 1,
"SAULS": 1,
"SAULT": 1,
"SAUNT": 1,
"SAURY": 1,
"SAUTS": 1,
"SAVED": 1,
"SAVER": 1,
"SAVES": 1,
"SAVEY": 1,
"SAVIN": 1,
"SAWAH": 1,
"SAWED": 1,
"SAWER": 1,
"SAXES": 1,
"SAYED": 1,
"SAYER": 1,
"SAYID": 1,
"SAYNE": 1,
"SAYON": 1,
"SAYST": 1,
"SAZES": 1,
"SCABS": 1,
"SCADS": 1,
"SCAFF": 1,
"SCAGS": 1,
"SCAIL": 1,
"SCALA": 1,
"SCALL": 1,
"SCAMS": 1,
"SCAND": 1,
"SCANS": 1,
"SCAPA": 1,
"SCAPE": 1,
"SCAPI": 1,
"SCARP": 1,
"SCARS": 1,
"SCART": 1,
"SCATH": 1,
"SCATS": 1,
"SCATT": 1,
"SCAUD": 1,
"SCAUP": 1,
"SCAUR": 1,
"SCAWS": 1,
"SCEAT": 1,
"SCENA": 1,
"SCEND": 1,
"SCHAV": 1,
"SCHMO": 1,
"SCHUL": 1,
"SCHWA": 1,
"SCLIM": 1,
"SCODY": 1,
"SCOGS": 1,
"SCOOG": 1,
"SCOOT": 1,
"SCOPA": 1,
"SCOPS": 1,
"SCOTS": 1,
"SCOUG": 1,
"SCOUP": 1,
"SCOWP": 1,
"SCOWS": 1,
"SCRAB": 1,
"SCRAE": 1,
"SCRAG": 1,
"SCRAN": 1,
"SCRAT": 1,
"SCRAW": 1,
"SCRAY": 1,
"SCRIM": 1,
"SCRIP": 1,
"SCROB": 1,
"SCROD": 1,
"SCROG": 1,
"SCROW": 1,
"SCUDI": 1,
"SCUDO": 1,
"SCUDS": 1,
"SCUFF": 1,
"SCUFT": 1,
"SCUGS": 1,
"SCULK": 1,
"SCULL": 1,
"SCULP": 1,
"SCULS": 1,
"SCUMS": 1,
"SCUPS": 1,
"SCURF": 1,
"SCURS": 1,
"SCUSE": 1,
"SCUTA": 1,
"SCUTE": 1,
"SCUTS": 1,
"SCUZZ": 1,
"SCYES": 1,
"SDAYN": 1,
"SDEIN": 1,
"SEALS": 1,
"SEAME": 1,
"SEAMS": 1,
"SEAMY": 1,
"SEANS": 1,
"SEARE": 1,
"SEARS": 1,
"SEASE": 1,
"SEATS": 1,
"SEAZE": 1,
"SEBUM": 1,
"SECCO": 1,
"SECHS": 1,
"SECTS": 1,
"SEDER": 1,
"SEDES": 1,
"SEDGE": 1,
"SEDGY": 1,
"SEDUM": 1,
"SEEDS": 1,
"SEEKS": 1,
"SEELD": 1,
"SEELS": 1,
"SEELY": 1,
"SEEMS": 1,
"SEEPS": 1,
"SEEPY": 1,
"SEERS": 1,
"SEFER": 1,
"SEGAR": 1,
"SEGNI": 1,
"SEGNO": 1,
"SEGOL": 1,
"SEGOS": 1,
"SEHRI": 1,
"SEIFS": 1,
"SEILS": 1,
"SEINE": 1,
"SEIRS": 1,
"SEISE": 1,
"SEISM": 1,
"SEITY": 1,
"SEIZA": 1,
"SEKOS": 1,
"SEKTS": 1,
"SELAH": 1,
"SELES": 1,
"SELFS": 1,
"SELLA": 1,
"SELLE": 1,
"SELLS": 1,
"SELVA": 1,
"SEMEE": 1,
"SEMES": 1,
"SEMIE": 1,
"SEMIS": 1,
"SENAS": 1,
"SENDS": 1,
"SENES": 1,
"SENGI": 1,
"SENNA": 1,
"SENOR": 1,
"SENSA": 1,
"SENSI": 1,
"SENTE": 1,
"SENTI": 1,
"SENTS": 1,
"SENVY": 1,
"SENZA": 1,
"SEPAD": 1,
"SEPAL": 1,
"SEPIC": 1,
"SEPOY": 1,
"SEPTA": 1,
"SEPTS": 1,
"SERAC": 1,
"SERAI": 1,
"SERAL": 1,
"SERED": 1,
"SERER": 1,
"SERES": 1,
"SERFS": 1,
"SERGE": 1,
"SERIC": 1,
"SERIN": 1,
"SERKS": 1,
"SERON": 1,
"SEROW": 1,
"SERRA": 1,
"SERRE": 1,
"SERRS": 1,
"SERRY": 1,
"SERVO": 1,
"SESEY": 1,
"SESSA": 1,
"SETAE": 1,
"SETAL": 1,
"SETON": 1,
"SETTS": 1,
"SEWAN": 1,
"SEWAR": 1,
"SEWED": 1,
"SEWEL": 1,
"SEWEN": 1,
"SEWIN": 1,
"SEXED": 1,
"SEXER": 1,
"SEXES": 1,
"SEXTO": 1,
"SEXTS": 1,
"SEYEN": 1,
"SHADS": 1,
"SHAGS": 1,
"SHAHS": 1,
"SHAKO": 1,
"SHAKT": 1,
"SHALM": 1,
"SHALY": 1,
"SHAMA": 1,
"SHAMS": 1,
"SHAND": 1,
"SHANS": 1,
"SHAPS": 1,
"SHARN": 1,
"SHASH": 1,
"SHAUL": 1,
"SHAWM": 1,
"SHAWN": 1,
"SHAWS": 1,
"SHAYA": 1,
"SHAYS": 1,
"SHCHI": 1,
"SHEAF": 1,
"SHEAL": 1,
"SHEAS": 1,
"SHEDS": 1,
"SHEEL": 1,
"SHEND": 1,
"SHENT": 1,
"SHEOL": 1,
"SHERD": 1,
"SHERE": 1,
"SHERO": 1,
"SHETS": 1,
"SHEVA": 1,
"SHEWN": 1,
"SHEWS": 1,
"SHIAI": 1,
"SHIEL": 1,
"SHIER": 1,
"SHIES": 1,
"SHILL": 1,
"SHILY": 1,
"SHIMS": 1,
"SHINS": 1,
"SHIPS": 1,
"SHIRR": 1,
"SHIRS": 1,
"SHISH": 1,
"SHISO": 1,
"SHIST": 1,
"SHITE": 1,
"SHITS": 1,
"SHIUR": 1,
"SHIVA": 1,
"SHIVE": 1,
"SHIVS": 1,
"SHLEP": 1,
"SHLUB": 1,
"SHMEK": 1,
"SHMOE": 1,
"SHOAT": 1,
"SHOED": 1,
"SHOER": 1,
"SHOES": 1,
"SHOGI": 1,
"SHOGS": 1,
"SHOJI": 1,
"SHOJO": 1,
"SHOLA": 1,
"SHOOL": 1,
"SHOON": 1,
"SHOOS": 1,
"SHOPE": 1,
"SHOPS": 1,
"SHORL": 1,
"SHOTE": 1,
"SHOTS": 1,
"SHOTT": 1,
"SHOWD": 1,
"SHOWS": 1,
"SHOYU": 1,
"SHRED": 1,
"SHRIS": 1,
"SHROW": 1,
"SHTIK": 1,
"SHTUM": 1,
"SHTUP": 1,
"SHULE": 1,
"SHULN": 1,
"SHULS": 1,
"SHUNS": 1,
"SHURA": 1,
"SHUTE": 1,
"SHUTS": 1,
"SHWAS": 1,
"SHYER": 1,
"SIALS": 1,
"SIBBS": 1,
"SIBYL": 1,
"SICES": 1,
"SICHT": 1,
"SICKO": 1,
"SICKS": 1,
"SICKY": 1,
"SIDAS": 1,
"SIDED": 1,
"SIDER": 1,
"SIDES": 1,
"SIDHA": 1,
"SIDHE": 1,
"SIDLE": 1,
"SIELD": 1,
"SIENS": 1,
"SIENT": 1,
"SIETH": 1,
"SIEUR": 1,
"SIFTS": 1,
"SIGHS": 1,
"SIGIL": 1,
"SIGLA": 1,
"SIGNA": 1,
"SIGNS": 1,
"SIJOS": 1,
"SIKAS": 1,
"SIKER": 1,
"SIKES": 1,
"SILDS": 1,
"SILED": 1,
"SILEN": 1,
"SILER": 1,
"SILES": 1,
"SILEX": 1,
"SILKS": 1,
"SILLS": 1,
"SILOS": 1,
"SILTS": 1,
"SILTY": 1,
"SILVA": 1,
"SIMAR": 1,
"SIMAS": 1,
"SIMBA": 1,
"SIMIS": 1,
"SIMPS": 1,
"SIMUL": 1,
"SINDS": 1,
"SINED": 1,
"SINES": 1,
"SINGS": 1,
"SINHS": 1,
"SINKS": 1,
"SINKY": 1,
"SINUS": 1,
"SIPED": 1,
"SIPES": 1,
"SIPPY": 1,
"SIRED": 1,
"SIREE": 1,
"SIRES": 1,
"SIRIH": 1,
"SIRIS": 1,
"SIROC": 1,
"SIRRA": 1,
"SIRUP": 1,
"SISAL": 1,
"SISES": 1,
"SISTA": 1,
"SISTS": 1,
"SITAR": 1,
"SITED": 1,
"SITES": 1,
"SITHE": 1,
"SITKA": 1,
"SITUP": 1,
"SITUS": 1,
"SIVER": 1,
"SIXER": 1,
"SIXES": 1,
"SIXMO": 1,
"SIXTE": 1,
"SIZAR": 1,
"SIZED": 1,
"SIZEL": 1,
"SIZER": 1,
"SIZES": 1,
"SKAGS": 1,
"SKAIL": 1,
"SKALD": 1,
"SKANK": 1,
"SKART": 1,
"SKATS": 1,
"SKATT": 1,
"SKAWS": 1,
"SKEAN": 1,
"SKEAR": 1,
"SKEDS": 1,
"SKEED": 1,
"SKEEF": 1,
"SKEEN": 1,
"SKEER": 1,
"SKEES": 1,
"SKEET": 1,
"SKEGG": 1,
"SKEGS": 1,
"SKEIN": 1,
"SKELF": 1,
"SKELL": 1,
"SKELM": 1,
"SKELP": 1,
"SKENE": 1,
"SKENS": 1,
"SKEOS": 1,
"SKEPS": 1,
"SKERS": 1,
"SKETS": 1,
"SKEWS": 1,
"SKIDS": 1,
"SKIED": 1,
"SKIES": 1,
"SKIEY": 1,
"SKIMO": 1,
"SKIMS": 1,
"SKINK": 1,
"SKINS": 1,
"SKINT": 1,
"SKIOS": 1,
"SKIPS": 1,
"SKIRL": 1,
"SKIRR": 1,
"SKITE": 1,
"SKITS": 1,
"SKIVE": 1,
"SKIVY": 1,
"SKLIM": 1,
"SKOAL": 1,
"SKODY": 1,
"SKOFF": 1,
"SKOGS": 1,
"SKOLS": 1,
"SKOOL": 1,
"SKORT": 1,
"SKOSH": 1,
"SKRAN": 1,
"SKRIK": 1,
"SKUAS": 1,
"SKUGS": 1,
"SKYED": 1,
"SKYER": 1,
"SKYEY": 1,
"SKYFS": 1,
"SKYRE": 1,
"SKYRS": 1,
"SKYTE": 1,
"SLABS": 1,
"SLADE": 1,
"SLAES": 1,
"SLAGS": 1,
"SLAID": 1,
"SLAKE": 1,
"SLAMS": 1,
"SLANE": 1,
"SLANK": 1,
"SLAPS": 1,
"SLART": 1,
"SLATS": 1,
"SLATY": 1,
"SLAWS": 1,
"SLAYS": 1,
"SLEBS": 1,
"SLEDS": 1,
"SLEER": 1,
"SLEWS": 1,
"SLEYS": 1,
"SLIER": 1,
"SLILY": 1,
"SLIMS": 1,
"SLIPE": 1,
"SLIPS": 1,
"SLIPT": 1,
"SLISH": 1,
"SLITS": 1,
"SLIVE": 1,
"SLOAN": 1,
"SLOBS": 1,
"SLOES": 1,
"SLOGS": 1,
"SLOID": 1,
"SLOJD": 1,
"SLOMO": 1,
"SLOOM": 1,
"SLOOT": 1,
"SLOPS": 1,
"SLOPY": 1,
"SLORM": 1,
"SLOTS": 1,
"SLOVE": 1,
"SLOWS": 1,
"SLOYD": 1,
"SLUBB": 1,
"SLUBS": 1,
"SLUED": 1,
"SLUES": 1,
"SLUFF": 1,
"SLUGS": 1,
"SLUIT": 1,
"SLUMS": 1,
"SLURB": 1,
"SLURS": 1,
"SLUSE": 1,
"SLUTS": 1,
"SLYER": 1,
"SLYPE": 1,
"SMAAK": 1,
"SMAIK": 1,
"SMALM": 1,
"SMALT": 1,
"SMARM": 1,
"SMAZE": 1,
"SMEEK": 1,
"SMEES": 1,
"SMEIK": 1,
"SMEKE": 1,
"SMERK": 1,
"SMEWS": 1,
"SMIRR": 1,
"SMIRS": 1,
"SMITS": 1,
"SMOGS": 1,
"SMOKO": 1,
"SMOLT": 1,
"SMOOR": 1,
"SMOOT": 1,
"SMORE": 1,
"SMORG": 1,
"SMOUT": 1,
"SMOWT": 1,
"SMUGS": 1,
"SMURS": 1,
"SMUSH": 1,
"SMUTS": 1,
"SNABS": 1,
"SNAFU": 1,
"SNAGS": 1,
"SNAPS": 1,
"SNARF": 1,
"SNARK": 1,
"SNARS": 1,
"SNARY": 1,
"SNASH": 1,
"SNATH": 1,
"SNAWS": 1,
"SNEAD": 1,
"SNEAP": 1,
"SNEBS": 1,
"SNECK": 1,
"SNEDS": 1,
"SNEED": 1,
"SNEES": 1,
"SNELL": 1,
"SNIBS": 1,
"SNICK": 1,
"SNIES": 1,
"SNIFT": 1,
"SNIGS": 1,
"SNIPS": 1,
"SNIPY": 1,
"SNIRT": 1,
"SNITS": 1,
"SNOBS": 1,
"SNODS": 1,
"SNOEK": 1,
"SNOEP": 1,
"SNOGS": 1,
"SNOKE": 1,
"SNOOD": 1,
"SNOOK": 1,
"SNOOL": 1,
"SNOOT": 1,
"SNOTS": 1,
"SNOWK": 1,
"SNOWS": 1,
"SNUBS": 1,
"SNUGS": 1,
"SNUSH": 1,
"SNYES": 1,
"SOAKS": 1,
"SOAPS": 1,
"SOARE": 1,
"SOARS": 1,
"SOAVE": 1,
"SOBAS": 1,
"SOCAS": 1,
"SOCES": 1,
"SOCKO": 1,
"SOCKS": 1,
"SOCLE": 1,
"SODAS": 1,
"SODDY": 1,
"SODIC": 1,
"SODOM": 1,
"SOFAR": 1,
"SOFAS": 1,
"SOFTA": 1,
"SOFTS": 1,
"SOFTY": 1,
"SOGER": 1,
"SOHUR": 1,
"SOILS": 1,
"SOILY": 1,
"SOJAS": 1,
"SOJUS": 1,
"SOKAH": 1,
"SOKEN": 1,
"SOKES": 1,
"SOKOL": 1,
"SOLAH": 1,
"SOLAN": 1,
"SOLAS": 1,
"SOLDE": 1,
"SOLDI": 1,
"SOLDO": 1,
"SOLDS": 1,
"SOLED": 1,
"SOLEI": 1,
"SOLER": 1,
"SOLES": 1,
"SOLON": 1,
"SOLOS": 1,
"SOLUM": 1,
"SOLUS": 1,
"SOMAN": 1,
"SOMAS": 1,
"SONCE": 1,
"SONDE": 1,
"SONES": 1,
"SONGS": 1,
"SONLY": 1,
"SONNE": 1,
"SONNY": 1,
"SONSE": 1,
"SONSY": 1,
"SOOEY": 1,
"SOOKS": 1,
"SOOKY": 1,
"SOOLE": 1,
"SOOLS": 1,
"SOOMS": 1,
"SOOPS": 1,
"SOOTE": 1,
"SOOTS": 1,
"SOPHS": 1,
"SOPHY": 1,
"SOPOR": 1,
"SOPPY": 1,
"SOPRA": 1,
"SORAL": 1,
"SORAS": 1,
"SORBO": 1,
"SORBS": 1,
"SORDA": 1,
"SORDO": 1,
"SORDS": 1,
"SORED": 1,
"SOREE": 1,
"SOREL": 1,
"SORER": 1,
"SORES": 1,
"SOREX": 1,
"SORGO": 1,
"SORNS": 1,
"SORRA": 1,
"SORTA": 1,
"SORTS": 1,
"SORUS": 1,
"SOTHS": 1,
"SOTOL": 1,
"SOUCE": 1,
"SOUCT": 1,
"SOUGH": 1,
"SOUKS": 1,
"SOULS": 1,
"SOUMS": 1,
"SOUPS": 1,
"SOUPY": 1,
"SOURS": 1,
"SOUSE": 1,
"SOUTS": 1,
"SOWAR": 1,
"SOWCE": 1,
"SOWED": 1,
"SOWFF": 1,
"SOWFS": 1,
"SOWLE": 1,
"SOWLS": 1,
"SOWMS": 1,
"SOWND": 1,
"SOWNE": 1,
"SOWPS": 1,
"SOWSE": 1,
"SOWTH": 1,
"SOYAS": 1,
"SOYLE": 1,
"SOYUZ": 1,
"SOZIN": 1,
"SPACY": 1,
"SPADO": 1,
"SPAED": 1,
"SPAER": 1,
"SPAES": 1,
"SPAGS": 1,
"SPAHI": 1,
"SPAIL": 1,
"SPAIN": 1,
"SPAIT": 1,
"SPAKE": 1,
"SPALD": 1,
"SPALE": 1,
"SPALL": 1,
"SPALT": 1,
"SPAMS": 1,
"SPANE": 1,
"SPANG": 1,
"SPANS": 1,
"SPARD": 1,
"SPARS": 1,
"SPART": 1,
"SPATE": 1,
"SPATS": 1,
"SPAUL": 1,
"SPAWL": 1,
"SPAWS": 1,
"SPAYD": 1,
"SPAYS": 1,
"SPAZA": 1,
"SPAZZ": 1,
"SPEAL": 1,
"SPEAN": 1,
"SPEAT": 1,
"SPECS": 1,
"SPECT": 1,
"SPEEL": 1,
"SPEER": 1,
"SPEIL": 1,
"SPEIR": 1,
"SPEKS": 1,
"SPELD": 1,
"SPELK": 1,
"SPEOS": 1,
"SPETS": 1,
"SPEUG": 1,
"SPEWS": 1,
"SPEWY": 1,
"SPIAL": 1,
"SPICA": 1,
"SPICK": 1,
"SPICS": 1,
"SPIDE": 1,
"SPIER": 1,
"SPIES": 1,
"SPIFF": 1,
"SPIFS": 1,
"SPIKS": 1,
"SPILE": 1,
"SPIMS": 1,
"SPINA": 1,
"SPINK": 1,
"SPINS": 1,
"SPIRT": 1,
"SPIRY": 1,
"SPITS": 1,
"SPITZ": 1,
"SPIVS": 1,
"SPLAY": 1,
"SPLOG": 1,
"SPODE": 1,
"SPODS": 1,
"SPOOM": 1,
"SPOOR": 1,
"SPOOT": 1,
"SPORK": 1,
"SPOSH": 1,
"SPOTS": 1,
"SPRAD": 1,
"SPRAG": 1,
"SPRAT": 1,
"SPRED": 1,
"SPREW": 1,
"SPRIT": 1,
"SPROD": 1,
"SPROG": 1,
"SPRUE": 1,
"SPRUG": 1,
"SPUDS": 1,
"SPUED": 1,
"SPUER": 1,
"SPUES": 1,
"SPUGS": 1,
"SPULE": 1,
"SPUME": 1,
"SPUMY": 1,
"SPURS": 1,
"SPUTA": 1,
"SPYAL": 1,
"SPYRE": 1,
"SQUAB": 1,
"SQUAW": 1,
"SQUEG": 1,
"SQUID": 1,
"SQUIT": 1,
"SQUIZ": 1,
"STABS": 1,
"STADE": 1,
"STAGS": 1,
"STAGY": 1,
"STAIG": 1,
"STANE": 1,
"STANG": 1,
"STAPH": 1,
"STAPS": 1,
"STARN": 1,
"STARR": 1,
"STARS": 1,
"STATS": 1,
"STAUN": 1,
"STAWS": 1,
"STAYS": 1,
"STEAN": 1,
"STEAR": 1,
"STEDD": 1,
"STEDE": 1,
"STEDS": 1,
"STEEK": 1,
"STEEM": 1,
"STEEN": 1,
"STEIL": 1,
"STELA": 1,
"STELE": 1,
"STELL": 1,
"STEME": 1,
"STEMS": 1,
"STEND": 1,
"STENO": 1,
"STENS": 1,
"STENT": 1,
"STEPS": 1,
"STEPT": 1,
"STERE": 1,
"STETS": 1,
"STEWS": 1,
"STEWY": 1,
"STEYS": 1,
"STICH": 1,
"STIED": 1,
"STIES": 1,
"STILB": 1,
"STILE": 1,
"STIME": 1,
"STIMS": 1,
"STIMY": 1,
"STIPA": 1,
"STIPE": 1,
"STIRE": 1,
"STIRK": 1,
"STIRP": 1,
"STIRS": 1,
"STIVE": 1,
"STIVY": 1,
"STOAE": 1,
"STOAI": 1,
"STOAS": 1,
"STOAT": 1,
"STOBS": 1,
"STOEP": 1,
"STOGY": 1,
"STOIT": 1,
"STOLN": 1,
"STOMA": 1,
"STOND": 1,
"STONG": 1,
"STONK": 1,
"STONN": 1,
"STOOK": 1,
"STOOR": 1,
"STOPE": 1,
"STOPS": 1,
"STOPT": 1,
"STOSS": 1,
"STOTS": 1,
"STOTT": 1,
"STOUN": 1,
"STOUP": 1,
"STOUR": 1,
"STOWN": 1,
"STOWP": 1,
"STOWS": 1,
"STRAD": 1,
"STRAE": 1,
"STRAG": 1,
"STRAK": 1,
"STREP": 1,
"STREW": 1,
"STRIA": 1,
"STRIG": 1,
"STRIM": 1,
"STROP": 1,
"STROW": 1,
"STROY": 1,
"STRUM": 1,
"STUBS": 1,
"STUDE": 1,
"STUDS": 1,
"STULL": 1,
"STULM": 1,
"STUMM": 1,
"STUMS": 1,
"STUNS": 1,
"STUPA": 1,
"STUPE": 1,
"STURE": 1,
"STURT": 1,
"STYED": 1,
"STYES": 1,
"STYLI": 1,
"STYLO": 1,
"STYME": 1,
"STYMY": 1,
"STYRE": 1,
"STYTE": 1,
"SUBAH": 1,
"SUBAS": 1,
"SUBBY": 1,
"SUBER": 1,
"SUBHA": 1,
"SUCCI": 1,
"SUCKS": 1,
"SUCKY": 1,
"SUCRE": 1,
"SUDDS": 1,
"SUDOR": 1,
"SUDSY": 1,
"SUEDE": 1,
"SUENT": 1,
"SUERS": 1,
"SUETE": 1,
"SUETS": 1,
"SUETY": 1,
"SUGAN": 1,
"SUGHS": 1,
"SUGOS": 1,
"SUHUR": 1,
"SUIDS": 1,
"SUINT": 1,
"SUITS": 1,
"SUJEE": 1,
"SUKHS": 1,
"SUKUK": 1,
"SULCI": 1,
"SULFA": 1,
"SULFO": 1,
"SULKS": 1,
"SULPH": 1,
"SULUS": 1,
"SUMIS": 1,
"SUMMA": 1,
"SUMOS": 1,
"SUMPH": 1,
"SUMPS": 1,
"SUNIS": 1,
"SUNKS": 1,
"SUNNA": 1,
"SUNNS": 1,
"SUNUP": 1,
"SUPES": 1,
"SUPRA": 1,
"SURAH": 1,
"SURAL": 1,
"SURAS": 1,
"SURAT": 1,
"SURDS": 1,
"SURED": 1,
"SURES": 1,
"SURFS": 1,
"SURFY": 1,
"SURGY": 1,
"SURRA": 1,
"SUSED": 1,
"SUSES": 1,
"SUSUS": 1,
"SUTOR": 1,
"SUTRA": 1,
"SUTTA": 1,
"SWABS": 1,
"SWACK": 1,
"SWADS": 1,
"SWAGE": 1,
"SWAGS": 1,
"SWAIL": 1,
"SWAIN": 1,
"SWALE": 1,
"SWALY": 1,
"SWAMY": 1,
"SWANG": 1,
"SWANK": 1,
"SWANS": 1,
"SWAPS": 1,
"SWAPT": 1,
"SWARD": 1,
"SWARE": 1,
"SWARF": 1,
"SWART": 1,
"SWATS": 1,
"SWAYL": 1,
"SWAYS": 1,
"SWEAL": 1,
"SWEDE": 1,
"SWEED": 1,
"SWEEL": 1,
"SWEER": 1,
"SWEES": 1,
"SWEIR": 1,
"SWELT": 1,
"SWERF": 1,
"SWEYS": 1,
"SWIES": 1,
"SWIGS": 1,
"SWILE": 1,
"SWIMS": 1,
"SWINK": 1,
"SWIPE": 1,
"SWIRE": 1,
"SWISS": 1,
"SWITH": 1,
"SWITS": 1,
"SWIVE": 1,
"SWIZZ": 1,
"SWOBS": 1,
"SWOLE": 1,
"SWOLN": 1,
"SWOPS": 1,
"SWOPT": 1,
"SWOTS": 1,
"SWOUN": 1,
"SYBBE": 1,
"SYBIL": 1,
"SYBOE": 1,
"SYBOW": 1,
"SYCEE": 1,
"SYCES": 1,
"SYCON": 1,
"SYENS": 1,
"SYKER": 1,
"SYKES": 1,
"SYLIS": 1,
"SYLPH": 1,
"SYLVA": 1,
"SYMAR": 1,
"SYNCH": 1,
"SYNCS": 1,
"SYNDS": 1,
"SYNED": 1,
"SYNES": 1,
"SYNTH": 1,
"SYPED": 1,
"SYPES": 1,
"SYPHS": 1,
"SYRAH": 1,
"SYREN": 1,
"SYSOP": 1,
"SYTHE": 1,
"SYVER": 1,
"TAALS": 1,
"TAATA": 1,
"TABER": 1,
"TABES": 1,
"TABID": 1,
"TABIS": 1,
"TABLA": 1,
"TABOR": 1,
"TABUN": 1,
"TABUS": 1,
"TACAN": 1,
"TACES": 1,
"TACET": 1,
"TACHE": 1,
"TACHO": 1,
"TACHS": 1,
"TACKS": 1,
"TACOS": 1,
"TACTS": 1,
"TAELS": 1,
"TAFIA": 1,
"TAGGY": 1,
"TAGMA": 1,
"TAHAS": 1,
"TAHRS": 1,
"TAIGA": 1,
"TAIGS": 1,
"TAIKO": 1,
"TAILS": 1,
"TAINS": 1,
"TAIRA": 1,
"TAISH": 1,
"TAITS": 1,
"TAJES": 1,
"TAKAS": 1,
"TAKES": 1,
"TAKHI": 1,
"TAKIN": 1,
"TAKIS": 1,
"TAKKY": 1,
"TALAK": 1,
"TALAQ": 1,
"TALAR": 1,
"TALAS": 1,
"TALCS": 1,
"TALCY": 1,
"TALEA": 1,
"TALER": 1,
"TALES": 1,
"TALKS": 1,
"TALKY": 1,
"TALLS": 1,
"TALMA": 1,
"TALPA": 1,
"TALUK": 1,
"TALUS": 1,
"TAMAL": 1,
"TAMED": 1,
"TAMES": 1,
"TAMIN": 1,
"TAMIS": 1,
"TAMMY": 1,
"TAMPS": 1,
"TANAS": 1,
"TANGA": 1,
"TANGI": 1,
"TANGS": 1,
"TANHS": 1,
"TANKA": 1,
"TANKS": 1,
"TANKY": 1,
"TANNA": 1,
"TANSY": 1,
"TANTI": 1,
"TANTO": 1,
"TANTY": 1,
"TAPAS": 1,
"TAPED": 1,
"TAPEN": 1,
"TAPES": 1,
"TAPET": 1,
"TAPIS": 1,
"TAPPA": 1,
"TAPUS": 1,
"TARAS": 1,
"TARDO": 1,
"TARED": 1,
"TARES": 1,
"TARGA": 1,
"TARGE": 1,
"TARNS": 1,
"TAROC": 1,
"TAROK": 1,
"TAROS": 1,
"TARPS": 1,
"TARRE": 1,
"TARRY": 1,
"TARSI": 1,
"TARTS": 1,
"TARTY": 1,
"TASAR": 1,
"TASED": 1,
"TASER": 1,
"TASES": 1,
"TASKS": 1,
"TASSA": 1,
"TASSE": 1,
"TASSO": 1,
"TATAR": 1,
"TATER": 1,
"TATES": 1,
"TATHS": 1,
"TATIE": 1,
"TATOU": 1,
"TATTS": 1,
"TATUS": 1,
"TAUBE": 1,
"TAULD": 1,
"TAUON": 1,
"TAUPE": 1,
"TAUTS": 1,
"TAVAH": 1,
"TAVAS": 1,
"TAVER": 1,
"TAWAI": 1,
"TAWAS": 1,
"TAWED": 1,
"TAWER": 1,
"TAWIE": 1,
"TAWSE": 1,
"TAWTS": 1,
"TAXED": 1,
"TAXER": 1,
"TAXES": 1,
"TAXIS": 1,
"TAXOL": 1,
"TAXON": 1,
"TAXOR": 1,
"TAXUS": 1,
"TAYRA": 1,
"TAZZA": 1,
"TAZZE": 1,
"TEADE": 1,
"TEADS": 1,
"TEAED": 1,
"TEAKS": 1,
"TEALS": 1,
"TEAMS": 1,
"TEARS": 1,
"TEATS": 1,
"TEAZE": 1,
"TECHS": 1,
"TECHY": 1,
"TECTA": 1,
"TEELS": 1,
"TEEMS": 1,
"TEEND": 1,
"TEENE": 1,
"TEENS": 1,
"TEENY": 1,
"TEERS": 1,
"TEFFS": 1,
"TEGGS": 1,
"TEGUA": 1,
"TEGUS": 1,
"TEHRS": 1,
"TEIID": 1,
"TEILS": 1,
"TEIND": 1,
"TEINS": 1,
"TELAE": 1,
"TELCO": 1,
"TELES": 1,
"TELEX": 1,
"TELIA": 1,
"TELIC": 1,
"TELLS": 1,
"TELLY": 1,
"TELOI": 1,
"TELOS": 1,
"TEMED": 1,
"TEMES": 1,
"TEMPI": 1,
"TEMPS": 1,
"TEMPT": 1,
"TEMSE": 1,
"TENCH": 1,
"TENDS": 1,
"TENDU": 1,
"TENES": 1,
"TENGE": 1,
"TENIA": 1,
"TENNE": 1,
"TENNO": 1,
"TENNY": 1,
"TENON": 1,
"TENTS": 1,
"TENTY": 1,
"TENUE": 1,
"TEPAL": 1,
"TEPAS": 1,
"TEPOY": 1,
"TERAI": 1,
"TERAS": 1,
"TERCE": 1,
"TEREK": 1,
"TERES": 1,
"TERFE": 1,
"TERFS": 1,
"TERGA": 1,
"TERMS": 1,
"TERNE": 1,
"TERNS": 1,
"TERRY": 1,
"TERTS": 1,
"TESLA": 1,
"TESTA": 1,
"TESTE": 1,
"TESTS": 1,
"TETES": 1,
"TETHS": 1,
"TETRA": 1,
"TETRI": 1,
"TEUCH": 1,
"TEUGH": 1,
"TEWED": 1,
"TEWEL": 1,
"TEWIT": 1,
"TEXAS": 1,
"TEXES": 1,
"TEXTS": 1,
"THACK": 1,
"THAGI": 1,
"THAIM": 1,
"THALE": 1,
"THALI": 1,
"THANA": 1,
"THANE": 1,
"THANG": 1,
"THANS": 1,
"THANX": 1,
"THARM": 1,
"THARS": 1,
"THAWS": 1,
"THAWY": 1,
"THEBE": 1,
"THECA": 1,
"THEED": 1,
"THEEK": 1,
"THEES": 1,
"THEGN": 1,
"THEIC": 1,
"THEIN": 1,
"THELF": 1,
"THEMA": 1,
"THENS": 1,
"THEOW": 1,
"THERM": 1,
"THESP": 1,
"THETE": 1,
"THEWS": 1,
"THEWY": 1,
"THIGS": 1,
"THILK": 1,
"THILL": 1,
"THINE": 1,
"THINS": 1,
"THIOL": 1,
"THIRL": 1,
"THOFT": 1,
"THOLE": 1,
"THOLI": 1,
"THORO": 1,
"THORP": 1,
"THOUS": 1,
"THOWL": 1,
"THRAE": 1,
"THRAW": 1,
"THRID": 1,
"THRIP": 1,
"THROE": 1,
"THUDS": 1,
"THUGS": 1,
"THUJA": 1,
"THUNK": 1,
"THURL": 1,
"THUYA": 1,
"THYMI": 1,
"THYMY": 1,
"TIANS": 1,
"TIARS": 1,
"TICAL": 1,
"TICCA": 1,
"TICED": 1,
"TICES": 1,
"TICHY": 1,
"TICKS": 1,
"TICKY": 1,
"TIDDY": 1,
"TIDED": 1,
"TIDES": 1,
"TIERS": 1,
"TIFFS": 1,
"TIFOS": 1,
"TIFTS": 1,
"TIGES": 1,
"TIGON": 1,
"TIKAS": 1,
"TIKES": 1,
"TIKIS": 1,
"TIKKA": 1,
"TILAK": 1,
"TILED": 1,
"TILER": 1,
"TILES": 1,
"TILLS": 1,
"TILLY": 1,
"TILTH": 1,
"TILTS": 1,
"TIMBO": 1,
"TIMED": 1,
"TIMES": 1,
"TIMON": 1,
"TIMPS": 1,
"TINAS": 1,
"TINCT": 1,
"TINDS": 1,
"TINEA": 1,
"TINED": 1,
"TINES": 1,
"TINGE": 1,
"TINGS": 1,
"TINKS": 1,
"TINNY": 1,
"TINTS": 1,
"TINTY": 1,
"TIPIS": 1,
"TIPPY": 1,
"TIRED": 1,
"TIRES": 1,
"TIRLS": 1,
"TIROS": 1,
"TIRRS": 1,
"TITCH": 1,
"TITER": 1,
"TITIS": 1,
"TITRE": 1,
"TITTY": 1,
"TITUP": 1,
"TIYIN": 1,
"TIYNS": 1,
"TIZES": 1,
"TIZZY": 1,
"TOADS": 1,
"TOADY": 1,
"TOAZE": 1,
"TOCKS": 1,
"TOCKY": 1,
"TOCOS": 1,
"TODDE": 1,
"TOEAS": 1,
"TOFFS": 1,
"TOFFY": 1,
"TOFTS": 1,
"TOFUS": 1,
"TOGAE": 1,
"TOGAS": 1,
"TOGED": 1,
"TOGES": 1,
"TOGUE": 1,
"TOHOS": 1,
"TOILE": 1,
"TOILS": 1,
"TOING": 1,
"TOISE": 1,
"TOITS": 1,
"TOKAY": 1,
"TOKED": 1,
"TOKER": 1,
"TOKES": 1,
"TOKOS": 1,
"TOLAN": 1,
"TOLAR": 1,
"TOLAS": 1,
"TOLED": 1,
"TOLES": 1,
"TOLLS": 1,
"TOLLY": 1,
"TOLTS": 1,
"TOLUS": 1,
"TOLYL": 1,
"TOMAN": 1,
"TOMBS": 1,
"TOMES": 1,
"TOMIA": 1,
"TOMMY": 1,
"TOMOS": 1,
"TONDI": 1,
"TONDO": 1,
"TONED": 1,
"TONER": 1,
"TONES": 1,
"TONEY": 1,
"TONGS": 1,
"TONKA": 1,
"TONKS": 1,
"TONNE": 1,
"TONUS": 1,
"TOOLS": 1,
"TOOMS": 1,
"TOONS": 1,
"TOOTS": 1,
"TOPED": 1,
"TOPEE": 1,
"TOPEK": 1,
"TOPER": 1,
"TOPES": 1,
"TOPHE": 1,
"TOPHI": 1,
"TOPHS": 1,
"TOPIS": 1,
"TOPOI": 1,
"TOPOS": 1,
"TOPPY": 1,
"TOQUE": 1,
"TORAH": 1,
"TORAN": 1,
"TORAS": 1,
"TORCS": 1,
"TORES": 1,
"TORIC": 1,
"TORII": 1,
"TOROS": 1,
"TOROT": 1,
"TORRS": 1,
"TORSE": 1,
"TORSI": 1,
"TORSK": 1,
"TORTA": 1,
"TORTE": 1,
"TORTS": 1,
"TOSAS": 1,
"TOSED": 1,
"TOSES": 1,
"TOSHY": 1,
"TOSSY": 1,
"TOTED": 1,
"TOTER": 1,
"TOTES": 1,
"TOTTY": 1,
"TOUKS": 1,
"TOUNS": 1,
"TOURS": 1,
"TOUSE": 1,
"TOUSY": 1,
"TOUTS": 1,
"TOUZE": 1,
"TOUZY": 1,
"TOWED": 1,
"TOWIE": 1,
"TOWNS": 1,
"TOWNY": 1,
"TOWSE": 1,
"TOWSY": 1,
"TOWTS": 1,
"TOWZE": 1,
"TOWZY": 1,
"TOYED": 1,
"TOYER": 1,
"TOYON": 1,
"TOYOS": 1,
"TOZED": 1,
"TOZES": 1,
"TOZIE": 1,
"TRABS": 1,
"TRADS": 1,
"TRAGI": 1,
"TRAIK": 1,
"TRAMS": 1,
"TRANK": 1,
"TRANQ": 1,
"TRANS": 1,
"TRANT": 1,
"TRAPE": 1,
"TRAPS": 1,
"TRAPT": 1,
"TRASS": 1,
"TRATS": 1,
"TRATT": 1,
"TRAVE": 1,
"TRAYF": 1,
"TRAYS": 1,
"TRECK": 1,
"TREED": 1,
"TREEN": 1,
"TREES": 1,
"TREFA": 1,
"TREIF": 1,
"TREKS": 1,
"TREMA": 1,
"TREMS": 1,
"TRESS": 1,
"TREST": 1,
"TRETS": 1,
"TREWS": 1,
"TREYF": 1,
"TREYS": 1,
"TRIAC": 1,
"TRIDE": 1,
"TRIER": 1,
"TRIES": 1,
"TRIFF": 1,
"TRIGO": 1,
"TRIGS": 1,
"TRIKE": 1,
"TRILD": 1,
"TRILL": 1,
"TRIMS": 1,
"TRINE": 1,
"TRINS": 1,
"TRIOL": 1,
"TRIOR": 1,
"TRIOS": 1,
"TRIPS": 1,
"TRIPY": 1,
"TRIST": 1,
"TROAD": 1,
"TROAK": 1,
"TROAT": 1,
"TROCK": 1,
"TRODE": 1,
"TRODS": 1,
"TROGS": 1,
"TROIS": 1,
"TROKE": 1,
"TROMP": 1,
"TRONA": 1,
"TRONC": 1,
"TRONE": 1,
"TRONK": 1,
"TRONS": 1,
"TROOZ": 1,
"TROTH": 1,
"TROTS": 1,
"TROWS": 1,
"TROYS": 1,
"TRUED": 1,
"TRUES": 1,
"TRUGO": 1,
"TRUGS": 1,
"TRULL": 1,
"TRYER": 1,
"TRYKE": 1,
"TRYMA": 1,
"TRYPS": 1,
"TSADE": 1,
"TSADI": 1,
"TSARS": 1,
"TSKED": 1,
"TSUBA": 1,
"TSUBO": 1,
"TUANS": 1,
"TUART": 1,
"TUATH": 1,
"TUBAE": 1,
"TUBAR": 1,
"TUBAS": 1,
"TUBBY": 1,
"TUBED": 1,
"TUBES": 1,
"TUCKS": 1,
"TUFAS": 1,
"TUFFE": 1,
"TUFFS": 1,
"TUFTS": 1,
"TUFTY": 1,
"TUGRA": 1,
"TUILE": 1,
"TUINA": 1,
"TUISM": 1,
"TUKTU": 1,
"TULES": 1,
"TULPA": 1,
"TULSI": 1,
"TUMID": 1,
"TUMMY": 1,
"TUMPS": 1,
"TUMPY": 1,
"TUNAS": 1,
"TUNDS": 1,
"TUNED": 1,
"TUNER": 1,
"TUNES": 1,
"TUNGS": 1,
"TUNNY": 1,
"TUPEK": 1,
"TUPIK": 1,
"TUPLE": 1,
"TUQUE": 1,
"TURDS": 1,
"TURFS": 1,
"TURFY": 1,
"TURKS": 1,
"TURME": 1,
"TURMS": 1,
"TURNS": 1,
"TURNT": 1,
"TURPS": 1,
"TURRS": 1,
"TUSHY": 1,
"TUSKS": 1,
"TUSKY": 1,
"TUTEE": 1,
"TUTTI": 1,
"TUTTY": 1,
"TUTUS": 1,
"TUXES": 1,
"TUYER": 1,
"TWAES": 1,
"TWAIN": 1,
"TWALS": 1,
"TWANK": 1,
"TWATS": 1,
"TWAYS": 1,
"TWEEL": 1,
"TWEEN": 1,
"TWEEP": 1,
"TWEER": 1,
"TWERK": 1,
"TWERP": 1,
"TWIER": 1,
"TWIGS": 1,
"TWILL": 1,
"TWILT": 1,
"TWINK": 1,
"TWINS": 1,
"TWINY": 1,
"TWIRE": 1,
"TWIRP": 1,
"TWITE": 1,
"TWITS": 1,
"TWOER": 1,
"TWYER": 1,
"TYEES": 1,
"TYERS": 1,
"TYIYN": 1,
"TYKES": 1,
"TYLER": 1,
"TYMPS": 1,
"TYNDE": 1,
"TYNED": 1,
"TYNES": 1,
"TYPAL": 1,
"TYPED": 1,
"TYPES": 1,
"TYPEY": 1,
"TYPIC": 1,
"TYPOS": 1,
"TYPPS": 1,
"TYPTO": 1,
"TYRAN": 1,
"TYRED": 1,
"TYRES": 1,
"TYROS": 1,
"TYTHE": 1,
"TZARS": 1,
"UDALS": 1,
"UDONS": 1,
"UGALI": 1,
"UGGED": 1,
"UHLAN": 1,
"UHURU": 1,
"UKASE": 1,
"ULAMA": 1,
"ULANS": 1,
"ULEMA": 1,
"ULMIN": 1,
"ULNAD": 1,
"ULNAE": 1,
"ULNAR": 1,
"ULNAS": 1,
"ULPAN": 1,
"ULVAS": 1,
"ULYIE": 1,
"ULZIE": 1,
"UMAMI": 1,
"UMBEL": 1,
"UMBER": 1,
"UMBLE": 1,
"UMBOS": 1,
"UMBRE": 1,
"UMIAC": 1,
"UMIAK": 1,
"UMIAQ": 1,
"UMMAH": 1,
"UMMAS": 1,
"UMMED": 1,
"UMPED": 1,
"UMPHS": 1,
"UMPIE": 1,
"UMPTY": 1,
"UMRAH": 1,
"UMRAS": 1,
"UNAIS": 1,
"UNAPT": 1,
"UNARM": 1,
"UNARY": 1,
"UNAUS": 1,
"UNBAG": 1,
"UNBAN": 1,
"UNBAR": 1,
"UNBED": 1,
"UNBID": 1,
"UNBOX": 1,
"UNCAP": 1,
"UNCES": 1,
"UNCIA": 1,
"UNCOS": 1,
"UNCOY": 1,
"UNCUS": 1,
"UNDAM": 1,
"UNDEE": 1,
"UNDOS": 1,
"UNDUG": 1,
"UNETH": 1,
"UNFIX": 1,
"UNGAG": 1,
"UNGET": 1,
"UNGOD": 1,
"UNGOT": 1,
"UNGUM": 1,
"UNHAT": 1,
"UNHIP": 1,
"UNICA": 1,
"UNITS": 1,
"UNJAM": 1,
"UNKED": 1,
"UNKET": 1,
"UNKID": 1,
"UNLAW": 1,
"UNLAY": 1,
"UNLED": 1,
"UNLET": 1,
"UNLID": 1,
"UNMAN": 1,
"UNMEW": 1,
"UNMIX": 1,
"UNPAY": 1,
"UNPEG": 1,
"UNPEN": 1,
"UNPIN": 1,
"UNRED": 1,
"UNRID": 1,
"UNRIG": 1,
"UNRIP": 1,
"UNSAW": 1,
"UNSAY": 1,
"UNSEE": 1,
"UNSEW": 1,
"UNSEX": 1,
"UNSOD": 1,
"UNTAX": 1,
"UNTIN": 1,
"UNWET": 1,
"UNWIT": 1,
"UNWON": 1,
"UPBOW": 1,
"UPBYE": 1,
"UPDOS": 1,
"UPDRY": 1,
"UPEND": 1,
"UPJET": 1,
"UPLAY": 1,
"UPLED": 1,
"UPLIT": 1,
"UPPED": 1,
"UPRAN": 1,
"UPRUN": 1,
"UPSEE": 1,
"UPSEY": 1,
"UPTAK": 1,
"UPTER": 1,
"UPTIE": 1,
"URAEI": 1,
"URALI": 1,
"URAOS": 1,
"URARE": 1,
"URARI": 1,
"URASE": 1,
"URATE": 1,
"URBEX": 1,
"URBIA": 1,
"URDEE": 1,
"UREAL": 1,
"UREAS": 1,
"UREDO": 1,
"UREIC": 1,
"URENA": 1,
"URENT": 1,
"URGED": 1,
"URGER": 1,
"URGES": 1,
"URIAL": 1,
"URITE": 1,
"URMAN": 1,
"URNAL": 1,
"URNED": 1,
"URPED": 1,
"URSAE": 1,
"URSID": 1,
"URSON": 1,
"URUBU": 1,
"URVAS": 1,
"USERS": 1,
"USNEA": 1,
"USQUE": 1,
"USURE": 1,
"USURY": 1,
"UTERI": 1,
"UVEAL": 1,
"UVEAS": 1,
"UVULA": 1,
"VACUA": 1,
"VADED": 1,
"VADES": 1,
"VAGAL": 1,
"VAGUS": 1,
"VAILS": 1,
"VAIRE": 1,
"VAIRS": 1,
"VAIRY": 1,
"VAKAS": 1,
"VAKIL": 1,
"VALES": 1,
"VALIS": 1,
"VALSE": 1,
"VAMPS": 1,
"VAMPY": 1,
"VANDA": 1,
"VANED": 1,
"VANES": 1,
"VANGS": 1,
"VANTS": 1,
"VAPED": 1,
"VAPER": 1,
"VAPES": 1,
"VARAN": 1,
"VARAS": 1,
"VARDY": 1,
"VAREC": 1,
"VARES": 1,
"VARIA": 1,
"VARIX": 1,
"VARNA": 1,
"VARUS": 1,
"VARVE": 1,
"VASAL": 1,
"VASES": 1,
"VASTS": 1,
"VASTY": 1,
"VATIC": 1,
"VATUS": 1,
"VAUCH": 1,
"VAUTE": 1,
"VAUTS": 1,
"VAWTE": 1,
"VAXES": 1,
"VEALE": 1,
"VEALS": 1,
"VEALY": 1,
"VEENA": 1,
"VEEPS": 1,
"VEERS": 1,
"VEERY": 1,
"VEGAS": 1,
"VEGES": 1,
"VEGIE": 1,
"VEGOS": 1,
"VEHME": 1,
"VEILS": 1,
"VEILY": 1,
"VEINS": 1,
"VEINY": 1,
"VELAR": 1,
"VELDS": 1,
"VELDT": 1,
"VELES": 1,
"VELLS": 1,
"VELUM": 1,
"VENAE": 1,
"VENAL": 1,
"VENDS": 1,
"VENDU": 1,
"VENEY": 1,
"VENGE": 1,
"VENIN": 1,
"VENTS": 1,
"VENUS": 1,
"VERBS": 1,
"VERRA": 1,
"VERRY": 1,
"VERST": 1,
"VERTS": 1,
"VERTU": 1,
"VESPA": 1,
"VESTA": 1,
"VESTS": 1,
"VETCH": 1,
"VEXED": 1,
"VEXER": 1,
"VEXES": 1,
"VEXIL": 1,
"VEZIR": 1,
"VIALS": 1,
"VIAND": 1,
"VIBES": 1,
"VIBEX": 1,
"VIBEY": 1,
"VICED": 1,
"VICES": 1,
"VICHY": 1,
"VIERS": 1,
"VIEWS": 1,
"VIEWY": 1,
"VIFDA": 1,
"VIFFS": 1,
"VIGAS": 1,
"VIGIA": 1,
"VILDE": 1,
"VILER": 1,
"VILLI": 1,
"VILLS": 1,
"VIMEN": 1,
"VINAL": 1,
"VINAS": 1,
"VINCA": 1,
"VINED": 1,
"VINER": 1,
"VINES": 1,
"VINEW": 1,
"VINIC": 1,
"VINOS": 1,
"VINTS": 1,
"VIOLD": 1,
"VIOLS": 1,
"VIRED": 1,
"VIREO": 1,
"VIRES": 1,
"VIRGA": 1,
"VIRGE": 1,
"VIRID": 1,
"VIRLS": 1,
"VIRTU": 1,
"VISAS": 1,
"VISED": 1,
"VISES": 1,
"VISIE": 1,
"VISNE": 1,
"VISON": 1,
"VISTO": 1,
"VITAE": 1,
"VITAS": 1,
"VITEX": 1,
"VITRO": 1,
"VITTA": 1,
"VIVAS": 1,
"VIVAT": 1,
"VIVDA": 1,
"VIVER": 1,
"VIVES": 1,
"VIZIR": 1,
"VIZOR": 1,
"VLEIS": 1,
"VLIES": 1,
"VLOGS": 1,
"VOARS": 1,
"VOCAB": 1,
"VOCES": 1,
"VODDY": 1,
"VODOU": 1,
"VODUN": 1,
"VOEMA": 1,
"VOGIE": 1,
"VOIDS": 1,
"VOILE": 1,
"VOIPS": 1,
"VOLAE": 1,
"VOLAR":1,
"VOLED":1,
"VOLES":1,
"VOLET":1,
"VOLKS":1,
"VOLTA":1,
"VOLTE":1,
"VOLTI":1,
"VOLTS":1,
"VOLVA":1,
"VOLVE":1,
"VOMER":1,
"VOTED":1,
"VOTES":1,
"VOUGE":1,
"VOULU":1,
"VOWED":1,
"VOWER":1,
"VOXEL":1,
"VOZHD":1,
"VRAIC":1,
"VRILS":1,
"VROOM":1,
"VROUS":1,
"VROUW":1,
"VROWS":1,
"VUGGS":1,
"VUGGY":1,
"VUGHS":1,
"VUGHY":1,
"VULGO":1,
"VULNS":1,
"VULVA":1,
"VUTTY":1,
"WAACS":1,
"WACKE":1,
"WACKO":1,
"WACKS":1,
"WADDS":1,
"WADDY":1,
"WADED":1,
"WADER":1,
"WADES":1,
"WADGE":1,
"WADIS":1,
"WADTS":1,
"WAFFS":1,
"WAFTS":1,
"WAGED":1,
"WAGES":1,
"WAGGA":1,
"WAGYU":1,
"WAHOO":1,
"WAIDE":1,
"WAIFS":1,
"WAIFT":1,
"WAILS":1,
"WAINS":1,
"WAIRS":1,
"WAITE":1,
"WAITS":1,
"WAKAS":1,
"WAKED":1,
"WAKEN":1,
"WAKER":1,
"WAKES":1,
"WAKFS":1,
"WALDO":1,
"WALDS":1,
"WALED":1,
"WALER":1,
"WALES":1,
"WALIE":1,
"WALIS":1,
"WALKS":1,
"WALLA":1,
"WALLS":1,
"WALLY":1,
"WALTY":1,
"WAMED":1,
"WAMES":1,
"WAMUS":1,
"WANDS":1,
"WANED":1,
"WANES":1,
"WANEY":1,
"WANGS":1,
"WANKS":1,
"WANKY":1,
"WANLE":1,
"WANLY":1,
"WANNA":1,
"WANTS":1,
"WANTY":1,
"WANZE":1,
"WAQFS":1,
"WARBS":1,
"WARBY":1,
"WARDS":1,
"WARED":1,
"WARES":1,
"WAREZ":1,
"WARKS":1,
"WARMS":1,
"WARNS":1,
"WARPS":1,
"WARRE":1,
"WARST":1,
"WARTS":1,
"WASES":1,
"WASHY":1,
"WASMS":1,
"WASPS":1,
"WASPY":1,
"WASTS":1,
"WATAP":1,
"WATTS":1,
"WAUFF":1,
"WAUGH":1,
"WAUKS":1,
"WAULK":1,
"WAULS":1,
"WAURS":1,
"WAVED":1,
"WAVES":1,
"WAVEY":1,
"WAWAS":1,
"WAWES":1,
"WAWLS":1,
"WAXED":1,
"WAXER":1,
"WAXES":1,
"WAYED":1,
"WAZIR":1,
"WAZOO":1,
"WEALD":1,
"WEALS":1,
"WEAMB":1,
"WEANS":1,
"WEARS":1,
"WEBBY":1,
"WEBER":1,
"WECHT":1,
"WEDEL":1,
"WEDGY":1,
"WEEDS":1,
"WEEKE":1,
"WEEKS":1,
"WEELS":1,
"WEEMS":1,
"WEENS":1,
"WEENY":1,
"WEEPS":1,
"WEEPY":1,
"WEEST":1,
"WEETE":1,
"WEETS":1,
"WEFTE":1,
"WEFTS":1,
"WEIDS":1,
"WEILS":1,
"WEIRS":1,
"WEISE":1,
"WEIZE":1,
"WEKAS":1,
"WELDS":1,
"WELKE":1,
"WELKS":1,
"WELKT":1,
"WELLS":1,
"WELLY":1,
"WELTS":1,
"WEMBS":1,
"WENDS":1,
"WENGE":1,
"WENNY":1,
"WENTS":1,
"WEROS":1,
"WERSH":1,
"WESTS":1,
"WETAS":1,
"WETLY":1,
"WEXED":1,
"WEXES":1,
"WHAMO":1,
"WHAMS":1,
"WHANG":1,
"WHAPS":1,
"WHARE":1,
"WHATA":1,
"WHATS":1,
"WHAUP":1,
"WHAUR":1,
"WHEAL":1,
"WHEAR":1,
"WHEEN":1,
"WHEEP":1,
"WHEFT":1,
"WHELK":1,
"WHELM":1,
"WHENS":1,
"WHETS":1,
"WHEWS":1,
"WHEYS":1,
"WHIDS":1,
"WHIFT":1,
"WHIGS":1,
"WHILK":1,
"WHIMS":1,
"WHINS":1,
"WHIOS":1,
"WHIPS":1,
"WHIPT":1,
"WHIRR":1,
"WHIRS":1,
"WHISH":1,
"WHISS":1,
"WHIST":1,
"WHITS":1,
"WHITY":1,
"WHIZZ":1,
"WHOMP":1,
"WHOOF":1,
"WHOOT":1,
"WHOPS":1,
"WHORE":1,
"WHORL":1,
"WHORT":1,
"WHOSO":1,
"WHOWS":1,
"WHUMP":1,
"WHUPS":1,
"WHYDA":1,
"WICCA":1,
"WICKS":1,
"WICKY":1,
"WIDDY":1,
"WIDES":1,
"WIELS":1,
"WIFED":1,
"WIFES":1,
"WIFEY":1,
"WIFIE":1,
"WIFTY":1,
"WIGAN":1,
"WIGGA":1,
"WIGGY":1,
"WIKIS":1,
"WILCO":1,
"WILDS":1,
"WILED":1,
"WILES":1,
"WILGA":1,
"WILIS":1,
"WILJA":1,
"WILLS":1,
"WILTS":1,
"WIMPS":1,
"WINDS":1,
"WINED":1,
"WINES":1,
"WINEY":1,
"WINGE":1,
"WINGS":1,
"WINGY":1,
"WINKS":1,
"WINNA":1,
"WINNS":1,
"WINOS":1,
"WINZE":1,
"WIPED":1,
"WIPER":1,
"WIPES":1,
"WIRED":1,
"WIRER":1,
"WIRES":1,
"WIRRA":1,
"WISED":1,
"WISES":1,
"WISHA":1,
"WISHT":1,
"WISPS":1,
"WISTS":1,
"WITAN":1,
"WITED":1,
"WITES":1,
"WITHE":1,
"WITHS":1,
"WITHY":1,
"WIVED":1,
"WIVER":1,
"WIVES":1,
"WIZEN":1,
"WIZES":1,
"WOADS":1,
"WOALD":1,
"WOCKS":1,
"WODGE":1,
"WOFUL":1,
"WOJUS":1,
"WOKER":1,
"WOKKA":1,
"WOLDS":1,
"WOLFS":1,
"WOLLY":1,
"WOLVE":1,
"WOMBS":1,
"WOMBY":1,
"WOMYN":1,
"WONGA":1,
"WONGI":1,
"WONKS":1,
"WONKY":1,
"WONTS":1,
"WOODS":1,
"WOOED":1,
"WOOFS":1,
"WOOFY":1,
"WOOLD":1,
"WOOLS":1,
"WOONS":1,
"WOOPS":1,
"WOOPY":1,
"WOOSE":1,
"WOOSH":1,
"WOOTZ":1,
"WORDS":1,
"WORKS":1,
"WORMS":1,
"WORMY":1,
"WORTS":1,
"WOWED":1,
"WOWEE":1,
"WOXEN":1,
"WRANG":1,
"WRAPS":1,
"WRAPT":1,
"WRAST":1,
"WRATE":1,
"WRAWL":1,
"WRENS":1,
"WRICK":1,
"WRIED":1,
"WRIER":1,
"WRIES":1,
"WRITS":1,
"WROKE":1,
"WROOT":1,
"WROTH":1,
"WRYER":1,
"WUDDY":1,
"WUDUS":1,
"WULLS":1,
"WURST":1,
"WUSES":1,
"WUSHU":1,
"WUSSY":1,
"WUXIA":1,
"WYLED":1,
"WYLES":1,
"WYNDS":1,
"WYNNS":1,
"WYTED":1,
"WYTES":1,
"XEBEC":1,
"XENIA":1,
"XENIC":1,
"XENON":1,
"XERIC":1,
"XEROX":1,
"XERUS":1,
"XOANA":1,
"XRAYS":1,
"XYLAN":1,
"XYLEM":1,
"XYLIC":1,
"XYLOL":1,
"XYLYL":1,
"XYSTI":1,
"XYSTS":1,
"YAARS":1,
"YABAS":1,
"YABBA":1,
"YABBY":1,
"YACCA":1,
"YACKA":1,
"YACKS":1,
"YAFFS":1,
"YAGER":1,
"YAGES":1,
"YAGIS":1,
"YAHOO":1,
"YAIRD":1,
"YAKKA":1,
"YAKOW":1,
"YALES":1,
"YAMEN":1,
"YAMPY":1,
"YAMUN":1,
"YANGS":1,
"YANKS":1,
"YAPOK":1,
"YAPON":1,
"YAPPS":1,
"YAPPY":1,
"YARAK":1,
"YARCO":1,
"YARDS":1,
"YARER":1,
"YARFA":1,
"YARKS":1,
"YARNS":1,
"YARRS":1,
"YARTA":1,
"YARTO":1,
"YATES":1,
"YAUDS":1,
"YAULD":1,
"YAUPS":1,
"YAWED":1,
"YAWEY":1,
"YAWLS":1,
"YAWNS":1,
"YAWNY":1,
"YAWPS":1,
"YBORE":1,
"YCLAD":1,
"YCLED":1,
"YCOND":1,
"YDRAD":1,
"YDRED":1,
"YEADS":1,
"YEAHS":1,
"YEALM":1,
"YEANS":1,
"YEARD":1,
"YEARS":1,
"YECCH":1,
"YECHS":1,
"YECHY":1,
"YEDES":1,
"YEEDS":1,
"YEESH":1,
"YEGGS":1,
"YELKS":1,
"YELLS":1,
"YELMS":1,
"YELPS":1,
"YELTS":1,
"YENTA":1,
"YENTE":1,
"YERBA":1,
"YERDS":1,
"YERKS":1,
"YESES":1,
"YESKS":1,
"YESTS":1,
"YESTY":1,
"YETIS":1,
"YETTS":1,
"YEUKS":1,
"YEUKY":1,
"YEVEN":1,
"YEVES":1,
"YEWEN":1,
"YEXED":1,
"YEXES":1,
"YFERE":1,
"YIKED":1,
"YIKES":1,
"YILLS":1,
"YINCE":1,
"YIPES":1,
"YIPPY":1,
"YIRDS":1,
"YIRKS":1,
"YIRRS":1,
"YIRTH":1,
"YITES":1,
"YITIE":1,
"YLEMS":1,
"YLIKE":1,
"YLKES":1,
"YMOLT":1,
"YMPES":1,
"YOBBO":1,
"YOBBY":1,
"YOCKS":1,
"YODEL":1,
"YODHS":1,
"YODLE":1,
"YOGAS":1,
"YOGEE":1,
"YOGHS":1,
"YOGIC":1,
"YOGIN":1,
"YOGIS":1,
"YOICK":1,
"YOJAN":1,
"YOKED":1,
"YOKEL":1,
"YOKER":1,
"YOKES":1,
"YOKUL":1,
"YOLKS":1,
"YOLKY":1,
"YOMIM":1,
"YOMPS":1,
"YONIC":1,
"YONIS":1,
"YONKS":1,
"YOOFS":1,
"YOOPS":1,
"YORES":1,
"YORKS":1,
"YORPS":1,
"YOUKS":1,
"YOURN":1,
"YOURS":1,
"YOURT":1,
"YOUSE":1,
"YOWED":1,
"YOWES":1,
"YOWIE":1,
"YOWLS":1,
"YOWZA":1,
"YRAPT":1,
"YRENT":1,
"YRIVD":1,
"YRNEH":1,
"YSAME":1,
"YTOST":1,
"YUANS":1,
"YUCAS":1,
"YUCCA":1,
"YUCCH":1,
"YUCKO":1,
"YUCKS":1,
"YUCKY":1,
"YUFTS":1,
"YUGAS":1,
"YUKED":1,
"YUKES":1,
"YUKKY":1,
"YUKOS":1,
"YULAN":1,
"YULES":1,
"YUMMO":1,
"YUMMY":1,
"YUMPS":1,
"YUPON":1,
"YUPPY":1,
"YURTA":1,
"YURTS":1,
"YUZUS":1,
"ZABRA":1,
"ZACKS":1,
"ZAIDA":1,
"ZAIDY":1,
"ZAIRE":1,
"ZAKAT":1,
"ZAMAN":1,
"ZAMBO":1,
"ZAMIA":1,
"ZANJA":1,
"ZANTE":1,
"ZANZA":1,
"ZANZE":1,
"ZAPPY":1,
"ZARFS":1,
"ZARIS":1,
"ZATIS":1,
"ZAXES":1,
"ZAYIN":1,
"ZAZEN":1,
"ZEALS":1,
"ZEBEC":1,
"ZEBUB":1,
"ZEBUS":1,
"ZEDAS":1,
"ZEINS":1,
"ZENDO":1,
"ZERDA":1,
"ZERKS":1,
"ZEROS":1,
"ZESTS":1,
"ZETAS":1,
"ZEXES":1,
"ZEZES":1,
"ZHOMO":1,
"ZIBET":1,
"ZIFFS":1,
"ZIGAN":1,
"ZILAS":1,
"ZILCH":1,
"ZILLA":1,
"ZILLS":1,
"ZIMBI":1,
"ZIMBS":1,
"ZINCO":1,
"ZINCS":1,
"ZINCY":1,
"ZINEB":1,
"ZINES":1,
"ZINGS":1,
"ZINGY":1,
"ZINKE":1,
"ZINKY":1,
"ZIPPO":1,
"ZIPPY":1,
"ZIRAM":1,
"ZITIS":1,
"ZIZEL":1,
"ZIZIT":1,
"ZLOTE":1,
"ZLOTY":1,
"ZOAEA":1,
"ZOBOS":1,
"ZOBUS":1,
"ZOCCO":1,
"ZOEAE":1,
"ZOEAL":1,
"ZOEAS":1,
"ZOISM":1,
"ZOIST":1,
"ZOMBI":1,
"ZONAE":1,
"ZONDA":1,
"ZONED":1,
"ZONER":1,
"ZONES":1,
"ZONKS":1,
"ZOOEA":1,
"ZOOEY":1,
"ZOOID":1,
"ZOOKS":1,
"ZOOMS":1,
"ZOONS":1,
"ZOOTY":1,
"ZOPPA":1,
"ZOPPO":1,
"ZORIL":1,
"ZORIS":1,
"ZORRO":1,
"ZOUKS":1,
"ZOWEE":1,
"ZOWIE":1,
"ZULUS":1,
"ZUPAN":1,
"ZUPAS":1,
"ZUPPA":1,
"ZURFS":1,
"ZUZIM":1,
"ZYGAL":1,
"ZYGON":1,
"ZYMES":1,
"ZYMIC":1,
}

const ANSWERS = {
"VIDEO":1,
"PRINT":1,
"RIGID":1,
"OLDEN":1,
"STOMP":1,
"NOISY":1,
"OUGHT":1,
"BURST":1,
"TENET":1,
"CELLO":1,
"MANOR":1,
"BRAWL":1,
"JUMBO":1,
"SLICK":1,
"BELIE":1,
"UNITY":1,
"FRESH":1,
"VIGOR":1,
"GIRLY":1,
"PECAN":1,
"ELEGY":1,
"PLANE":1,
"KITTY":1,
"TACIT":1,
"MELON":1,
"STOLE":1,
"SLOSH":1,
"DONUT":1,
"SHUCK":1,
"SPITE":1,
"NATAL":1,
"OVOID":1,
"TEPEE":1,
"BLEAK":1,
"TULIP":1,
"SLURP":1,
"CACTI":1,
"DIGIT":1,
"STRAW":1,
"EMBER":1,
"DECOR":1,
"ELATE":1,
"SUPER":1,
"ACRID":1,
"AWOKE":1,
"SILKY":1,
"UNSET":1,
"TERRA":1,
"CEASE":1,
"BANAL":1,
"PRESS":1,
"HEADY":1,
"VISOR":1,
"ASSAY":1,
"STOVE":1,
"SAUCY":1,
"REBUT":1,
"BALER":1,
"REARM":1,
"ROOMY":1,
"FRIED":1,
"STEIN":1,
"SLUNG":1,
"CHARD":1,
"DODGY":1,
"BUSED":1,
"TRUCE":1,
"FIZZY":1,
"AUDIO":1,
"BACON":1,
"DYING":1,
"TROOP":1,
"HOTEL":1,
"MORAL":1,
"SAVOR":1,
"HOBBY":1,
"OTHER":1,
"WEIGH":1,
"DAUNT":1,
"INEPT":1,
"ABYSS":1,
"DECRY":1,
"CLING":1,
"WOVEN":1,
"VOGUE":1,
"HARDY":1,
"SLOOP":1,
"CRESS":1,
"BUXOM":1,
"DRILL":1,
"FLUID":1,
"STINK":1,
"DEATH":1,
"PLANK":1,
"EDICT":1,
"ARGUE":1,
"MOWER":1,
"UNFED":1,
"UPPER":1,
"RATIO":1,
"QUEER":1,
"GAMUT":1,
"BELLY":1,
"MODEL":1,
"SPRAY":1,
"SPASM":1,
"STALK":1,
"ELUDE":1,
"TWIRL":1,
"LEANT":1,
"CHOIR":1,
"TIDAL":1,
"NEWLY":1,
"TRUNK":1,
"CHOKE":1,
"WRONG":1,
"TRIAD":1,
"CEDAR":1,
"CLOWN":1,
"PICKY":1,
"CAMEO":1,
"REHAB":1,
"ETHER":1,
"MORON":1,
"PITHY":1,
"GREAT":1,
"ONION":1,
"REUSE":1,
"AUDIT":1,
"BRINK":1,
"SIEGE":1,
"REGAL":1,
"NAVAL":1,
"SWEEP":1,
"ALOUD":1,
"THANK":1,
"ROGER":1,
"PLAIN":1,
"TAPIR":1,
"BEFIT":1,
"SHALT":1,
"MONEY":1,
"FALSE":1,
"FOCUS":1,
"MONTH":1,
"STAFF":1,
"TAMER":1,
"PHOTO":1,
"EXACT":1,
"ADAPT":1,
"PRICE":1,
"FLOOR":1,
"CONCH":1,
"MAGIC":1,
"ABOVE":1,
"SHRUB":1,
"JOINT":1,
"SATYR":1,
"WHELP":1,
"SHAKY":1,
"SCOFF":1,
"IVORY":1,
"STANK":1,
"QUEST":1,
"DRAKE":1,
"RECUR":1,
"SPERM":1,
"SNEER":1,
"CREME":1,
"SWAMI":1,
"SHONE":1,
"CREAK":1,
"MEDIA":1,
"IDLER":1,
"DUMPY":1,
"CHUTE":1,
"ASSET":1,
"BULLY":1,
"BILLY":1,
"POOCH":1,
"AHEAD":1,
"YEAST":1,
"BUGLE":1,
"BOOZE":1,
"DUVET":1,
"POWER":1,
"OTTER":1,
"NURSE":1,
"ODDLY":1,
"MAFIA":1,
"FROND":1,
"CAVIL":1,
"SKATE":1,
"LARGE":1,
"PASTE":1,
"PASTY":1,
"VICAR":1,
"APPLY":1,
"GOOEY":1,
"GRUEL":1,
"LEDGE":1,
"BATTY":1,
"OVERT":1,
"STUNK":1,
"SAVVY":1,
"UNMET":1,
"FEVER":1,
"CHAIN":1,
"PRIVY":1,
"LATHE":1,
"SPOIL":1,
"FLOUT":1,
"RESET":1,
"BRING":1,
"SLUMP":1,
"HUSKY":1,
"BIGOT":1,
"SLANT":1,
"LEAFY":1,
"LAYER":1,
"YOUNG":1,
"SCALE":1,
"SALTY":1,
"RELAX":1,
"BORAX":1,
"LOSER":1,
"SLUSH":1,
"TRYST":1,
"LANCE":1,
"EXIST":1,
"CRIME":1,
"SPAWN":1,
"RELAY":1,
"SLEEK":1,
"TURBO":1,
"CLOTH":1,
"LIBEL":1,
"BUTCH":1,
"OCCUR":1,
"UNDER":1,
"BLAST":1,
"SCOWL":1,
"FRILL":1,
"DUMMY":1,
"ICILY":1,
"ALIEN":1,
"KAPPA":1,
"CLINK":1,
"FAVOR":1,
"CAUSE":1,
"OMBRE":1,
"STUNG":1,
"HOVEL":1,
"AMUSE":1,
"ADOPT":1,
"SPREE":1,
"CYCLE":1,
"THEME":1,
"VAGUE":1,
"JOLLY":1,
"GOOFY":1,
"SERUM":1,
"COMMA":1,
"HORDE":1,
"SEVER":1,
"HOIST":1,
"SWIRL":1,
"MERCY":1,
"SHINY":1,
"BLAME":1,
"BARGE":1,
"GENRE":1,
"TULLE":1,
"PIECE":1,
"WHINY":1,
"QUASI":1,
"DRONE":1,
"HAVOC":1,
"BROOK":1,
"JUNTA":1,
"SHEAR":1,
"GRILL":1,
"BLOCK":1,
"HOWDY":1,
"TONAL":1,
"TUTOR":1,
"KNEEL":1,
"MEDAL":1,
"BOTCH":1,
"ZESTY":1,
"PLACE":1,
"FEMME":1,
"MOLDY":1,
"NANNY":1,
"SPELL":1,
"DEITY":1,
"CHAIR":1,
"VEGAN":1,
"DULLY":1,
"OPERA":1,
"LEFTY":1,
"METAL":1,
"PIETY":1,
"ELIDE":1,
"RIDGE":1,
"MIDGE":1,
"EVERY":1,
"FERRY":1,
"JAUNT":1,
"EAGLE":1,
"SUMAC":1,
"ESTER":1,
"DAILY":1,
"DADDY":1,
"PATSY":1,
"WAGON":1,
"CANAL":1,
"FORTE":1,
"ODDER":1,
"WRECK":1,
"NASAL":1,
"PALSY":1,
"PANEL":1,
"CLASH":1,
"LEASE":1,
"SALLY":1,
"FLUKE":1,
"QUITE":1,
"BESET":1,
"GAMER":1,
"INLAY":1,
"BLUNT":1,
"SPIED":1,
"VIRUS":1,
"DELTA":1,
"WREAK":1,
"ALIGN":1,
"DEBUG":1,
"LOAMY":1,
"MINUS":1,
"FETAL":1,
"AUNTY":1,
"STEAK":1,
"STOCK":1,
"TRACK":1,
"BASTE":1,
"BEGET":1,
"TREAD":1,
"FABLE":1,
"RETRY":1,
"VAPID":1,
"PRIME":1,
"WRING":1,
"SPIEL":1,
"AMBLE":1,
"LAGER":1,
"SQUAT":1,
"COBRA":1,
"EBONY":1,
"STASH":1,
"CLUMP":1,
"FOIST":1,
"SMITE":1,
"BINGO":1,
"BAYOU":1,
"OLDER":1,
"SLING":1,
"WISPY":1,
"FINCH":1,
"SPARK":1,
"TIPSY":1,
"PRAWN":1,
"SWISH":1,
"RUGBY":1,
"LIEGE":1,
"CATCH":1,
"BEECH":1,
"APRON":1,
"DRAPE":1,
"CUMIN":1,
"BOUND":1,
"SINEW":1,
"BRASH":1,
"CLEFT":1,
"HAZEL":1,
"COULD":1,
"BLOAT":1,
"CLOCK":1,
"FUNGI":1,
"PANSY":1,
"KNOCK":1,
"GUPPY":1,
"CLUED":1,
"FROZE":1,
"CRAFT":1,
"CANOE":1,
"CREEK":1,
"TOUGH":1,
"FOUND":1,
"WEDGE":1,
"RABID":1,
"CHUNK":1,
"LOOPY":1,
"BROOD":1,
"AMBER":1,
"BEARD":1,
"FURRY":1,
"ADMIT":1,
"PERCH":1,
"OPINE":1,
"TRUCK":1,
"WOOLY":1,
"OCEAN":1,
"SMACK":1,
"POLAR":1,
"COLOR":1,
"RETCH":1,
"SHRUG":1,
"MIDST":1,
"OVATE":1,
"MAIZE":1,
"BASIC":1,
"PEACH":1,
"REALM":1,
"GROWN":1,
"EAGER":1,
"ANODE":1,
"MERGE":1,
"DRESS":1,
"FETUS":1,
"KNAVE":1,
"THIRD":1,
"LAPSE":1,
"FLUNK":1,
"FLESH":1,
"PORCH":1,
"COUGH":1,
"SLASH":1,
"BLEEP":1,
"DOWEL":1,
"FUSSY":1,
"BANJO":1,
"TODDY":1,
"QUOTE":1,
"CRAZE":1,
"FROCK":1,
"BUDDY":1,
"POPPY":1,
"LASSO":1,
"SCONE":1,
"LEGAL":1,
"RASPY":1,
"PULPY":1,
"WALTZ":1,
"PIZZA":1,
"SPINE":1,
"FJORD":1,
"ONSET":1,
"KNEAD":1,
"SHOUT":1,
"DITTY":1,
"AMITY":1,
"SWINE":1,
"STUFF":1,
"SNAKY":1,
"MASSE":1,
"RIPEN":1,
"SWOOP":1,
"CHEAP":1,
"CURSE":1,
"KOALA":1,
"SOAPY":1,
"DALLY":1,
"CREPT":1,
"GUILE":1,
"TEETH":1,
"SAUCE":1,
"INFER":1,
"FUDGE":1,
"SEPIA":1,
"VOMIT":1,
"BUSHY":1,
"MAMBO":1,
"LUSTY":1,
"ENTER":1,
"GUARD":1,
"SPORT":1,
"PURGE":1,
"ARTSY":1,
"JIFFY":1,
"CHALK":1,
"CLEAN":1,
"RAINY":1,
"SCANT":1,
"FLUSH":1,
"POLKA":1,
"KINKY":1,
"DOWDY":1,
"RAZOR":1,
"PIVOT":1,
"SHACK":1,
"LANKY":1,
"RIVET":1,
"MANGA":1,
"BOOST":1,
"DRANK":1,
"GRAIL":1,
"MANGO":1,
"ALLAY":1,
"VENOM":1,
"LOWLY":1,
"GRANT":1,
"CHIEF":1,
"THUMB":1,
"HARRY":1,
"SANDY":1,
"LOUSE":1,
"TOAST":1,
"SAUTE":1,
"FEAST":1,
"DRIED":1,
"STOOL":1,
"LOCAL":1,
"MOVER":1,
"DROLL":1,
"NICER":1,
"CRAVE":1,
"UPSET":1,
"PROWL":1,
"NEEDY":1,
"PRIZE":1,
"BAGEL":1,
"SIGMA":1,
"COVER":1,
"TONGA":1,
"DRIER":1,
"LEPER":1,
"HAREM":1,
"SEWER":1,
"ABODE":1,
"HEART":1,
"TENTH":1,
"TRIPE":1,
"FULLY":1,
"GLOBE":1,
"EVENT":1,
"OAKEN":1,
"MUDDY":1,
"HITCH":1,
"UNION":1,
"GUISE":1,
"ELFIN":1,
"BUYER":1,
"GHOUL":1,
"FLOUR":1,
"BEGIN":1,
"GRAZE":1,
"UNTIL":1,
"AWAIT":1,
"MEALY":1,
"COYLY":1,
"REVUE":1,
"FILTH":1,
"CROWD":1,
"ABASE":1,
"FATAL":1,
"ALPHA":1,
"FERAL":1,
"CRYPT":1,
"GROIN":1,
"MUSKY":1,
"TORUS":1,
"DROOP":1,
"SWEPT":1,
"BOOTH":1,
"WATCH":1,
"CURVY":1,
"HYPER":1,
"GEEKY":1,
"BEAST":1,
"SHINE":1,
"ELDER":1,
"SHALE":1,
"CRUDE":1,
"GLARE":1,
"DERBY":1,
"MULCH":1,
"JOUST":1,
"RUMBA":1,
"ALBUM":1,
"RINSE":1,
"ABATE":1,
"GULLY":1,
"UNZIP":1,
"GROSS":1,
"MOTEL":1,
"CAULK":1,
"WIDEN":1,
"STOKE":1,
"MURKY":1,
"STYLE":1,
"TAKER":1,
"AVOID":1,
"JUNTO":1,
"OUNCE":1,
"BEZEL":1,
"REPLY":1,
"WIDTH":1,
"COVEY":1,
"SLICE":1,
"SLINK":1,
"WHOOP":1,
"GREEN":1,
"WRIST":1,
"OCTAL":1,
"URINE":1,
"WATER":1,
"SPINY":1,
"EXILE":1,
"PAPAL":1,
"FANNY":1,
"SMILE":1,
"CLEAT":1,
"CRIER":1,
"COUPE":1,
"ROACH":1,
"TARDY":1,
"GUMMY":1,
"PURER":1,
"AGAIN":1,
"BADLY":1,
"SCRUM":1,
"APING":1,
"TRUER":1,
"SPADE":1,
"TOTAL":1,
"NOBLE":1,
"GRAPE":1,
"CHOCK":1,
"SPORE":1,
"PUPIL":1,
"AMAZE":1,
"PENCE":1,
"FILLY":1,
"GLOVE":1,
"BISON":1,
"PANIC":1,
"TAWNY":1,
"TRIBE":1,
"LOCUS":1,
"NINJA":1,
"MIGHT":1,
"MUMMY":1,
"TAPER":1,
"SWEAR":1,
"INTRO":1,
"GOING":1,
"DEALT":1,
"RODEO":1,
"VIVID":1,
"BRUSH":1,
"TABBY":1,
"HIPPY":1,
"WHINE":1,
"MACAW":1,
"STILL":1,
"PAGAN":1,
"PROOF":1,
"USURP":1,
"PRISM":1,
"GRATE":1,
"SHADE":1,
"WISER":1,
"CLEAR":1,
"FLING":1,
"GIVER":1,
"PLIED":1,
"CREEP":1,
"GROUP":1,
"TRUMP":1,
"JEWEL":1,
"HORSE":1,
"NUTTY":1,
"HARPY":1,
"SILLY":1,
"FREAK":1,
"GUIDE":1,
"RISKY":1,
"SEDAN":1,
"GRIPE":1,
"SWUNG":1,
"QUERY":1,
"PLEAD":1,
"LEAVE":1,
"STORY":1,
"SLOPE":1,
"ALERT":1,
"BRIAR":1,
"RIPER":1,
"SCALY":1,
"SISSY":1,
"RANDY":1,
"CHEAT":1,
"SHIFT":1,
"BADGE":1,
"LABOR":1,
"SORRY":1,
"SLEET":1,
"ENTRY":1,
"CRAZY":1,
"COVEN":1,
"SUITE":1,
"FRIAR":1,
"SYRUP":1,
"SHOOK":1,
"GLAND":1,
"FLAIL":1,
"GLAZE":1,
"IONIC":1,
"VISTA":1,
"NINNY":1,
"SOGGY":1,
"STEAD":1,
"CORNY":1,
"CABLE":1,
"WHOSE":1,
"SUSHI":1,
"TOWEL":1,
"WHARF":1,
"DOING":1,
"AGILE":1,
"PAINT":1,
"THREE":1,
"CAPUT":1,
"GAZER":1,
"CACHE":1,
"CHANT":1,
"JELLY":1,
"PUPPY":1,
"CANDY":1,
"SPLIT":1,
"CLACK":1,
"KARMA":1,
"THESE":1,
"ERODE":1,
"OZONE":1,
"MANGE":1,
"SURGE":1,
"GIPSY":1,
"VIXEN":1,
"NEIGH":1,
"SNUCK":1,
"BITTY":1,
"RIFLE":1,
"ERROR":1,
"WOMAN":1,
"WHIFF":1,
"GECKO":1,
"SCOUT":1,
"VALUE":1,
"EXERT":1,
"WELSH":1,
"TOXIC":1,
"ZEBRA":1,
"AGING":1,
"OVARY":1,
"SOBER":1,
"AVERT":1,
"DISCO":1,
"FEIGN":1,
"BLOOD":1,
"KHAKI":1,
"PARTY":1,
"CONIC":1,
"FOLIO":1,
"OPIUM":1,
"AISLE":1,
"WHALE":1,
"PLAIT":1,
"CHUMP":1,
"SKIER":1,
"HEFTY":1,
"FATTY":1,
"CLOAK":1,
"SHADY":1,
"FLINT":1,
"SHAVE":1,
"BILGE":1,
"TACKY":1,
"CHECK":1,
"BUILT":1,
"CASTE":1,
"THORN":1,
"CRAWL":1,
"CABBY":1,
"IMPEL":1,
"POINT":1,
"TIARA":1,
"GNASH":1,
"BATCH":1,
"PAYER":1,
"BROAD":1,
"TEMPO":1,
"THONG":1,
"GEESE":1,
"GRADE":1,
"BUGGY":1,
"EATER":1,
"CIRCA":1,
"FISHY":1,
"TABLE":1,
"PERKY":1,
"POESY":1,
"GROVE":1,
"CHEEK":1,
"TIGER":1,
"OWNER":1,
"BICEP":1,
"TALON":1,
"BAKER":1,
"DINGO":1,
"BULKY":1,
"BONGO":1,
"EXALT":1,
"ECLAT":1,
"GRAPH":1,
"AXION":1,
"GAWKY":1,
"SHALL":1,
"SCOUR":1,
"CLOSE":1,
"BRIBE":1,
"HELLO":1,
"DIODE":1,
"ALLOY":1,
"SCAMP":1,
"DEPTH":1,
"TORCH":1,
"HILLY":1,
"SMOCK":1,
"URBAN":1,
"MAPLE":1,
"ERECT":1,
"SAPPY":1,
"SOOTH":1,
"ADORN":1,
"QUILL":1,
"FROWN":1,
"ABBEY":1,
"TRITE":1,
"POISE":1,
"PYGMY":1,
"SOOTY":1,
"GOURD":1,
"SLYLY":1,
"RETRO":1,
"RIDER":1,
"HYENA":1,
"GROPE":1,
"NOSEY":1,
"SWARM":1,
"HONOR":1,
"TRAMP":1,
"STEER":1,
"AVAIL":1,
"TOXIN":1,
"HATCH":1,
"MOULT":1,
"FUNKY":1,
"CLUNG":1,
"GREED":1,
"PERIL":1,
"SPACE":1,
"BRIDE":1,
"PETAL":1,
"CIGAR":1,
"SNEAK":1,
"SHEET":1,
"ABBOT":1,
"PUPAL":1,
"EMBED":1,
"AGONY":1,
"SPOON":1,
"EDIFY":1,
"GROWL":1,
"TUNIC":1,
"BOULE":1,
"BROOM":1,
"FLOCK":1,
"SONIC":1,
"OUTGO":1,
"SCALD":1,
"PLUNK":1,
"CREAM":1,
"MODAL":1,
"BUNCH":1,
"TRICE":1,
"GROOM":1,
"OFFER":1,
"BRAVO":1,
"ROUTE":1,
"SCRAP":1,
"EVOKE":1,
"PURSE":1,
"RULER":1,
"SNARE":1,
"SHIRT":1,
"SHEER":1,
"WHITE":1,
"DUTCH":1,
"HASTY":1,
"ARRAY":1,
"POSER":1,
"LUCKY":1,
"FOYER":1,
"UNCUT":1,
"KNELT":1,
"FORTH":1,
"ROOST":1,
"MOLAR":1,
"GAUNT":1,
"DELAY":1,
"WAXEN":1,
"LEMON":1,
"USHER":1,
"PESKY":1,
"ACUTE":1,
"DECOY":1,
"VALET":1,
"TREND":1,
"CROUP":1,
"BUTTE":1,
"GODLY":1,
"DWELL":1,
"BOXER":1,
"GOODY":1,
"CANON":1,
"VERGE":1,
"STEAL":1,
"SHOOT":1,
"SHOAL":1,
"BAGGY":1,
"APPLE":1,
"REBEL":1,
"MIMIC":1,
"FLEET":1,
"NORTH":1,
"CANNY":1,
"STAVE":1,
"PATTY":1,
"BUNNY":1,
"WHISK":1,
"EQUIP":1,
"FLIRT":1,
"STEEL":1,
"VODKA":1,
"FLOAT":1,
"DANDY":1,
"SEGUE":1,
"GAUGE":1,
"LINGO":1,
"AXIOM":1,
"SNORT":1,
"LATCH":1,
"IMAGE":1,
"MISER":1,
"VITAL":1,
"PRANK":1,
"TROLL":1,
"PRIMO":1,
"CAGEY":1,
"STAND":1,
"MYRRH":1,
"DROVE":1,
"STIFF":1,
"WINDY":1,
"HEDGE":1,
"SCOOP":1,
"TWIST":1,
"FREER":1,
"START":1,
"CHILL":1,
"BINGE":1,
"DEPOT":1,
"APART":1,
"ANNOY":1,
"ATOLL":1,
"GONAD":1,
"CAIRN":1,
"MOSSY":1,
"ICING":1,
"RIVER":1,
"REBAR":1,
"STEED":1,
"SPEED":1,
"GUEST":1,
"POUCH":1,
"GOLLY":1,
"ADULT":1,
"BROKE":1,
"NEWER":1,
"SALAD":1,
"DOZEN":1,
"FIBRE":1,
"DIMLY":1,
"WINCH":1,
"GAUZE":1,
"COACH":1,
"GROUT":1,
"DRAWN":1,
"SPOKE":1,
"TOPIC":1,
"REPAY":1,
"LARVA":1,
"ROVER":1,
"TENSE":1,
"STRAY":1,
"HASTE":1,
"WIDER":1,
"CREPE":1,
"WORST":1,
"JUICE":1,
"STEEP":1,
"ANNEX":1,
"FRONT":1,
"AFOOT":1,
"STARK":1,
"BOSSY":1,
"PRUDE":1,
"LIPID":1,
"DINGY":1,
"INTER":1,
"CREED":1,
"UNTIE":1,
"ABOUT":1,
"FREED":1,
"MASON":1,
"ANGER":1,
"STRUT":1,
"STAKE":1,
"OFTEN":1,
"DIARY":1,
"ALOOF":1,
"DRAFT":1,
"EASEL":1,
"DAIRY":1,
"DWELT":1,
"CLERK":1,
"MINTY":1,
"LURCH":1,
"STRIP":1,
"AWASH":1,
"NINTH":1,
"SMOTE":1,
"CLAMP":1,
"COLON":1,
"MODEM":1,
"RAYON":1,
"EXTRA":1,
"OUTDO":1,
"ARROW":1,
"PSALM":1,
"RURAL":1,
"GLIDE":1,
"WREST":1,
"DEBIT":1,
"LYRIC":1,
"LUNCH":1,
"FUNNY":1,
"PUDGY":1,
"EERIE":1,
"BLURB":1,
"SHORT":1,
"AZURE":1,
"TATTY":1,
"MANIA":1,
"HARSH":1,
"SPOOL":1,
"ROCKY":1,
"GLINT":1,
"PINCH":1,
"FIBER":1,
"CHAOS":1,
"WHOLE":1,
"BETEL":1,
"RIGHT":1,
"SPRIG":1,
"CHILD":1,
"JETTY":1,
"STORE":1,
"WHACK":1,
"MINOR":1,
"CHOSE":1,
"MAMMY":1,
"WOULD":1,
"CHORE":1,
"VAULT":1,
"DOWRY":1,
"DRAMA":1,
"BENCH":1,
"TOTEM":1,
"READY":1,
"CROSS":1,
"MIRTH":1,
"THOSE":1,
"CABAL":1,
"BROIL":1,
"CAMEL":1,
"NOTCH":1,
"RATTY":1,
"DUSKY":1,
"FUROR":1,
"BEING":1,
"DIZZY":1,
"SPOUT":1,
"SCENT":1,
"LUNAR":1,
"SKULL":1,
"SCRAM":1,
"ALIBI":1,
"POSSE":1,
"GRAFT":1,
"MANLY":1,
"FELON":1,
"CHASE":1,
"STUCK":1,
"FECAL":1,
"BEVEL":1,
"MURAL":1,
"TITAN":1,
"SMALL":1,
"WEIRD":1,
"MUCUS":1,
"IGLOO":1,
"AGLOW":1,
"ASCOT":1,
"MOUSE":1,
"GLOSS":1,
"BELOW":1,
"ELECT":1,
"PLUMB":1,
"FLUFF":1,
"REFIT":1,
"PIXEL":1,
"FAINT":1,
"PUREE":1,
"GASSY":1,
"WRITE":1,
"MEANT":1,
"SNIPE":1,
"STACK":1,
"YIELD":1,
"FLOSS":1,
"SLIME":1,
"SADLY":1,
"RUDDY":1,
"THUMP":1,
"SHOWN":1,
"AWAKE":1,
"BOAST":1,
"INBOX":1,
"SOWER":1,
"COMET":1,
"IDEAL":1,
"ABUSE":1,
"MANGY":1,
"PALER":1,
"SNAKE":1,
"REBUS":1,
"REVEL":1,
"BURNT":1,
"FORUM":1,
"TERSE":1,
"LOBBY":1,
"SPICE":1,
"LEACH":1,
"RAPID":1,
"WRATH":1,
"CRUSH":1,
"IDIOM":1,
"THRUM":1,
"RACER":1,
"SPURN":1,
"SPENT":1,
"UTILE":1,
"CHART":1,
"BRAIN":1,
"GOLEM":1,
"UNFIT":1,
"EPOCH":1,
"PLANT":1,
"FIRST":1,
"MATCH":1,
"ENEMA":1,
"OBESE":1,
"CROWN":1,
"PAUSE":1,
"EGRET":1,
"SHORE":1,
"PRICK":1,
"PATCH":1,
"FLOOD":1,
"EXPEL":1,
"VAPOR":1,
"LIGHT":1,
"QUEUE":1,
"JERKY":1,
"FAIRY":1,
"FLAME":1,
"SWASH":1,
"CRASH":1,
"TRACE":1,
"ABIDE":1,
"MOUNT":1,
"VOICE":1,
"CHIME":1,
"CINCH":1,
"NYMPH":1,
"TEASE":1,
"WHEEL":1,
"COCOA":1,
"BOOTY":1,
"BUILD":1,
"GULCH":1,
"MAKER":1,
"RAMEN":1,
"WASTE":1,
"THING":1,
"LADEN":1,
"ALARM":1,
"SEVEN":1,
"ARDOR":1,
"MARCH":1,
"SOLVE":1,
"SHARE":1,
"MOTIF":1,
"UMBRA":1,
"LOGIC":1,
"THROW":1,
"ARSON":1,
"POSIT":1,
"PADDY":1,
"SNARL":1,
"MUSIC":1,
"OPTIC":1,
"FLUME":1,
"COAST":1,
"DANCE":1,
"SCARF":1,
"HUMID":1,
"BAWDY":1,
"PENNE":1,
"RUSTY":1,
"LOFTY":1,
"SKIRT":1,
"UNIFY":1,
"BLIMP":1,
"FARCE":1,
"SNUFF":1,
"SNOUT":1,
"HUNCH":1,
"FOCAL":1,
"LADLE":1,
"SLANG":1,
"APHID":1,
"CRANK":1,
"ARBOR":1,
"PREEN":1,
"NASTY":1,
"WEEDY":1,
"DRIFT":1,
"UNCLE":1,
"YACHT":1,
"FLAIR":1,
"TRUSS":1,
"INDEX":1,
"SHEEN":1,
"GIRTH":1,
"EPOXY":1,
"BERRY":1,
"GUSTY":1,
"THIEF":1,
"HYMEN":1,
"CAROL":1,
"LEECH":1,
"TIGHT":1,
"STEAM":1,
"WAGER":1,
"INPUT":1,
"STALE":1,
"BUDGE":1,
"SNORE":1,
"SAFER":1,
"SLAIN":1,
"CLOUD":1,
"BRICK":1,
"CLANG":1,
"HOVER":1,
"SPILT":1,
"HUTCH":1,
"HERON":1,
"SHIRE":1,
"STONE":1,
"MADLY":1,
"BREAD":1,
"NIGHT":1,
"ANGLE":1,
"THETA":1,
"LEAKY":1,
"PRIDE":1,
"LUNGE":1,
"SPANK":1,
"ARISE":1,
"BREAK":1,
"TILDE":1,
"KNEED":1,
"CROAK":1,
"RADII":1,
"TWIXT":1,
"WEAVE":1,
"ABORT":1,
"NADIR":1,
"TUBER":1,
"QUIET":1,
"ANGEL":1,
"RECUT":1,
"FRISK":1,
"QUOTA":1,
"WEARY":1,
"DEMUR":1,
"SMOKY":1,
"VENUE":1,
"APNEA":1,
"DETOX":1,
"SCARE":1,
"ENSUE":1,
"THICK":1,
"WHICH":1,
"WRUNG":1,
"ALOFT":1,
"SINCE":1,
"STINT":1,
"CRISP":1,
"DIRTY":1,
"ELITE":1,
"SLATE":1,
"FUGUE":1,
"DAISY":1,
"BEGAN":1,
"BOUGH":1,
"WITTY":1,
"WITCH":1,
"JOIST":1,
"SPIRE":1,
"BLADE":1,
"FRAUD":1,
"GRUNT":1,
"SMELL":1,
"SULKY":1,
"FUZZY":1,
"PARSE":1,
"LEERY":1,
"PLAID":1,
"NEVER":1,
"MOIST":1,
"BOOBY":1,
"TESTY":1,
"EIGHT":1,
"SHUNT":1,
"CLANK":1,
"BEEFY":1,
"RENAL":1,
"DITTO":1,
"MOVIE":1,
"WROTE":1,
"ATTIC":1,
"LIMBO":1,
"SATIN":1,
"PINKY":1,
"MOTTO":1,
"TEPID":1,
"TENOR":1,
"ATONE":1,
"SNIDE":1,
"NYLON":1,
"TONIC":1,
"CRONE":1,
"CURVE":1,
"CIVIC":1,
"INGOT":1,
"DIVER":1,
"SKULK":1,
"CADET":1,
"GUSTO":1,
"BLUFF":1,
"HONEY":1,
"USAGE":1,
"SEMEN":1,
"MECCA":1,
"BRINY":1,
"TREAT":1,
"MISSY":1,
"GRAVY":1,
"PLUMP":1,
"ANNUL":1,
"ESSAY":1,
"HELIX":1,
"CORAL":1,
"THROB":1,
"DREAM":1,
"DOLLY":1,
"LEVEL":1,
"VOWEL":1,
"COWER":1,
"SIXTY":1,
"RERUN":1,
"AIDER":1,
"ROWDY":1,
"BRAID":1,
"SPICY":1,
"CRAMP":1,
"BURLY":1,
"FLOWN":1,
"AROSE":1,
"CLASS":1,
"PLUME":1,
"SPARE":1,
"PIGGY":1,
"TRAIL":1,
"FLANK":1,
"USING":1,
"CHEER":1,
"SOUND":1,
"SCORE":1,
"MATEY":1,
"POUTY":1,
"SALVO":1,
"DIRGE":1,
"WOOZY":1,
"TAINT":1,
"FLARE":1,
"CYBER":1,
"RANCH":1,
"SHEEP":1,
"PUBIC":1,
"REPEL":1,
"STERN":1,
"FENCE":1,
"SKUNK":1,
"SHELF":1,
"NOBLY":1,
"AMEND":1,
"SUAVE":1,
"GUESS":1,
"DOWNY":1,
"SWORD":1,
"AGREE":1,
"BLIND":1,
"TWICE":1,
"LIVER":1,
"CURLY":1,
"JUICY":1,
"BLUSH":1,
"TEDDY":1,
"FOLLY":1,
"VALID":1,
"DREAD":1,
"PARER":1,
"ANIME":1,
"IDYLL":1,
"AGATE":1,
"WIGHT":1,
"SHAME":1,
"PHASE":1,
"ALONE":1,
"WARTY":1,
"PETTY":1,
"HALVE":1,
"STONY":1,
"AGENT":1,
"GRAND":1,
"ASIDE":1,
"HOUND":1,
"RADAR":1,
"BROWN":1,
"CURRY":1,
"NOVEL":1,
"KRILL":1,
"DROIT":1,
"ROBOT":1,
"PIPER":1,
"VYING":1,
"REACT":1,
"HAPPY":1,
"THIGH":1,
"SUGAR":1,
"CRICK":1,
"OUTER":1,
"ANGRY":1,
"NOMAD":1,
"SWILL":1,
"TUMOR":1,
"ROGUE":1,
"CRIED":1,
"REMIT":1,
"POLYP":1,
"LYING":1,
"QUARK":1,
"EMCEE":1,
"WIDOW":1,
"PLEAT":1,
"VALVE":1,
"ENNUI":1,
"UNWED":1,
"CREST":1,
"TASTY":1,
"GRIMY":1,
"TWINE":1,
"SMIRK":1,
"BRIEF":1,
"STARE":1,
"YOUTH":1,
"FIGHT":1,
"UNITE":1,
"IRONY":1,
"CHILI":1,
"PESTO":1,
"EQUAL":1,
"STATE":1,
"VINYL":1,
"FLAKY":1,
"TRUST":1,
"CUBIC":1,
"HOMER":1,
"AFFIX":1,
"ANKLE":1,
"EARLY":1,
"TOWER":1,
"FIERY":1,
"INERT":1,
"HEAVE":1,
"PROXY":1,
"HOARD":1,
"CLAIM":1,
"PROBE":1,
"AFTER":1,
"STUMP":1,
"CRUMP":1,
"AFIRE":1,
"HOTLY":1,
"HINGE":1,
"SWEET":1,
"WAVER":1,
"SPILL":1,
"SASSY":1,
"COURT":1,
"FOGGY":1,
"VIRAL":1,
"BOOZY":1,
"DOPEY":1,
"CHUCK":1,
"MUNCH":1,
"TROUT":1,
"MAYBE":1,
"MAYOR":1,
"BROTH":1,
"HUMAN":1,
"BORNE":1,
"FILET":1,
"FINER":1,
"BLARE":1,
"PRUNE":1,
"CRUEL":1,
"OVINE":1,
"BYLAW":1,
"STOOD":1,
"PEDAL":1,
"GAYER":1,
"FLASK":1,
"THINK":1,
"SCRUB":1,
"LINER":1,
"STUNT":1,
"TUBAL":1,
"STORM":1,
"GRUFF":1,
"BLOOM":1,
"DRIVE":1,
"HUMOR":1,
"TWANG":1,
"SOLAR":1,
"CREDO":1,
"SLACK":1,
"MERIT":1,
"SLUNK":1,
"FEWER":1,
"INANE":1,
"GIDDY":1,
"BALMY":1,
"DITCH":1,
"ADOBE":1,
"FIFTY":1,
"SLEEP":1,
"LEVER":1,
"SUNNY":1,
"TRAIT":1,
"IMBUE":1,
"AFOUL":1,
"CLICK":1,
"BLEND":1,
"STAID":1,
"SHANK":1,
"VILLA":1,
"AMPLE":1,
"RUMOR":1,
"LIMIT":1,
"POUND":1,
"GRIEF":1,
"MILKY":1,
"BLOWN":1,
"NERVE":1,
"PROVE":1,
"MUSTY":1,
"CARVE":1,
"PHONE":1,
"GLOOM":1,
"ARMOR":1,
"TODAY":1,
"PEARL":1,
"GRIME":1,
"STOIC":1,
"JUDGE":1,
"GHOST":1,
"CYNIC":1,
"ADMIN":1,
"FLORA":1,
"WIMPY":1,
"TRIAL":1,
"SHAPE":1,
"CATTY":1,
"SCENE":1,
"TRAWL":1,
"FETCH":1,
"DRUID":1,
"CATER":1,
"MOURN":1,
"SURLY":1,
"STAGE":1,
"SPURT":1,
"HAIRY":1,
"WAFER":1,
"LOWER":1,
"RISER":1,
"DRAIN":1,
"GAFFE":1,
"HAUTE":1,
"GYPSY":1,
"SHREW":1,
"GLEAN":1,
"SHIRK":1,
"MARRY":1,
"RELIC":1,
"PASTA":1,
"PINTO":1,
"TORSO":1,
"DWARF":1,
"CRUMB":1,
"TRADE":1,
"TRACT":1,
"CURIO":1,
"TANGO":1,
"RIGOR":1,
"FROST":1,
"PUSHY":1,
"RHYME":1,
"SNAIL":1,
"QUEEN":1,
"PHONY":1,
"SCOPE":1,
"BOSOM":1,
"PAYEE":1,
"CADDY":1,
"LIKEN":1,
"SHAKE":1,
"BRASS":1,
"SMELT":1,
"CABIN":1,
"PIANO":1,
"LEAST":1,
"SCUBA":1,
"UNDUE":1,
"SLIMY":1,
"GNOME":1,
"HEIST":1,
"WOUND":1,
"SIXTH":1,
"FRITZ":1,
"INCUR":1,
"HUSSY":1,
"GLOAT":1,
"QUILT":1,
"SCALP":1,
"ACORN":1,
"LYMPH":1,
"HEARD":1,
"HATER":1,
"VALOR":1,
"PRIOR":1,
"ALTAR":1,
"SPOOK":1,
"LOOSE":1,
"MANIC":1,
"QUOTH":1,
"FAULT":1,
"GAYLY":1,
"SHELL":1,
"FETID":1,
"LUMPY":1,
"UNLIT":1,
"ADORE":1,
"GOOSE":1,
"SHORN":1,
"ABACK":1,
"EATEN":1,
"COUCH":1,
"COPSE":1,
"SALON":1,
"ENVOY":1,
"GROAN":1,
"SMASH":1,
"ADAGE":1,
"FLAKE":1,
"VIPER":1,
"BLISS":1,
"BERET":1,
"RANGE":1,
"STUDY":1,
"REIGN":1,
"ULCER":1,
"GORGE":1,
"ROAST":1,
"CHARM":1,
"THREW":1,
"TOUCH":1,
"STOOP":1,
"SOUTH":1,
"MOCHA":1,
"AMONG":1,
"PENAL":1,
"SHOVE":1,
"BOBBY":1,
"BIDDY":1,
"ROUND":1,
"PINEY":1,
"FORTY":1,
"DELVE":1,
"FLICK":1,
"AMASS":1,
"PROUD":1,
"NOOSE":1,
"AORTA":1,
"SWEAT":1,
"LUMEN":1,
"CRATE":1,
"SPOOF":1,
"CHEST":1,
"TITHE":1,
"CHIRP":1,
"DEVIL":1,
"RAJAH":1,
"FINAL":1,
"NUDGE":1,
"TROVE":1,
"PIXIE":1,
"GLASS":1,
"BLOND":1,
"MOODY":1,
"BIRCH":1,
"MAGMA":1,
"CRIMP":1,
"GRAVE":1,
"BELCH":1,
"JOKER":1,
"GLADE":1,
"FILER":1,
"GAVEL":1,
"DODGE":1,
"PARKA":1,
"EMAIL":1,
"SHYLY":1,
"CARGO":1,
"DUSTY":1,
"SPEAR":1,
"VERSO":1,
"FRAIL":1,
"DINER":1,
"SNOWY":1,
"MEDIC":1,
"GUAVA":1,
"QUALM":1,
"CHAMP":1,
"BASIS":1,
"DILLY":1,
"SWOON":1,
"JAZZY":1,
"TIBIA":1,
"METER":1,
"ALIVE":1,
"PUNCH":1,
"SERIF":1,
"SCREW":1,
"LOATH":1,
"STICK":1,
"WRACK":1,
"MINCE":1,
"BLUER":1,
"SNOOP":1,
"RHINO":1,
"USUAL":1,
"TYING":1,
"FIEND":1,
"CRONY":1,
"FORGE":1,
"LEAPT":1,
"WORTH":1,
"BOARD":1,
"ORGAN":1,
"BLEED":1,
"QUACK":1,
"CAPER":1,
"TRAIN":1,
"PITCH":1,
"DECAY":1,
"FANCY":1,
"GRIND":1,
"CIVIL":1,
"TIMER":1,
"ANVIL":1,
"HENCE":1,
"SHARD":1,
"GAILY":1,
"PRONG":1,
"GOUGE":1,
"ORBIT":1,
"SWIFT":1,
"HORNY":1,
"DEIGN":1,
"TEARY":1,
"WORLD":1,
"RABBI":1,
"CIDER":1,
"MOOSE":1,
"WENCH":1,
"PEACE":1,
"DUNCE":1,
"BEACH":1,
"REFER":1,
"SPELT":1,
"SCREE":1,
"SAINT":1,
"BRINE":1,
"RESIN":1,
"APTLY":1,
"SPECK":1,
"HOLLY":1,
"BEGUN":1,
"SMOKE":1,
"ABLED":1,
"TWEAK":1,
"TAKEN":1,
"BIRTH":1,
"VERVE":1,
"TRICK":1,
"LUPUS":1,
"ILIAC":1,
"ALLEY":1,
"IMPLY":1,
"RUDER":1,
"DEFER":1,
"BRAND":1,
"NAVEL":1,
"BLACK":1,
"BASAL":1,
"CHAFE":1,
"WRYLY":1,
"PAPER":1,
"ERUPT":1,
"COVET":1,
"AXIAL":1,
"HABIT":1,
"SINGE":1,
"LURID":1,
"QUASH":1,
"FLIER":1,
"FOAMY":1,
"LORRY":1,
"BRAKE":1,
"MUSHY":1,
"LATTE":1,
"BREED":1,
"QUICK":1,
"TRULY":1,
"CLASP":1,
"DRYLY":1,
"WAIST":1,
"MINIM":1,
"SPUNK":1,
"QUART":1,
"TRIED":1,
"MERRY":1,
"WILLY":1,
"BOWEL":1,
"MORPH":1,
"ALIKE":1,
"JUMPY":1,
"LINEN":1,
"SIEVE":1,
"RENEW":1,
"WOODY":1,
"PROSE":1,
"STRAP":1,
"VOILA":1,
"ROBIN":1,
"DENIM":1,
"BEGAT":1,
"TIMID":1,
"SLEPT":1,
"PLATE":1,
"UDDER":1,
"LEGGY":1,
"WORDY":1,
"SWORE":1,
"ASHEN":1,
"GREET":1,
"TANGY":1,
"TROPE":1,
"BRUTE":1,
"FLUTE":1,
"SHARK":1,
"DOGMA":1,
"TASTE":1,
"SQUAD":1,
"CHORD":1,
"DROWN":1,
"DRUNK":1,
"RALLY":1,
"MADAM":1,
"GUILD":1,
"CHURN":1,
"RALPH":1,
"SMEAR":1,
"ASKEW":1,
"HANDY":1,
"ELBOW":1,
"SENSE":1,
"KNACK":1,
"BIOME":1,
"BLAZE":1,
"LITHE":1,
"QUAIL":1,
"CACAO":1,
"GIANT":1,
"FILMY":1,
"THEIR":1,
"LATER":1,
"GIVEN":1,
"CLOUT":1,
"KAYAK":1,
"RECAP":1,
"SKIFF":1,
"CRASS":1,
"HIPPO":1,
"WOOER":1,
"PLUCK":1,
"COMFY":1,
"SALVE":1,
"FRANK":1,
"STING":1,
"LABEL":1,
"PUFFY":1,
"CLIMB":1,
"CHAFF":1,
"ROUGE":1,
"LUCID":1,
"CHICK":1,
"BASIL":1,
"TAROT":1,
"SONAR":1,
"SWAMP":1,
"SAVOY":1,
"CLUCK":1,
"MAMMA":1,
"EXULT":1,
"DOUGH":1,
"SCION":1,
"HAUNT":1,
"REACH":1,
"CORER":1,
"DRYER":1,
"SURER":1,
"LYNCH":1,
"GRASS":1,
"SPIKY":1,
"HUMUS":1,
"COUNT":1,
"CROCK":1,
"EYING":1,
"PARRY":1,
"ABHOR":1,
"BRAVE":1,
"YEARN":1,
"MELEE":1,
"MUCKY":1,
"ULTRA":1,
"ALLOT":1,
"CHINA":1,
"ZONAL":1,
"SLAVE":1,
"CHASM":1,
"SMITH":1,
"OXIDE":1,
"ELOPE":1,
"CARRY":1,
"FAITH":1,
"CRANE":1,
"WAIVE":1,
"CLONE":1,
"BULGE":1,
"FELLA":1,
"WOMEN":1,
"BEADY":1,
"FIELD":1,
"ALTER":1,
"GUMBO":1,
"FRAME":1,
"VOUCH":1,
"DEBUT":1,
"CLIFF":1,
"LOUSY":1,
"AMISS":1,
"VERSE":1,
"POKER":1,
"LOGIN":1,
"BRISK":1,
"QUIRK":1,
"VAUNT":1,
"SWELL":1,
"PUTTY":1,
"HEAVY":1,
"HAVEN":1,
"WHERE":1,
"BONEY":1,
"BLANK":1,
"EXTOL":1,
"FRUIT":1,
"HUNKY":1,
"BLINK":1,
"DICEY":1,
"DECAL":1,
"PULSE":1,
"SPEND":1,
"BRUNT":1,
"AWFUL":1,
"SANER":1,
"DATUM":1,
"AWARD":1,
"CARAT":1,
"NAIVE":1,
"ALONG":1,
"PLUSH":1,
"SALSA":1,
"EMPTY":1,
"NOISE":1,
"RAVEN":1,
"DEMON":1,
"RUPEE":1,
"NERDY":1,
"ENACT":1,
"BLURT":1,
"TOOTH":1,
"DUCHY":1,
"VOCAL":1,
"OWING":1,
"SWING":1,
"MAUVE":1,
"AVIAN":1,
"SHOWY":1,
"TOKEN":1,
"FLASH":1,
"ACTOR":1,
"BLAND":1,
"RADIO":1,
"GLORY":1,
"LAPEL":1,
"VIOLA":1,
"TITLE":1,
"SPLAT":1,
"FLACK":1,
"VOTER":1,
"FIFTH":1,
"SOLID":1,
"BLEAT":1,
"LEMUR":1,
"ROYAL":1,
"INLET":1,
"SPIKE":1,
"JUROR":1,
"GAMMA":1,
"EARTH":1,
"STAMP":1,
"OFFAL":1,
"REEDY":1,
"SETUP":1,
"SAUNA":1,
"RIVAL":1,
"IRATE":1,
"ERASE":1,
"LILAC":1,
"ALLOW":1,
"GRASP":1,
"WOKEN":1,
"VISIT":1,
"MARSH":1,
"DEBAR":1,
"PIQUE":1,
"LOVER":1,
"ROWER":1,
"WHEAT":1,
"GAUDY":1,
"LOYAL":1,
"TRASH":1,
"LLAMA":1,
"SHARP":1,
"OLIVE":1,
"SERVE":1,
"CLOVE":1,
"TABOO":1,
"MEATY":1,
"OMEGA":1,
"FORAY":1,
"SEEDY":1,
"CROOK":1,
"STORK":1,
"ORDER":1,
"GRAIN":1,
"QUAKE":1,
"NICHE":1,
"TWEET":1,
"FROTH":1,
"WACKY":1,
"ARENA":1,
"TRUTH":1,
"TAUNT":1,
"THYME":1,
"CHESS":1,
"MOUTH":1,
"LEARN":1,
"FICUS":1,
"SHAWL":1,
"SIGHT":1,
"PRONE":1,
"BLITZ":1,
"BONUS":1,
"SIREN":1,
"STILT":1,
"GENIE":1,
"ETUDE":1,
"SKILL":1,
"TEACH":1,
"AWARE":1,
"CUTIE":1,
"EJECT":1,
"ITCHY":1,
"MACRO":1,
"AUGUR":1,
"WORRY":1,
"NIECE":1,
"PILOT":1,
"DONOR":1,
"EKING":1,
"EVADE":1,
"GLEAM":1,
"UNDID":1,
"UTTER":1,
"COMIC":1,
"SCARY":1,
"LODGE":1,
"PATIO":1,
"DRAWL":1,
"SEIZE":1,
"STALL":1,
"WELCH":1,
"BATHE":1,
"BATON":1,
"CHIDE":1,
"ISSUE":1,
"SNACK":1,
"DRINK":1,
"BASIN":1,
"GONER":1,
"LIVID":1,
"LEASH":1,
"SWATH":1,
"RARER":1,
"WHILE":1,
"MOUND":1,
"SPEAK":1,
"HYDRO":1,
"MAJOR":1,
"THEFT":1,
"RAISE":1,
"FIXER":1,
"STOUT":1,
"IDIOT":1,
"BIBLE":1,
"EXCEL":1,
"BARON":1,
"SQUIB":1,
"KIOSK":1,
"ISLET":1,
"WINCE":1,
"KNOLL":1,
"GUILT":1,
"INNER":1,
"ALGAE":1,
"TAFFY":1,
"SUING":1,
"BRACE":1,
"OCTET":1,
"FLUNG":1,
"PLIER":1,
"DROOL":1,
"AGAPE":1,
"KEBAB":1,
"HURRY":1,
"SNIFF":1,
"PRIED":1,
"MOGUL":1,
"STAIR":1,
"AGORA":1,
"DETER":1,
"VIGIL":1,
"TALLY":1,
"TOPAZ":1,
"ENEMY":1,
"MACHO":1,
"ROUGH":1,
"WIELD":1,
"GLYPH":1,
"WHIRL":1,
"PLAZA":1,
"CRACK":1,
"CRUST":1,
"FACET":1,
"SLIDE":1,
"MOTOR":1,
"DENSE":1,
"SHUSH":1,
"LAUGH":1,
"FLECK":1,
"ETHOS":1,
"METRO":1,
"AMPLY":1,
"SCORN":1,
"SHAFT":1,
"SCOLD":1,
"KNOWN":1,
"WORSE":1,
"BLOKE":1,
"SHOCK":1,
"ANTIC":1,
"FORGO":1,
"KNIFE":1,
"FAUNA":1,
"ETHIC":1,
"HEATH":1,
"MINER":1,
"SWORN":1,
"THERE":1,
"FORCE":1,
"SLOTH":1,
"HUMPH":1,
"BRAWN":1,
"BELLE":1,
"QUELL":1,
"RISEN":1,
"BLESS":1,
"ANGST":1,
"DEUCE":1,
"HOUSE":1,
"FEMUR":1,
"ADEPT":1,
"PENNY":1,
"FLYER":1,
"ROUSE":1,
"ROTOR":1,
"SHEIK":1,
"CONDO":1,
"BERTH":1,
"SHIED":1,
"SYNOD":1,
"EVICT":1,
"ENDOW":1,
"SULLY":1,
"TWEED":1,
"AROMA":1,
"DOUBT":1,
"MAXIM":1,
"GRACE":1,
"STAIN":1,
"SKIMP":1,
"SMART":1,
"ENJOY":1,
"DROSS":1,
"MICRO":1
}

export {ACCEPTABLE, ANSWERS}