<template>
    <ModalBase>
<br/>
<br/>
<br/>
            <h4>Like QWERTLE? Buy me a coffee if you want to support. Or don't!</h4>
            <a href="https://www.buymeacoffee.com/samfriedman" target="_blank">
                <img src="https://cdn.buymeacoffee.com/buttons/v2/default-blue.png" alt="Buy Me A Coffee" style="height: 60px !important;width: 217px !important;" ></a>
            <!-- <p> -->
            <!-- Something wrong?&nbsp;<button @click="reset">Reset game</button> -->
            <!-- </p> -->
            </ModalBase>
</template>

<script>
import ModalBase from './ModalBase.vue';

export default {
    components: { ModalBase }
};
</script>

<style>

</style>
